import { Fragment, useContext, useEffect } from "react";
import userPng from "../assets/images/user.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { TiShoppingCart } from "react-icons/ti";
import { Link } from "react-router-dom";
import {
  FaUsers,
  FaTags,
  FaClipboardCheck,
  FaCalendarAlt,
  FaMoneyBillWave,
} from "react-icons/fa";

const SideBar = () => {
  return (
    <Fragment>
      <div id="header_top" className="header_top false">
        <div className="container">
          <div className="hleft">
            <a
              aria-current="page"
              className="header-brand active"
              href="/dashboard"
            >
              <img
                src="/assets/images/MainLogo-removebg-preview.png"
                alt="Main Logo"
                width="35vh"
              />
            </a>
            <div className="dropdown"></div>
          </div>
          <div className="hright">
            <div className="dropdown">
              <span className="nav-link icon settingbar">
                <i
                  className="fa fa-gear fa-spin"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Settings"
                ></i>
              </span>

              <p className="nav-link user_btn">
                <img
                  className="avatar"
                  src={userPng}
                  alt="fake_alr"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="User Menu"
                />
              </p>

              <p className="nav-link icon menu_toggle">
                <i className="fa  fa-align-left"></i>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="left-sidebar" className="sidebar ">
        <h5 className="brand-name">Witzzart</h5>
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <div className="">
            <ul className="metismenu">
              <li className="">
                <span className="g_heading">master</span>
              </li>
              <li className="">
                <li className="">
                  <span className="">
                    <Link className="list-a" to="/admin/dashboard">
                      <GiHamburgerMenu className="mr-3" />
                      Dashboard
                    </Link>
                  </span>
                </li>
                <li className="">
                  <span className="">
                    <Link className="list-a" to="/admin/users">
                      <FaUsers className="mr-3" />
                      Users
                    </Link>
                  </span>
                </li>
                <li className="">
                  <span className="">
                    <Link className="list-a" to="/admin/category">
                      <FaTags className="mr-3" />
                      Categories
                    </Link>
                  </span>
                </li>
                <li className="">
                  <span className="">
                    <Link className="list-a" to="/admin/event-approval">
                      <FaClipboardCheck className="mr-3" />
                      Event Approval
                    </Link>
                  </span>
                </li>
                <li className="">
                  <span className="">
                    <Link className="list-a" to="/admin/events">
                      <FaCalendarAlt className="mr-3" />
                      Events
                    </Link>
                  </span>
                </li>
                <li className="">
                  <span className="">
                    <Link className="list-a" to="/admin/payment">
                      <FaMoneyBillWave className="mr-3" />
                      Payments
                    </Link>
                  </span>
                </li>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default SideBar;
