export default function Location({ formData, onChange }) {
  return (
    <div className="mb-4">
      {/* Venue Name */}
      <div className="mb-3">
        <label className="form-label">Venue Name*</label>
        <input
          type="text"
          className="form-control"
          required
          placeholder="Enter venue name"
          name="venue_name"
          value={formData.venue_name}
          onChange={onChange}
        />
      </div>

      {/* Location Type */}
      <div className="mb-3">
        <label className="form-label">Location Type*</label>
        <select
          className="form-select"
          required
          name="location_type"
          value={formData.location_type}
          onChange={onChange}
        >
          <option value="In-Person">In-Person</option>
          <option value="Online">Online</option>
          <option value="Hybrid">Hybrid</option>
        </select>
      </div>

      {/* Conditional Fields Based on Location Type */}
      {/* Show Address and Map for In-Person or Hybrid */}
      {(formData.location_type === "In-Person" || formData.location_type === "Hybrid") && (
        <>
          <div className="mb-3">
            <label className="form-label">Address*</label>
            <input
              type="text"
              required
              className="form-control"
              placeholder="Enter full address"
              name="address"
              value={formData.address}
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Map Integration*</label>
            <input
              type="text"
              required
              className="form-control"
              placeholder="Enter map link"
              name="map"
              value={formData.map || ""}
              onChange={onChange}
            />
          </div>
        </>
      )}

      {/* Show Online Link for Online or Hybrid */}
      {(formData.location_type === "Online" || formData.location_type === "Hybrid") && (
        <div className="mb-3">
          <label className="form-label">Event Link*</label>
          <input
            type="text"
            required
            className="form-control"
            placeholder="Enter online event link"
            name="online_link"
            value={formData.online_link}
            onChange={onChange}
          />
          <small className="form-text text-muted">
            URL for virtual events (e.g., Zoom, Google Meet)
          </small>
        </div>
      )}
    </div>
  );
}
