import React from "react";

const RefundPolicyPage = () => {
  return (
    <div className=" custom-container  mt-5 py-4 d-flex flex-column align-item-start justify-content-start">
      <h2 className="text-start  fw-bold text-black  mb-4">
        Refund Policy
      </h2>
      <div className="prose ">
        <p className="py-1">Last updated: [28th January 2025]</p>
        <p className="pb-3 font-sm">
          At Witzzart, we strive to ensure a seamless and transparent experience
          for all our users. This Refund Policy outlines how refunds are handled
          on our platform. Please read it carefully before making a purchase
        </p>

        <h2 className="py-3 fw-bold fs-3">1. General Refund Policy</h2>
        <p className=" font-sm ">
          As a ticketing marketplace, Witzzart facilitates transactions between
          event organizers and attendees. The refund policy for each event is
          determined by the event organizer. We strongly recommend reviewing the
          specific refund policy for an event before completing your purchase.
        </p>

        <h2 className="py-3 fw-bold fs-3">2. Event Cancellations</h2>
        <p className=" font-sm ">
          If an event is cancelled by the organizer:
        </p>
        <li className="font-sm">
          Ticket holders are typically eligible for a full refund of the ticket
          price and associated fees.
        </li>
        <li className="font-sm">
          Refunds will be processed to the original payment method within 5–10
          business days.
        </li>

        <h2 className="py-3 fw-bold fs-3">3. Event Rescheduling</h2>
        <p className=" font-sm ">If an event is rescheduled:</p>
        <li className="font-sm">
          Tickets will generally remain valid for the new date.
        </li>
        <li className="font-sm">
          If you’re unable to attend the rescheduled event, you may be eligible
          for a refund, subject to the organizer’s refund policy
        </li>

        <h2 className="py-3 fw-bold fs-3">4. Refund Requests</h2>
        <p className=" font-sm ">
          To request a refund, follow these steps:
        </p>
        <ol>
          <li>Log in to your Witzzart account</li>
          <li>Navigate to your Order History.</li>
          <li>Select the order for which you’re requesting a refund</li>
          <li>Click the “Request Refund” button.</li>
          <li>Follow the prompts to complete your request.</li>
        </ol>
        <p className=" font-sm ">
          NOTE : Refund requests are subject to the event-specific refund policy
          and may not always be granted
        </p>

        <h2 className="py-3 fw-bold fs-3">5. Processing Time</h2>
        <p className=" font-sm ">If your refund is approved:</p>

        <li>
          Please allow 5–10 business days for the refund to be processed and
          reflected in your account.
        </li>
        <li>The timeframe may vary depending on your payment provider.</li>

        <h2 className="py-3 fw-bold fs-3">6. Non-Refundable Items</h2>
        <p className=" font-sm ">
          The following items are generally non-refundable:
        </p>
        <ul>
          <li>Lost or stolen tickets</li>
          <li>Tickets for events that have already occurred</li>
          <li>Service fees, unless otherwise stated</li>
        </ul>

        <h2 className="py-3 fw-bold fs-3">7. Disputes</h2>
        <p className=" font-sm ">
          If you have a dispute regarding a refund:
        </p>

        <li>Contact our Customer Support Team at support@witzzart.com.</li>
        <li>
          We will work with you and the event organizer to resolve the issue
          fairly and promptly.
        </li>

        <h2 className="py-3 fw-bold fs-3">8. Changes to This Policy</h2>
        <p className=" font-sm ">
          We reserve the right to modify this Refund Policy at any time. Changes
          will be effective immediately upon posting to our website. Your
          continued use of our services after any changes indicates your
          acceptance of the updated policy
        </p>

        <h2 className="py-3 fw-bold fs-3">9. Contact Us</h2>
        <p className=" font-sm ">
          If you have any questions about this Refund Policy, please reach out
          to us at support@witzzart.com.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicyPage;
