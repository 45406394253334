import React, { useState, useEffect, useRef } from "react";
import concert from "../../assets/images/concert.png";
import axios from "../../../src/axiosInstance";
import { useParams, useNavigate } from "react-router-dom";
import ArtistCard from "./Shared/profileCards";
import MainCards from "./Shared/MainCards";

const EventDetailsPage = () => {
  const detailsRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [attendees, setAttendees] = useState(1);
  const [packageType, setPackageType] = useState("");
  const [activeTab, setActiveTab] = useState("overview");
  const RAZORPAY_KEY = process.env.RAZORPAY_KEY_ID;
  const [eventDetailsById, setEventDetailsById] = useState([]);
  const { id } = useParams();
  const [selectedDateid, setSelectedDateid] = useState(null);

  const [newEvents, setnewEvents] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (detailsRef.current) {
        const rect = detailsRef.current.getBoundingClientRect();
        setIsScrolled(rect.top <= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isFormValid = packageType && attendees && selectedDate;

  const paymentHandler = async (e) => {
    let token = localStorage.getItem("Token");
    console.log("Asdfghjkl", token);
    if (!token) {
      navigate("/login");
      return;
    }

    let data = {
      eventId: id,
      tickettype: packageType,
      seats: attendees,
      userId: localStorage.getItem("userId"),
      date: selectedDate,
      currency: "INR",
    };

    const response = await axios.post(`/createOrder`, data);

    const order = await response.data.data.order;
    const emailData = await response.data.data.emailData;
    console.log("order", order.amount);

    var options = {
      key: RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: data.currency,
      name: "Witzzart", //your business name
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          emailData : emailData
        };
        // verifyPayment
        const validateRes = await axios.post(`/verifyPayment`, body);
        const jsonRes = validateRes;
        console.log(jsonRes);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: "Witzzart", //your customer's name
        email: "Support@witzzart.com",
        contact: "7568305343", //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#dc0a12",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
  };

  const getEventDetails = async () => {
    const response = await axios.get(`/event/${id}`);
    if (response.data.data) {
      const keysToParse = [
        "event_highlights",
        "audience_type",
        "event_agenda",
        "required_equipment",
        "accessibility_options",
      ];

      keysToParse.forEach((key) => {
        if (response.data.data[key]) {
          response.data.data[key] = JSON.parse(response.data.data[key]);
        }
      });
    }

    setEventDetailsById(response.data.data);
  };
  useEffect(() => {
    getEventDetails();
    getEvents();
  }, [id]);

  const getEvents = async () => {
    try {
      const response = await axios.get("/event");
      setnewEvents(response.data.data);
      console.log("Response:", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function generateEventDates(startDate, endDate) {
    const eventDates = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      // Get the full month name (e.g., "March" instead of "03")
      const monthName = currentDate.toLocaleString("default", {
        month: "long",
      });
      // Get the day of the month (e.g., "1" or "2")
      const dayOfMonth = currentDate.getDate();

      // Push formatted date in the required format to the array
      eventDates.push({
        date: `${monthName} ${dayOfMonth}`, // Example: "March 1"
        month: monthName,
        day: dayOfMonth,
      });

      // Increment by one day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return eventDates;
  }


  function formatDate(isoString) {
    const date = new Date(isoString);
    return date.toLocaleDateString("en-GB"); 
  }

  return (
    <div className="custom-container mt-2 pt-4">
     <div
        className="position-relative rounded-3 overflow-hidden"
        style={{ height: "80vh", width:"100vh !important" }}
      >
        <img
          src={eventDetailsById.images?.[0]?.image}
          alt="Event Banner"
          className="w-100 object-fit-contain border rounded-3"
          style={{height:"100%", width:"100vh "}}
        />
      </div>
      <div className="row all-width mt-3">
        <div className="col-12 col-md-8">
          <div className="mt-1">
            <ul className="nav pt-3 pb-3 px-0">
              {["overview", "schedule", "artists", "reviews"].map((tab) => (
                <li className="nav-item" key={tab}>
                  <span
                    onClick={() => setActiveTab(tab)}
                    className={`nav-link px-2 font-xs display-6 text-dark ${
                      activeTab === tab ? "fw-bold" : "fw-muted"
                    }`}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </span>
                </li>
              ))}
            </ul>

            <div className="tab-content  py-3  px-0 rounded-lg">
              {activeTab === "overview" && (
                <div className="border p-4 rounded-4">
                  <div className="d-flex pb-5  align-items-center w-100 justify-space-between ">
                    <h4 className="text-dark mb-0 fw-bold text-capitalize">
                      {eventDetailsById.event_title}
                    </h4>
                    <span className=" rounded-pill font-xxs px-2 py-1 btn-red ml-3">
                      {eventDetailsById.event_type}
                    </span>
                  </div>
                  <h5 className="fw-bold"> Event Description</h5>
                  <p className="text-muted">{eventDetailsById?.description}</p>
                  <h5 className="fw-bold">Highlights:</h5>
                  {eventDetailsById?.event_highlights?.map((item, index) =>
                      item.length > 0 ? <li key={index} className="text-muted text-sm">{item}</li> : null
                  )}


                  <div class="row mt-3">
                        <div class="col d-flex gap-2">
                          <p className="text-h6 mb-2 fw-semibold">Location:</p>
                          <span className="font-xs text-gray">{eventDetailsById?.address}</span>
                        </div>
                        <div class="col d-flex gap-2">
                          <p className="text-h6 mb-2 fw-semibold">Dress Code:</p>
                          <span className="font-xs text-gray">{eventDetailsById?.dress_code}</span>
                        </div>
                      </div>


                      <div class="row mt-3">
                        <div class="col d-flex gap-2">
                          <p className="text-h6 mb-2 fw-semibold">Language:</p>
                          <span className="font-xs text-gray">
                            {eventDetailsById?.event_language}
                          </span>
                        </div>

                        <div class="col d-flex gap-2">
                          <p className="text-h6 mb-2 fw-semibold">
                            Skill Level:
                          </p>
                          <span className="font-xs text-gray">
                            {eventDetailsById?.skill_level}
                          </span>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col d-flex">
                          <p className="text-h6 mb-2 fw-semibold">
                            Items required:
                          </p>
                          <div className="  d-flex gap-2">
                            {eventDetailsById?.required_equipment?.length > 0 ? (
                                eventDetailsById?.required_equipment?.map((item) => {
                                  return (
                                    item?.trim()?.length > 0 && ( <span className="rounded-pill text-gray font-xxs px-1 py-1 btn-red ">
                                      {item}
                                    </span>)

                                  );
                                })
                            ):(
                              <span className="font-xs text-gray">None</span>
                            )}

                          </div>
                        </div>
                      </div>

                </div>
              )}
              {activeTab === "schedule" && (
                <div className="border p-4 rounded-4">
                  <h4 className="fw-bold">Event Schedule</h4>

                  <div className="row">
                        <div className="col d-flex gap-3">
                          <p className="text-h6 mb-2 fw-semibold">
                            Start date:
                          </p>
                          <p className="font-xs mb-2 text-gray">{formatDate(eventDetailsById?.start_date)}</p>
                        </div>
                        <div className="col d-flex gap-3">
                          <p className="text-h6 mb-2 fw-semibold">End date:</p>
                          <p className="font-xs mb-2 text-gray text-gray">{formatDate(eventDetailsById?.end_date)}</p>
                        </div>
                      </div>

                      <div className="d-flex gap-2">
                        <p className="text-h6 mb-2 fw-semibold">Timing:</p>
                        <p className="font-xs mb-2 text-gray">
                          {" "}
                          {eventDetailsById?.start_time} - {eventDetailsById?.end_time}{" "}
                        </p>
                      </div>
                      <div className="d-flex gap-2">
                        <p className="text-h6 fw-semibold mb-2">Event Type:</p>
                        <p className="font-xs text-gray">{eventDetailsById?.location_type}</p>
                      </div>

                      <div className="d-flex gap-2">
                        <p className="text-h6 mb-2 fw-semibold">Map Link:</p>
                        <a
                          href={eventDetailsById?.map.startsWith("http") ? eventDetailsById?.map : `https://${eventDetailsById?.map}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray text-break font-xs redTextOnhover"
                        >
                          {eventDetailsById?.map}
                        </a>
                      </div>

                      <div className="d-flex gap-2">
                        <p className="text-h6 mb-2  fw-semibold">Online Link:</p>
                        <p className="font-xs text-gray">{eventDetailsById?.online_link ? eventDetailsById.online_link :"None"}</p>
                      </div>


                      <div className=" gap-2">
                        <h5 className=" mb-2 fw-semibold">
                          Schedule:
                        </h5>
                        <ul>
                          {eventDetailsById?.event_agenda?.map((item) =>
                              item.length > 0 ? <li className="text-muted text-sm">{item}</li> : null
                          )}
                        </ul>{" "}
                      </div>

                </div>
              )}
              {activeTab === "artists" && (
                <div className="row g-2">
                  {eventDetailsById?.artists?.map((artist, index) => (
                    <div className="col-6 col-md-4 col-lg-3 px-3" key={index}>
                      <ArtistCard artist={artist} />
                    </div>
                  ))}
                </div>
              )}
              {activeTab === "reviews" && (
                <div className=" ">
                  <div className="d-flex align-items-center justify-content-between all-width">
                    <h4 className="fw-bold mb-5"> Reviews </h4>
                  </div>

                  <div className=" bg-white all-width rounded-lg border-gray p-2 ">
                    <p className="text-gray">
                      "Absolutely amazing event! Would recommend to everyone."
                      <br />- Sarah L.
                    </p>
                  </div>

                  <div class="mt-3">
                    <textarea
                      className=" bg-white text-black all-width rounded-lg border-gray p-2 "
                      placeholder="Leave a comment here"
                    ></textarea>
                    <button className="btn-red font-xxs py-1 px-2">
                      Add Review
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 mt-3 mt-md-0">
          <div className="card border">
            <div className="card-header">
              <div className="d-flex justify-content-between all-width align-items-center">
                <h6 className=" mb-1">
                  ₹{eventDetailsById.tickets?.[0]?.price} onwards
                </h6>
                <span className="badge bg-success text-success bg-opacity-10">
                  {eventDetailsById.tickets?.[0]?.seats} spots left
                </span>
              </div>
            </div>
            <div className="card-body">
              <a
                href={"/profile/" + eventDetailsById.user_id}
                className="d-flex align-items-center gap-3 p-3 border rounded mb-4 text-decoration-none"
              >
                <img
                  src="/placeholder.svg"
                  alt="Metropolitan Gallery"
                  className="rounded-circle"
                  style={{ width: "40px", height: "40px" }}
                />
                <div>
                  <h6 className="mb-0">
                    {eventDetailsById.event_organizer_name}
                  </h6>
                  <small className="text-muted d-block">
                    Verified Organizer
                  </small>
                </div>
              </a>

              {/* Select Date */}
              <div className="mb-4">
                <h6 className="fw-bold mb-3">Select Date</h6>
                <div className="row g-2">
                  {generateEventDates(
                    eventDetailsById.start_date,
                    eventDetailsById.end_date
                  )?.map((date, index) => (
                    <div
                      className="col-4"
                      key={date.date}
                      onClick={() => {
                        const actualDate = new Date(
                          eventDetailsById.start_date
                        );
                        actualDate.setDate(actualDate.getDate() + index);
                        setSelectedDate(actualDate.toISOString());
                        setSelectedDateid(index)
                      }}
                    >
                      <button

                      className={` btn w-100 border ${
                        selectedDateid===index
                        ? "btn-red " : ""
                       }`}

                      >
                        <div className="small">{date.month}</div>
                        <div className="fw-bold">{date.day}</div>
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Number of Attendees */}
              <div className="mb-4">
                <h6 className="fw-bold mb-3">Number of Attendees</h6>
                <select
                  className="form-select"
                  value={attendees}
                  onChange={(e) => setAttendees(Number(e.target.value))}
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <option key={num} value={num}>
                      {num} {num === 1 ? "person" : "people"}
                    </option>
                  ))}
                </select>
              </div>

              {/* Select Package */}
              <div className="mb-4">
                <h6 className="fw-bold mb-3">Select Package</h6>
                {eventDetailsById?.tickets?.map((pkg, index) => (
                  <div
                    className={`border rounded d-flex justify-content-between align-items-start p-3 mb-3 ${
                      packageType === pkg.ticket_name ? "border-primary" : ""
                    }`}
                    key={index}
                  >
                    <div>
                      <input
                        type="radio"
                        name="packageType"
                        id={index}
                        value={pkg.ticket_name}
                        checked={packageType === pkg.ticket_name}
                        onChange={(e) => setPackageType(e.target.value)}
                        className="form-check-input me-2"
                      />
                      <label
                        htmlFor={index}
                        className="form-label pl-5 fw-bold"
                      >
                        {pkg.ticket_name}
                      </label>
                      <div className="d-flex align-items-center justify-content-end all-width">
                        {/* <span className="rounded-pill btn-red font-xxs py-1 px-2"> {pkg.seats} seats left</span> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="fw-bold">₹{pkg.price}</div>
                      <span className="rounded-pill btn-red text-truncate font-xxs py-1 px-2">
                        {pkg.seats} seats left
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              {/* Info Section */}
              <div className="p-3 bg-light rounded mb-4">
                <p className="mb-1 text-muted">
                  <i className="bi bi-people me-2"></i>A lot people have booked
                  this week
                </p>
                <p className="mb-0 text-muted">
                  <i className="bi bi-clock me-2"></i>This event usually sells
                  out
                </p>
              </div>

              {/* Get Tickets Button */}
              <button
                className="btn btn-danger w-100 mb-3"
                disabled={!isFormValid}
                onClick={paymentHandler}
              >
                Get Tickets
              </button>

              {/* <p className="text-center text-muted small mb-0">
                Free cancellation up to 24 hours before the event
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <h5>More Events</h5>

        <div className="row g-4">
          {newEvents?.map((event, index) => (
            <div
              className="col-12 col-sm-6 col-md- col-lg-4 col-xl-3"
              key={index}
            >
              <MainCards
                title={event.event_title}
                image={event?.images?.[0]?.image}
                date={event.start_date}
                time={event.start_time}
                location={event.address}
                price={event.tickets[0].price}
                href={"eventdetails/" + event.id}
                tags={event.tags}
                type={event.type}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventDetailsPage;
