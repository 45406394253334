import React, { useState } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";
export default function FAQsPage() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How do I purchase tickets on witzzart?",
      answer:
        "To purchase tickets, simply browse our events, select the event you're interested in, choose your tickets, and proceed to checkout. You'll need to create an account or log in to complete your purchase.",
    },
    {
      question: "Can I get a refund for my tickets?",
      answer:
        "Refund policies are set by event organizers. Please check the specific event's refund policy before purchasing tickets. If an event is cancelled, you will typically be eligible for a refund.",
    },
    {
      question: "How do I list my event on witzzart?",
      answer:
        "To list an event, you'll need to create an organizer account. Once approved, you can create your event listing, set ticket prices, and manage sales through our platform.",
    },
    {
      question: "What fees does witzzart charge?",
      answer:
        "We charge a service fee on each ticket sold. The exact fee amount is displayed during the checkout process for attendees and in the organizer dashboard for event creators.",
    },
    {
      question: "How do I contact customer support?",
      answer:
        "You can reach our customer support team by emailing support@witzzart.com or through the contact form on our website. We aim to respond to all inquiries within 24 hours.",
    },
    {
      question: "Are my tickets transferable?",
      answer:
        "Ticket transferability depends on the event organizer's policy. Some events allow ticket transfers, while others may have restrictions. Check the event details or contact the organizer for specific information.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept major credit cards, debit cards, and select digital payment methods. The available payment options will be displayed during the checkout process.",
    },
    {
      question: "How do I access my tickets after purchase?",
      answer:
        "After purchasing, you'll receive an email confirmation with your tickets attached. You can also access your tickets by logging into your witzzart account and viewing your order history.",
    },
    {
      question: "What should I do if an event is cancelled or rescheduled?",
      answer:
        "If an event is cancelled or rescheduled, we'll notify you via email with information about refunds or next steps. Always ensure your contact information is up to date in your account settings.",
    },
    {
      question: "Is it safe to buy tickets on witzzart?",
      answer:
        "Yes, we use industry-standard security measures to protect your personal and payment information. All transactions are encrypted, and we never store your full credit card details.",
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="container py-5">
      <h2 className=" mb-5 text-start pl-3">Frequently Asked Questions</h2>
      <div className="accordion" id="faqsAccordion">
        {faqs.map((faq, index) => (
          <div className="accordion-item border-0" key={index}>
            <h2 className="accordion-header" id={`heading-${index}`}>
              <button
                className={`accordion-button bg-white text-dark ${
                  openIndex === index ? "" : "collapsed"
                } d-flex justify-content-between align-items-center`}
                type="button"
                onClick={() => handleToggle(index)}
                style={{
                  boxShadow: "none",
                  outline: "none",
                  backgroundColor: "white",
                  fontWeight: "bold",
                }}
              >
                <h5>{faq.question}</h5> 
                <span className="ms-2">
                  {openIndex === index ? <ChevronUp /> : <ChevronDown />}
                </span>
              </button>
            </h2>
            <div
              id={`collapse-${index}`}
              className={`accordion-collapse collapse ${
                openIndex === index ? "show" : ""
              }`}
              aria-labelledby={`heading-${index}`}
            >
              <div className="accordion-body">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
