import React, { useEffect, useState } from "react";
import "./Carousel.css";
import axios from "../../../../src/axiosInstance";
import { Link } from "react-router-dom";

const App = () => {
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [eventslist, setEventslist] = useState([]);
  const [loading, setLoading] = useState(true);
  let imageurl= "https://picsum.photos/1200/400?random=2"
  const getEvents = async () => {
    try {
      const response = await axios.get("/event");
      setEventslist(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);



  useEffect(() => {
    if (eventslist.length > 0) {
      const interval = setInterval(() => {
        setCurrentEventIndex((prevIndex) => (prevIndex + 1) % eventslist.length);
      }, 5000); // Change event every 5 seconds

      return () => clearInterval(interval);
    }
  }, [eventslist.length]);

  const currentEvent = eventslist[currentEventIndex];
  let newurl = currentEvent?.images?.[0]?.image


  if (loading) {
    return <div>Loading...</div>;
  }



  function formatDate(isoDate) {
    if (isoDate) {
      const date = new Date(isoDate);
      const options = { year: "numeric", month: "short", day: "numeric" };
      return date?.toLocaleDateString("en-US", options);
    }
  }

  function formatTime(timeString) {
    if (timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
      return `${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;
    }
  }



  return (
    <Link to={`/eventdetails/`+currentEvent?.id} className="h-100">
      {eventslist.length > 0 && (
        <div
          className="carousel h-100 border-gray"
          // style={{ backgroundImage: `url(${currentEvent?.images?.[0]?.image})`}}


          style={{
            backgroundImage: currentEvent?.images?.[0]?.image
              ? `url(${currentEvent.images[0].image})`
              : 'none',
            backgroundColor: currentEvent?.images?.[0]?.image
              ? 'transparent'
              : 'linear-gradient(to left,rgb(84, 82, 82), #d3d3d3)', // Gray gradient
          }}



        >
          <div className="event-info">
            <h2 className="">{currentEvent?.event_title}</h2>
            <p className="font-xs">{formatDate(currentEvent?.start_date)}</p>
            <button className="btn-red rounded-pill py-1 px-2 font-xs" >
              Get Tickets from {currentEvent?.tickets[0]?.price}
            </button>
          </div>
        </div>
      )}
    </Link>
  );
};

export default App;