import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from './context/Auth/AuthContext';
import Main from "./Layouts/Main";
import DashBoard from "./Pages/DashBoard/DashBoard";
import UsersList from "./Pages/Users/UsersList";
import AddEditUsers from "./Pages/Users/AddEditUser";
import Profile from "./Pages/Profile/Profile";
import Home from "./Pages/Website/Home";
import Events from "./Pages/Website/Events";
import Orders from "./Pages/Website/Orders";
import Contact from "./Pages/Website/Contact";  
import { useState } from "react";
import CategoryList from "./Pages/Category/CategoryList";
import AddEditCategory from "./Pages/Category/AddEditCategory";
import EventsList from "./Pages/Events/EventsList";
import AddEditEvents from "./Pages/Events/AddEditEvents";
import SignIn from "./Pages/Website/Login";
import SignUp from "./Pages/Website/Signup";
import WebsiteLayout from "./Pages/Website/Layout";
import EventDetailsPage from "./Pages/Website/Event_details";
import ArtisticUserProfile from "./Pages/Website/Userprofile";
import Create_Event from "./Pages/Website/Create_Event";
import About from "./Pages/Website/About";
import FAQ from "./Pages/Website/FAQ";
import Careers from "./Pages/Website/Careers";
import Press from "./Pages/Website/Press";
import HowItWork from "./Pages/Website/HowItWork";
import RefundPolicy from "./Pages/Website/RefundPolicy";
import ForOrganizer from "./Pages/Website/ForOrganizer";
import Pricing from "./Pages/Website/Pricing";
import Resource from "./Pages/Website/Resource";
import AdminLogin from "./Pages/Auth/AdminSignIn";
import PrivacyPolicy from "./Pages/Website/PrivacyPolicy";
import TermOfServices from "./Pages/Website/TermOfServices";
import CookiePolicy from "./Pages/Website/CookiePolicy";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { Loader } from "lucide-react";
import PaymentList from "./Pages/Payment/PaymentList";
import EventApprpovalList from "./Pages/Events/EventApprovalList";

function App() {

  const [isLoading, setIsLoading] = useState(false); 

  const checkToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const userId = urlParams.get("userId");      
    if (token) {
      localStorage.setItem("Token", token);
      localStorage.setItem("userId", userId);
      window.history.replaceState({}, document.title, window.location.pathname);
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkToken();
    setIsLoading(false);
  }, []);


  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }
  return (
    <AuthProvider>
    <BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        {/* Website Layout routes */}
        <Route path="/" element={<WebsiteLayout />}>
          <Route index element={<Home />} />
          {/* <Route path="event" element={<Events />} /> */}
          <Route path="order" element={<Orders />} />
          <Route path="contact" element={<Contact />} />
          <Route path="event" element={<Events />} />
          <Route path="eventdetails/:id" element={<EventDetailsPage />} />
          <Route path="myProfile" element={<ArtisticUserProfile />} />
          <Route path="profile/:id" element={<ArtisticUserProfile />} />
          <Route path="createEvent" element={<Create_Event />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="careers" element={<Careers />} />
          <Route path="press" element={<Press />} />

          <Route path="how-it-work" element={<HowItWork />} />

          <Route path="refund-policy" element={<RefundPolicy />} />

          <Route path="for-organizer" element={<ForOrganizer />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="resources" element={<Resource />} />

          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermOfServices />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
        </Route>

        <Route path="/login" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />

        <Route path="/admin-login" element={<AdminLogin />} />
        
        <Route path="/admin" element={<Main />}>
        <Route path="dashboard" element={<DashBoard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="users">
            <Route path="" element={<UsersList />} />
            <Route path="add" element={<AddEditUsers />} />
            <Route path="edit/:id" element={<AddEditUsers />} />
          </Route>
          <Route path="category">
            <Route path="" element={<CategoryList />} />
            <Route path="add" element={<AddEditCategory />} />
            <Route path="edit/:id" element={<AddEditCategory />} />
          </Route>
         
          <Route path="event-approval">
            <Route path="" element={<EventApprpovalList />} />
          </Route>
          <Route path="events">
            <Route path="" element={<EventsList />} />
            <Route path="add" element={<AddEditEvents />} />
            <Route path="edit/:id" element={<AddEditEvents />} />
          </Route>
          <Route path="payment">
            <Route path="" element={<PaymentList />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
    </AuthProvider>

  );
}

export default App;
