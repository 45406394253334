import React, { useEffect, useState } from "react";
import TopNavBar from "../../Layouts/TopNavBar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../src/axiosInstance";
import {
  showErrorToast,
  showSuccessToast,
} from "../../components/Toast/Toaster";

const AddEditUser = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [postalCode, setPostalCode] = useState("");

  useEffect(() => {
    // Fetch countries
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryNames = data.map((country) => country.name.common).sort();
        setCountries(countryNames);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const fetchStates = (country) => {
    fetch("https://countriesnow.space/api/v0.1/countries/states", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ country }),
    })
      .then((response) => response.json())
      .then((data) => {
        const stateNames = data.data.states.map((state) => state.name).sort();
        setStates(stateNames);
        setCities([]); // Reset cities when country changes
      })
      .catch((error) => console.error("Error fetching states:", error));
  };

  const fetchCities = (country, state) => {
    fetch("https://countriesnow.space/api/v0.1/countries/state/cities", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ country, state }),
    })
      .then((response) => response.json())
      .then((data) => {
        const cityNames = data.data.sort();
        setCities(cityNames);
      })
      .catch((error) => console.error("Error fetching cities:", error));
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    mobile_no: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters long")
      .required("Password is required"),
    gender: Yup.string().required("Gender is required"),
    date_of_birth: Yup.date().required("Date of Birth is required"),
    username: Yup.string().required("Username is required"),
    role: Yup.string().required("Role is required"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    postal_code: Yup.string().required("Postal Code is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile_no: "",
      password: "",
      gender: "",
      date_of_birth: "",
      username: "",
      role: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("Token");
      if (!token) {
        showErrorToast("Authentication token is missing. Please log in again.");
        return;
      }
      console.log("Submitting with token:", token);

      try {
        let result;
        if (id) {
          result = await axios.post(`/adminstaff/${id}`, values);
        } else {
          result = await axios.post('/adminstaff', values);
        }

        if (result.status === 201) {
          navigate("/admin/users");
          showSuccessToast("User added successfully");
        } else if(result.status === 200) {
          navigate("/admin/users");
          showSuccessToast("User updated successfully");
        }
         else {
          showErrorToast(result.data.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error.response || error);
        showErrorToast("Failed to add/update user. Please try again.");
      }
    },
  });


  const getUserData = async () => {
    if (id) {
      try {
        const result = await axios.get(`/getUser/${id}`);
        if (result.status === 200) {
          const userData = result.data.data;
  
          userData.date_of_birth = userData.date_of_birth ? userData.date_of_birth.split("T")[0] : "";
          userData.city = userData.city || "";
          userData.state = userData.state || "";
          userData.country = userData.country || "";
          userData.postal_code = userData.postal_code || "";
  
          console.log("Fetched user data:", userData);
  
          formik.setValues(userData);
  
          if (userData.country) {
            fetchStates(userData.country);
  
            if (userData.state) {
              fetchCities(userData.country, userData.state);
            }
          }
          
          showSuccessToast("User fetched successfully");
        } else {
          showErrorToast(result.data.message);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        showErrorToast("Failed to fetch user data.");
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <TopNavBar links={{ list: "/admin/users", add: "/admin/users/add" }} />
      <div className="section-body">
        <div className="container-fluid">
          <div className="tab-content">
            <form onSubmit={formik.handleSubmit}>
              <div className="tab-pane fade show active" id="user-add" role="tabpanel">
                <div className="card">
                  <div className="card-header">
                    <strong>Add User</strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* Name */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="name"
                            {...formik.getFieldProps("name")}
                            type="text"
                            className="form-control"
                            placeholder="Name *"
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div style={{ color: "red" }}>{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Email */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="email"
                            {...formik.getFieldProps("email")}
                            type="email"
                            className="form-control"
                            placeholder="Email *"
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div style={{ color: "red" }}>{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Mobile Number */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="mobile_no"
                            {...formik.getFieldProps("mobile_no")}
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number *"
                          />
                          {formik.touched.mobile_no && formik.errors.mobile_no ? (
                            <div style={{ color: "red" }}>{formik.errors.mobile_no}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Password */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="password"
                            {...formik.getFieldProps("password")}
                            type="password"
                            className="form-control"
                            placeholder="Password *"
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div style={{ color: "red" }}>{formik.errors.password}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Gender */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="gender"
                            {...formik.getFieldProps("gender")}
                          >
                            <option value="">Select Gender *</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          {formik.touched.gender && formik.errors.gender ? (
                            <div style={{ color: "red" }}>{formik.errors.gender}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Date of Birth */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="date_of_birth"
                            {...formik.getFieldProps("date_of_birth")}
                            type="date"
                            className="form-control"
                            placeholder="Date of Birth *"
                          />
                          {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                            <div style={{ color: "red" }}>{formik.errors.date_of_birth}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Username */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="username"
                            {...formik.getFieldProps("username")}
                            type="text"
                            className="form-control"
                            placeholder="Username *"
                          />
                          {formik.touched.username && formik.errors.username ? (
                            <div style={{ color: "red" }}>{formik.errors.username}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Role */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="role"
                            {...formik.getFieldProps("role")}
                          >
                            <option value="">Select Role *</option>
                            <option value="Admin">Admin</option>
                            <option value="Employee">Employee</option>
                            <option value="Manager">Manager</option>
                          </select>
                          {formik.touched.role && formik.errors.role ? (
                            <div style={{ color: "red" }}>{formik.errors.role}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Address Line 1 */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="address_line_1"
                            {...formik.getFieldProps("address_line_1")}
                            type="text"
                            className="form-control"
                            placeholder="Address Line 1 *"
                          />
                          {formik.touched.address_line_1 && formik.errors.address_line_1 ? (
                            <div style={{ color: "red" }}>{formik.errors.address_line_1}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Address Line 2 */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="address_line_2"
                            {...formik.getFieldProps("address_line_2")}
                            type="text"
                            className="form-control"
                            placeholder="Address Line 2"
                          />
                        </div>
                      </div>

                      {/* Country */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="country"
                            {...formik.getFieldProps("country")}
                            onChange={(e) => {
                              formik.handleChange(e);
                              fetchStates(e.target.value);
                            }}
                          >
                            <option value="">Select Country *</option>
                            {countries.map((country, index) => (
                              <option key={index} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                          {formik.touched.country && formik.errors.country ? (
                            <div style={{ color: "red" }}>{formik.errors.country}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* State */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="state"
                            {...formik.getFieldProps("state")}
                            onChange={(e) => {
                              formik.handleChange(e);
                              fetchCities(formik.values.country, e.target.value);
                            }}
                          >
                            <option value="">Select State *</option>
                            {states.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                          {formik.touched.state && formik.errors.state ? (
                            <div style={{ color: "red" }}>{formik.errors.state}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* City */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="city"
                            {...formik.getFieldProps("city")}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setPostalCode(`${e.target.value}-12345`); // Example of setting postal code
                            }}
                          >
                            <option value="">Select City *</option>
                            {cities.map((city, index) => (
                              <option key={index} value={city}>
                                {city}
                              </option>
                            ))}
                          </select>
                          {formik.touched.city && formik.errors.city ? (
                            <div style={{ color: "red" }}>{formik.errors.city}</div>
                          ) : null}
                        </div>
                      </div>

                      {/* Postal Code */}
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="postal_code"
                            {...formik.getFieldProps("postal_code")}
                            type="text"
                            className="form-control"
                            placeholder="Postal Code *"
                            onChange={(e) => {
                              formik.handleChange(e); 
                              setPostalCode(e.target.value);
                            }}
                          />
                          {formik.touched.postal_code &&
                          formik.errors.postal_code ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.postal_code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <button type="submit" className="btn btn-red">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => navigate("/admin/users")}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditUser;
