import React from "react";
import { Link } from "react-router-dom";
import {
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Linkedin,
  FacebookIcon,
} from "lucide-react";

export function Footer() {
  return (
    <footer className="bg-light d-flex align-items-center justify-content-center border-top mt-5 py-5">
      <div className="container mx-2 ">
        <div className="row g-4">
          <div className="col-12 col-md-3">
            <h5 className="fw-medium mb-3">About witzzart</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  to="/about"
                  className="text-secondary text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/careers"
                  className="text-secondary text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Careers
                </Link>
              </li>
              {/* <li>
                <Link to="/press" className="text-secondary text-decoration-none" onClick={() => window.scrollTo(0, 0)}>
                  Press
                </Link>
              </li> */}
              <li>
                <Link
                  to="/contact"
                  className="text-secondary text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-3">
            <h5 className="fw-medium mb-3">For Attendees</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  to="/"
                  className="text-secondary text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Find Event
                </Link>
              </li>
              <li>
                <Link
                  to="/how-it-work"
                  className="text-secondary text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  How It Works
                </Link>
              </li>
              <li>
                <Link
                  to="/faq"
                  className="text-secondary text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  FAQs
                </Link>
              </li>
              <li>
                <Link
                  to="/refund-policy"
                  className="text-secondary text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Refund Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-3">
            <h5 className="fw-medium mb-3">For Organizers</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  to="/for-organizer"
                  className="text-secondary text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  For Organizers
                </Link>
              </li>
              <li>
                <Link
                  to="/pricing"
                  className="text-secondary text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Pricing
                </Link>
              </li>
              {/* <li>
                <Link to="/resources" className="text-secondary text-decoration-none">
                  Resources
                </Link>
              </li>   */}
            </ul>
          </div>
          <div className="col-12 col-md-3">
            <h5 className="fw-medium mb-3">Connect With Us</h5>
            <div className="d-flex gap-3 mb-3">
              <Link
                to="https://www.linkedin.com/company/wittzart/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary text-decoration-none"
              >
                <Linkedin className="fs-4" />
              </Link>
              <Link
                to="https://www.facebook.com/TheArtWizzard"
                rel="noopener noreferrer"
                 target="_blank"
                className="text-secondary text-decoration-none"
              >
                <FacebookIcon className="fs-4" />
              </Link>
              <Link
                to="https://www.instagram.com/witzzart_?igsh=MWRyZTg3NW8xandtcw=="
                className="text-secondary text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram className="fs-4" />
              </Link>
            </div>
            <p className="text-secondary small">
              Subscribe to our newsletter for updates on events and exclusive
              offers.
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4  border-top all-width ">
          <p className="text-secondary pt-4 small mb-0">
            &copy; 2024 witzzart. All rights reserved.
          </p>
          <div className="d-flex align-items-center  pt-4 gap-3">
            <Link
              to="/privacy-policy"
              className="text-secondary text-decoration-none"
              onClick={() => window.scrollTo(0, 0)}
            >
              Privacy Policy
            </Link>

            <Link
              to="terms-of-service"
              className="text-secondary text-decoration-none"
              onClick={() => window.scrollTo(0, 0)}
            >
              Terms of Service
            </Link>
            <Link
              to="cookie-policy"
              className="text-secondary text-decoration-none"
              onClick={() => window.scrollTo(0, 0)}
            >
              Cookie Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
