import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import TopNavBar from "../../Layouts/TopNavBar";
import axios from '../../../src/axiosInstance';
import { showErrorToast, showSuccessToast } from "../../components/Toast/Toaster";

const AddEditUser = () => {
  let { id } = useParams();
  const navigate = useNavigate();


  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("Token");
      if (!token) {
          showErrorToast("Authentication token is missing. Please log in again.");
          return;
      }

      try {
        let result;
        if (values.id) {
          result = await axios.post(`/category/${id}`, values);

        } else {
          result = await axios.post('/category', values);
        }
        if (result.status === 200) {
          navigate("/admin/category");
          showSuccessToast("Category updated successfully");
        } else if(result.status === 201){
          navigate("/admin/category");
          showSuccessToast("Category added successfully");
        } else {
          showErrorToast(result.data.message);
        }
      } catch (error) {
        console.error("Error submitting form", error);
      }
    },
  });


  const getUserData = async () => {
    if (id) {
      const result = await axios.get(`category/${id}`);
      if (result.status === 200) {
        formik.setValues(result.data.data);
        showSuccessToast("Categories fetched successfully");
      }
      else {
        showErrorToast(result.data.message);
      }
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <TopNavBar links={{ list: "/admin/category", add: "/admin/category/add" }} />
      <div className="section-body">
        <div className="container-fluid">
          <div className="tab-content">
            <form onSubmit={formik.handleSubmit}>
              <div className="tab-pane fade show active" id="user-add" role="tabpanel">
                <div className="card">
                  <div className="card-header">
                    <strong>Add Category</strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* Name */}
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <input
                            id="category_name"
                            {...formik.getFieldProps("name")}
                            type="text"
                            className="form-control"
                            placeholder="Category Name *"
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div style={{ color: "red" }}>{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <button type="submit" className="btn btn-red">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => navigate("/admin/category")}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditUser;
