import React, { useEffect, useState } from 'react'
import TopNavBar from "../../Layouts/TopNavBar"
import { useNavigate } from "react-router-dom";
import DataTable2 from '../../components/DataTable2';
import { Link } from "react-router-dom";
import { FaEdit, FaTrash , FaUserAlt} from 'react-icons/fa';
import axios from '../../../src/axiosInstance'; 

const UsersList = () => {
  const [users, setUsers]=useState();
  const  getUserList = async()=>{
    const result = await axios.get('/getUsers');
    if (result.status === 200) {
      setUsers(result.data.data ? result.data.data:[]) 
    }
       
  }
  useEffect(()=>{
    getUserList()
  },[])

  const headers = [
		// { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
		{name: 'Name',	field: 'name',sortable: true,classKey: '',},
		{name: 'Role', field: 'role', sortable: true, classKey: '' },
		{name: 'Email', field: 'email', sortable: true, classKey: '' },
		{name: 'Mobile', field: 'mobile_no', sortable: true, classKey: '' },
    {name: 'City', field: 'city', sortable: true, classKey: '' },
		{name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['name', 'role_name', 'email'];


  const usersListData = users?.map((value) => {

    let buttons = [];
    //  if (authPermissions?.includes('User-Update'))
	{
    buttons.push(
      <Link
        key='editButton##1'
        type='button'
        
        to={`/admin/users/edit/${value.id}`} 
        style={{ border: 'none', background: 'none', marginRight: '10px' }}
        title='Edit'
      >
        <FaEdit color='green' size={13} />
      </Link>
    );
     }
    // buttons.push(
    //   <Link
    //     key='userButton##1'
    //     type='button'
    //     href={`# `}
    //     style={{ border: 'none', background: 'none', marginRight: '10px' }}
    //     title='Permission'
    //   >
    //     <FaUserAlt color='#232323' size={13} />
    //   </Link>
    // );
    // if(authPermissions?.includes("User-Delete"))
	{
    buttons.push(
      <button
        key='deleteButton##1'
        type='button'
        // data-id={value.id}
        // onClick={() => userDeleteHandler(value.id)}
        title='Delete'
        style={{ border: 'none', background: 'none' }}   
      >                                                                  
        <FaTrash color='red' size={13} />
      </button>
    );
    }
   
    value['action'] = buttons?.length > 0 ? buttons : '-';
    
    return value;
  });


  return (
<>
			<TopNavBar links={{ list: '/admin/users', add: '/admin/users/add '}} />
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={usersListData ? usersListData : []}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
  )
}
export default UsersList