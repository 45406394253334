import React, { useEffect } from "react";
import { useState } from "react";
import concert from "../../../assets/images/concert.png";
import { Contact, MapPin, Clock2 } from "lucide-react";

export default function GoodPreviewForm({ formData, ticketPrices }) {
  const [previewImage, setPreviewImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("overview");

  const handleImageClick = (image, index) => {
    setPreviewImage(image);
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setPreviewImage(null);
  };

  const handleThumbnailClick = (image, index) => {
    setPreviewImage(image);
    setCurrentImageIndex(index);
  };

  const [expandedTickets, setExpandedTickets] = useState(
    new Array(ticketPrices?.length).fill(false) // Initially, all tickets are collapsed
  );

  // Function to toggle expand for a specific ticket
  const toggleExpand = (index) => {
    setExpandedTickets((prev) => {
      const newExpandedTickets = [...prev];
      newExpandedTickets[index] = !newExpandedTickets[index]; // Toggle the state for the clicked ticket
      return newExpandedTickets;
    });
  };

  function generateEventDates(startDate, endDate) {
    const eventDates = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      // Get the full month name (e.g., "March" instead of "03")
      const monthName = currentDate.toLocaleString("default", {
        month: "short",
      });
      // Get the day of the month (e.g., "1" or "2")
      const dayOfMonth = currentDate.getDate();

      // Push formatted date in the required format to the array
      eventDates.push({
        date: `${monthName} ${dayOfMonth}`, // Example: "March 1"
        month: monthName,
        day: dayOfMonth,
      });

      // Increment by one day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return eventDates;
  }

  return (
    <>
      <div className="ml-2 container d-flex align-start justify-center ">
        <div className="row w-100">
          <div
            className="position-relative border rounded-2 overflow-hidden"
            style={{ height: "400px" }}
          >
            <img
              src={formData.image[0]}
              alt="Urban Art Festival 2024"
              className="w-100 h-100 object-fit-cover"
            />
          </div>

          <div className="col-12 col-md-8 px-2 height-full  ">
            <div className="row all-width ">
              <div className="mt-1">
                <ul class="nav py-5">
                  <li class="nav-item text-dark">
                    <span
                      onClick={() => setActiveTab("overview")}
                      className={`nav-link  display-6 text-dark  ${
                        activeTab === "overview" ? "fw-bold " : "fw-muted"
                      }`}
                    >
                      Overview
                    </span>
                  </li>
                  <li class="nav-item">
                    <span
                      onClick={() => setActiveTab("schedule")}
                      className={`nav-link  display-6 text-dark  ${
                        activeTab === "schedule" ? "fw-bold " : "fw-muted"
                      }`}
                    >
                      Schedule
                    </span>
                  </li>
                  <li class="nav-item">
                    <span
                      onClick={() => setActiveTab("artists")}
                      className={`nav-link  display-6 text-dark  ${
                        activeTab === "artists" ? "fw-bold " : "fw-muted"
                      }`}
                    >
                      Artists
                    </span>
                  </li>
                  <li class="nav-item">
                    <span
                      onClick={() => setActiveTab("other")}
                      className={`nav-link  display-6 text-dark  ${
                        activeTab === "other" ? "fw-bold " : "fw-muted"
                      }`}
                    >
                      Other
                    </span>
                  </li>
                </ul>

                <div className="tab-content   mt-1 ">
                  {activeTab === "overview" && (
                    <div className="border p-3 rounded-1">
                      <div className="d-flex align-items-center w-100 justify-space-between ">
                        <h5 className="text-dark fw-bold text-capitalize">
                          {formData.event_title}
                        </h5>
                        <span className=" rounded-pill font-xxs px-2 py-1 btn-red ml-3">
                          {formData.event_type}
                        </span>
                      </div>
                      <h5></h5>

                      <p className="text-gray">{formData.description}</p>
                      <p className="text-h6 mb-2 fw-semibold">Highlights:</p>
                      {formData.event_highlights.map((highlight) => {
                        return <li className="font-xs text-gray">{highlight}</li>;
                      })}

                      <div class="row mt-3">
                        <div class="col d-flex gap-2">
                          <p className="text-h6 mb-2 fw-semibold">Location:</p>
                          <span className="font-xs text-gray">{formData.address}</span>
                        </div>
                        <div class="col d-flex gap-2">
                          <p className="text-h6 mb-2 fw-semibold">Dress Code:</p>
                          <span className="font-xs text-gray">{formData.dress_code}</span>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col d-flex gap-2">
                          <p className="text-h6 mb-2 fw-semibold">Language:</p>
                          <span className="font-xs text-gray">
                            {formData.event_language}
                          </span>
                        </div>

                        <div class="col d-flex gap-2">
                          <p className="text-h6 mb-2 fw-semibold">
                            Skill Level:
                          </p>
                          <span className="font-xs text-gray">
                            {formData.skill_level}
                          </span>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <p className="text-h6 mb-2 fw-semibold">
                            Items required:
                          </p>
                          <div className="  d-flex gap-2">
                            {formData.required_equipment.map((item) => {
                              return (
                                item?.trim()?.length > 0 && ( <span className="rounded-pill text-gray font-xxs px-1 py-1 btn-red ">
                                  {item}
                                </span>)

                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === "schedule" && (
                    <div className="border p-3 rounded-1">
                      <div className="row">
                        <div className="col d-flex gap-3">
                          <p className="text-h6 mb-2 fw-semibold">
                            Start date:
                          </p>
                          <p className="font-xs mb-2 text-gray">{formData.start_date}</p>
                        </div>
                        <div className="col d-flex gap-3">
                          <p className="text-h6 mb-2 fw-semibold">End date:</p>
                          <p className="font-xs mb-2 text-gray text-gray">{formData.end_date}</p>
                        </div>
                      </div>

                      <div className="d-flex gap-2">
                        <p className="text-h6 mb-2 fw-semibold">Timing:</p>
                        <p className="font-xs mb-2 text-gray">
                          {" "}
                          {formData.start_time} - {formData.end_time}{" "}
                        </p>
                      </div>
                      <div className="d-flex gap-2">
                        <p className="text-h6 fw-semibold mb-2">Event Type:</p>
                        <p className="font-xs text-gray">{formData.location_type}</p>
                      </div>

                      <div className="d-flex gap-2">
                        <p className="text-h6 mb-2 fw-semibold">Map Link:</p>
                        <a
                          href={formData.map.startsWith("http") ? formData.map : `https://${formData.map}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray text-break font-xs redTextOnhover"
                        >
                          {formData.map}
                        </a>
                      </div>

                      <div className="d-flex gap-2">
                        <p className="text-h6 mb-2  fw-semibold">Online Link:</p>
                        <p className="font-xs text-gray">{formData.online_link}</p>
                      </div>

                      <div className=" gap-2">
                        <p className="text-h6 mb-2 fw-semibold">
                          Schedule:
                        </p>
                        <ul>
                          {formData.event_agenda.map((item) => {
                            return <li className="font-xs text-gray">{item}</li>;
                          })}
                        </ul>{" "}
                      </div>
                    </div>
                  )}
                  {activeTab === "artists" && (
                    <div className=" p-1">
                      <div class="row g=2">
                        {formData.artists.map((item) => {
                          return (
                            <div class="col-12 col-sm-7 col-md- col-lg-5 px-3 col-xl-4">
                              <div class="card border-gray custom-hover">
                                <div class="card-body">
                                  <h6 class="card-title text-truncate ">
                                    {item.artist_name}
                                  </h6>
                                  <span class="card-subtitle mb-2 font-xxs text-muted text-truncate">
                                    {item.category}
                                  </span>
                                  <p class="card-text text-truncate text-gray">
                                    {item.description}
                                  </p>
                                  <a
                                    href={item.social_media}
                                    class="card-link main-red"
                                  >
                                    <Contact size={20} />
                                  </a>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {activeTab === "other" && (
                    <div className="border p-3 rounded-1">
                      <div className="">
                        <h6 className="fw-semibold">Accessibility:</h6>
                        {formData.accessibility_options.map((item) => {
                          return <li className="font-xs text-gray">{item}</li>;
                        })}
                      </div>
                      <div className="d-flex gap-3 mt-3">
                        <h6 className="fw-semibold ">Age Group :</h6>
                        <p className="font-xs mb-0 text-gray">
                          {formData.age_restriction}
                        </p>
                      </div>

                      <h6 className="fw-semibold mt-3">Audience Type</h6>
                      {formData.audience_type.map((item) => {
                        return <ul className="mb-1 text-gray">{item}</ul>;
                      })}
                      <div className="d-flex mt-3 gap-3">
                        <h6 className="fw-semibold ">Cancellation:</h6>
                        <span className="font-xs text-gray">
                          {formData.cancellation_policy}
                        </span>
                      </div>

                      <div className="d-flex mt-3 gap-3">
                        <h6 className="fw-semibold ">Capacity:</h6>
                        <span className="font-xs text-gray">
                          {formData.event_capacity}
                        </span>
                      </div>

                      <div className="d-flex mt-3 gap-3">
                        <h6 className="fw-semibold ">Ticket type:</h6>
                        <span className="font-xs text-gray">{formData.ticket_type}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 px-0 mt-4  border rounded-1 height-full">
            <div className=" p-3 ">
              <div className="d-flex align-items-center gap-3 p-3 border rounded mb-4 text-decoration-none">
                <img
                  src="/placeholder.svg"
                  alt="Metropolitan Gallery"
                  className="rounded-circle border"
                  style={{ width: "40px", height: "40px" }}
                />
                <div>
                  <h6 className="mb-0 ">{formData.event_organizer_name}</h6>
                </div>
              </div>
              <div className="mb-4">
                <h6 className="fw-bold mb-3">Event Date:</h6>
                <div className="row g-2">
                  {/* { generateEventDates(formData.start_date, formData.end_date).map((date) => (
                    <div className="col-4" key={date.date}>
                      <button className="btn w-100 border">
                        <div className="small">Mar</div>
                        <div className="fw-bold">{date.date.split("-")[2]}</div>
                      </button>
                    </div>
                  ))} */}

                  {generateEventDates(
                    formData.start_date,
                    formData.end_date
                  ).map((date) => (
                    <div className="col-4" key={date.date}>
                      <div className="d-flex align-items-center justify-content-around py-3 rounded-3 px-2 all-width border">
                        <div className="small ">{date.month}</div>
                        <div className="fw-semibold ">{date.day}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="card-body p-0">
                {ticketPrices?.map((ticket, index) => {
                  return (
                    <div
                      key={index}
                      className="border my-1  redborderOnhover rounded-1"
                    >
                      {/* Initial Ticket Name Section */}
                      <div
                        className="d-flex align-items-center p-1 justify-content-between all-width"
                        onClick={() => toggleExpand(index)}
                      >
                        <p
                          className="font-xs  fw-bold mb-0"
                          style={{ cursor: "pointer" }}
                        >
                          {ticket.ticket_name}
                        </p>
                        <p className="font-xxs mb-0">
                          ₹{parseFloat(ticket.price)}
                        </p>
                      </div>

                      {/* Conditionally Render Expanded Data */}
                      {expandedTickets[index] && (
                        <div className="mt-2 p-1 bg-light">
                          <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs  fw-semibold">Date:</h6>
                            </div>
                            <div className="col-md-6 text-gray text-end font-xxs">
                              {new Date().toLocaleDateString()}
                            </div>
                          </div>
                          <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs fw-semibold">Time:</h6>
                            </div>
                            <div className="col-md-6 text-gray text-end font-xxs">
                              {new Date().toLocaleTimeString()}
                            </div>
                          </div>

                          <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs fw-semibold">Seats:</h6>
                            </div>
                            <div className="col-md-6 text-gray text-end font-xxs">
                              {ticket.seats}
                            </div>
                          </div>

                          <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs fw-semibold">
                                Discount:
                              </h6>
                            </div>
                            <div className="col-md-6 text-gray text-end font-xxs">
                              {ticket.discount}%
                            </div>
                          </div>

                          <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs fw-semibold">Platform fee:</h6>
                            </div>
                            <div className="col-md-6 text-gray text-end font-xxs">
                              {ticket?.slab?.buyer_fee}%
                            </div>
                          </div>

                          <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs fw-semibold">GST on platform fee:</h6>
                            </div>
                            <div className="col-md-6 text-gray text-end font-xxs">
                              {ticket?.slab?.buyer_fee_gst}%
                            </div>
                          </div>

                          <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs fw-semibold">
                                Payment Fee:
                              </h6>
                            </div>
                            <div className="col-md-6 text-end text-gray font-xxs">
                              {ticket?.slab?.seller_fee}%
                            </div>
                          </div>

                          <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs fw-semibold">GST on payment fee:</h6>
                            </div>
                            <div className="col-md-6 text-gray text-end font-xxs">
                              {ticket?.slab?.seller_fee_gst}%
                            </div>
                          </div>
                          {/* <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs  fw-semibold">
                                Organizer Payout:
                              </h6>
                            </div>
                            <div className="col-md-6 text-gray text-end font-xxs">
                              {parseFloat(ticket.organizer_payout)}
                            </div>
                          </div> */}

                          <div className="row mb-1">
                            <div className="col-md-6">
                              <h6 className="font-xxs fw-semibold">
                                Ticket Price:
                              </h6>
                            </div>
                            <div className="col-md-6 text-gray text-end font-xxs">
                              {parseFloat(ticket.price)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
