import React, { useEffect, useState } from 'react'
import TopNavBar from "../../Layouts/TopNavBar"
import { useNavigate } from "react-router-dom";
import DataTable2 from '../../components/DataTable2';
import { Link } from "react-router-dom";
import { FaEdit, FaTrash , FaUserAlt} from 'react-icons/fa';
import axios from '../../../src/axiosInstance'; 

const EventList = () => {
  const [event, setEvent]=useState();


  const  getEventList = async()=>{
    const result = await axios.get('/event');
    if (result.status === 200) {
      setEvent(result.data.data ? result.data.data:[]) 
    }
       
  }
  useEffect(()=>{
    getEventList()
  },[])

  const headers = [
    {name: 'Event Name',	field: 'event_title',sortable: true,classKey: '',},
		{name: 'Type', field: 'event_type', sortable: true, classKey: '' },
		{name: 'Address', field: 'address', sortable: true, classKey: '' },
		{name: 'Status', field: 'status', sortable: true, classKey: '' },
    {name: 'Event Duration', field: 'event_duration', sortable: true, classKey: '' },
		{name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['event_title', 'address', 'status'];


  const EventListData = event?.map((value) => {

    let buttons = [];
    //  if (authPermissions?.includes('User-Update'))
	{
    buttons.push(
      <Link
        // key='editButton##1'
        key={`editButton-${value.id}`}
        type='button'
        
        to={`/admin/events/edit/${value.id}`} 
        style={{ border: 'none', background: 'none', marginRight: '10px' }}
        title='Edit'
      >
        <FaEdit color='green' size={13} />
      </Link>
    );
     }

    // if(authPermissions?.includes("User-Delete"))
	// {
  //   buttons.push(
  //     <button
  //       key='deleteButton##1'
  //       type='button'
  //       // data-id={value.id}
  //       // onClick={() => userDeleteHandler(value.id)}
  //       title='Delete'
  //       style={{ border: 'none', background: 'none' }}   
  //     >                                                                  
  //       <FaTrash color='red' size={13} />
  //     </button>
  //   );
  //   }
   
    value['action'] = buttons?.length > 0 ? buttons : '-';
    
    return value;
  });

  console.log("checking",EventListData);
  


  return (
<>
			<TopNavBar links={{ list: '/admin/events', add: '/admin/events/add '}} />
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={EventListData ? EventListData : []}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
  )
}
export default EventList