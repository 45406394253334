import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import calendar styles
import { useEffect } from "react";
export default function DateAndTime({ formData, onChange }) {

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZones.includes(userTimeZone) && !formData.timezone) {
      onChange({ target: { name: "timezone", value: userTimeZone } });
    }
  }, [formData, onChange]);

  const timeZones = moment.tz.names();
  return (
    <div className="mb-4">
      <div className="row g-4">
        {/* Start Date */}
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Start Date*</label>
            <div className="position-relative w-100 ">
              <DatePicker

                selected={
                  formData.start_date ? new Date(formData.start_date) : null
                }
                onChange={(date) =>
                {
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                  const day = String(date.getDate()).padStart(2, "0");
                  const formattedDate = `${year}-${month}-${day}`;

                  // Pass the formatted date to the onChange handler
                  onChange({ target: { name: "start_date", value: formattedDate } });
                }
                }
                className="form-control"
                placeholderText="Select start date"
                dateFormat="yyyy-MM-dd"

                wrapperClassName="w-100" // Ensures the wrapper spans the full width
                popperClassName="w-100" // Ensures the dropdown spans full width (if needed)
                customInput={
                  <input
                    style={{
                      width: "100%", // Inline style for full width
                      display: "block", // Block-level element
                    }}
                    className="form-control"
                  />
                }
              />
            </div>
          </div>
        </div>

        {/* End Date */}
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">End Date*</label>
            <div className="position-relative w-100">
              <DatePicker
                selected={
                  formData.end_date ? new Date(formData.end_date) : null
                }
                onChange={(date) =>
                  {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, "0");
                    const formattedDate = `${year}-${month}-${day}`;

                    // Pass the formatted date to the onChange handler
                    onChange({ target: { name: "end_date", value: formattedDate } });
                  }
                }
                className="form-control"
                placeholderText="Select end date"
                dateFormat="yyyy-MM-dd"
                wrapperClassName="w-100"
                popperClassName="w-100"
                customInput={
                  <input
                    style={{
                      width: "100%",
                      display: "block",
                    }}
                    className="form-control"
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* Event Duration */}
      <div className="mb-3">
        <label className="form-label">Event Duration (hours)*</label>
        <input
          type="number"
          min="0.5"
          step="0.5"
          className="form-control"
          required
          name="event_duration"
          value={formData.event_duration}
          onChange={onChange}
        />
        <small className="form-text text-muted">
          Enter the duration of your event in hours.
        </small>
      </div>

      <div className="row g-4">
        {/* Start Time */}
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Start Time*</label>
            <input
              type="time"
              className="form-control"
              required
              name="start_time"
              value={formData.start_time}
              onChange={onChange}
            />
          </div>
        </div>

        {/* End Time */}
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">End Time*</label>
            <input
              type="time"
              className="form-control"
              name="end_time"
              required
              value={formData.end_time}
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      {/* Time Zone */}
      <div className="mb-3">
        <label className="form-label">Time Zone*</label>
        <select
          className="form-select"
          name="timezone"
          value={formData.timezone}
          required
          onChange={onChange}
        >
          <option value="">Select time zone</option>
          {timeZones.map((tz) => (
            <option key={tz} value={tz}>
              {tz}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
