export default function MarketingAssistance({ formData, onChange }) {
  const handlePackageChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "featured_event") {
      onChange({ target: { name, value: checked } });
    } else {
      const updatedPackages = checked
        ? [...formData.marketing, value]
        : formData.marketing.filter((item) => item !== value);
      onChange({ target: { name: "marketing", value: updatedPackages } });
    }
  };

  const handleAddTier = () => {
    onChange({
      target: {
        name: "tickets",
        value: [
          ...formData.tickets,
          { ticket_name: "", price: 0, discount: 0, seats: 0 },
        ],
      },
    });
  };

  const handleRemoveTier = (index) => {
    onChange({
      target: {
        name: "tickets",
        value: formData.tickets.filter((_, i) => i !== index),
      },
    });
  };

  const handleTierFieldChange = (index, field, value) => {
    const updatedTiers = formData.tickets.map((tier, i) =>
      i === index ? { ...tier, [field]: value } : tier
    );
    onChange({ target: { name: "tickets", value: updatedTiers } });
  };

  return (
    <div className="container space-y-6">
      {/* Ticket Type */}
      <div className="mb-4">
        <label className="form-label">Ticket Type*</label>
        <div>
          {["Free", "Paid", "Donation-based", "RSVP"].map((type) => (
            <div key={type} className="form-check">
              <input
              required
                className="form-check-input success-outlined"
                type="radio"
                id={type}
                name="ticket_type"
                value={type}
                checked={formData.ticket_type === type}
                onChange={onChange}
              />
              <label className="form-check-label" htmlFor={type}>
                {type}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-4 d-flex justify-content-between align-items-center border p-2 rounded">
        <div>
          <label className="form-label">Fee Absorption</label>
          <small className="form-text text-muted">Fee paid by buyer is absorbed organizer payout</small>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            name="fee_absorption"
            style={{marginLeft:"-10px"}}
            value={formData.fee_absorption}
            checked={formData.fee_absorption === 1}
            onChange={(e) => {
              const checked = e.target.checked;
              const syntheticEvent = {
                target: {
                  name: e.target.name,
                  value: checked ? 1 : 0,
                },
              };
              onChange(syntheticEvent);
            }}
          />
        </div>
      </div>

      {/* Additional Ticket Options */}
      {formData.ticket_type != "Free" && (
        <div>
          <h5>Additional Ticket Types</h5>
          <button
            type="button"
            className="btn btn-red mb-3"
            onClick={handleAddTier}
          >
            + Add Ticket Tier
          </button>
          {formData.tickets.map((tier, index) => (
            <div key={index} className="d-flex gap-3 mb-3 align-items-center">
              <input
                type="text"
                className="form-control"
                required
                placeholder="Ticket Name"
                value={tier.ticket_name || ""}
                onChange={(e) =>
                  handleTierFieldChange(index, "ticket_name", e.target.value)
                }
              />
              <input
                type="number"
                className="form-control"
                placeholder="Price"
                required
                value={tier.price || ""}
                onChange={(e) =>
                  handleTierFieldChange(index, "price", parseInt(e.target.value))
                }
              />
              <input
                type="number"
                className="form-control"
                placeholder="Discount"

                value={tier.discount || ""}
                onChange={(e) =>
                  handleTierFieldChange(index, "discount", parseInt(e.target.value))
                }
              />
              <input
                type="number"
                className="form-control"
                placeholder="Seats"
                required
                value={tier.seats || ""}
                onChange={(e) =>
                  handleTierFieldChange(index, "seats", parseInt(e.target.value))
                }
              />
              <button
                type="button"
                className="btn btn-red"
                onClick={() => handleRemoveTier(index)}
              >
                -
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Featured Event */}

      {/* Marketing Assistance Package */}
      <div className="mb-4">
        <label className="form-label">Marketing Assistance Package</label>
        <small className="form-text text-muted">
          Select the marketing assistance options you'd like for your event.
        </small>
        <div className="mt-2">
          {Object.keys(formData.marketing).map((key) => {
            if (key) {
              return (
                <div key={key} className="form-check">
                  <input
                    type="checkbox"
                    name={key}
                    value={key}
                    checked={formData.marketing[key]}
                    onChange={() => {
                      const newMarketing = {
                        ...formData.marketing,
                        [key]: !formData.marketing[key],
                      };
                      onChange({
                        target: { name: "marketing", value: newMarketing },
                      });
                    }}
                    className="form-check-input"
                  />
                  <label className="form-check-label">
                    {key
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                  </label>
                  {key === "featured_event" && (
                    <small className="rounded-pill border-gray ml-2 main-red px-2">
                      free
                    </small>
                  )}
                  {key === "social_media_promotion" && (
                    <small className="rounded-pill border-gray ml-2 main-red px-2">
                      free
                    </small>
                  )}
                </div>
              );
            }

            return null;
          })}
        </div>
        {/* {formData.marketing.tailored_solution && (
          <div className="mt-3">
            <div className="mb-3">
              <label className="form-label">Strategy</label>
              <input
                type="text"
                name="strategy"
                value={formData.marketing.tailored_solution.strategy || ""}
                onChange={(e) => {
                  const newTailoredSolution = {
                    ...formData.marketing.tailored_solution,
                    strategy: e.target.value,
                  };
                  const newMarketing = {
                    ...formData.marketing,
                    tailored_solution: newTailoredSolution,
                  };
                  onChange({
                    target: { name: "marketing", value: newMarketing },
                  });
                }}
                className="form-control"
                placeholder="Enter strategy"
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Budget</label>
              <input
                type="number"
                name="budget"
                value={formData.marketing.tailored_solution.budget || ""}
                onChange={(e) => {
                  const newTailoredSolution = {
                    ...formData.marketing.tailored_solution,
                    budget: e.target.value,
                  };
                  const newMarketing = {
                    ...formData.marketing,
                    tailored_solution: newTailoredSolution,
                  };
                  onChange({
                    target: { name: "marketing", value: newMarketing },
                  });
                }}
                className="form-control"
                placeholder="Enter budget"
              />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}
