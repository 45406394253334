import React from 'react'
import { CheckCircle, BarChart, Users, Calendar } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
export default function ForOrganizersPage() {
  const navigate = useNavigate()
  const features = [
    {
      icon: Calendar,
      title: "Easy Event Creation",
      description: "Create and publish your event in minutes with our intuitive event creation tools."
    },
    {
      icon: Users,
      title: "Reach a Wider Audience",
      description: "Tap into our extensive user base of art enthusiasts and event-goers."
    },
    {
      icon: BarChart,
      title: "Powerful Analytics",
      description: "Get real-time insights into ticket sales, attendee demographics, and more."
    },
    {
      icon: CheckCircle,
      title: "Seamless Ticket Management",
      description: "Easily manage ticket types, pricing, and availability for your events."
    }
  ]

  const testimonials = [
    {
      name: "Sarah Chen",
      role: "Gallery Owner",
      image: "/placeholder.svg?height=100&width=100",
      quote: "witzzart has revolutionized how we promote and manage our exhibitions. The platform's ease of use and powerful features have helped us reach a wider audience and streamline our operations."
    },
    {
      name: "Michael Rodriguez",
      role: "Event Coordinator",
      image: "/placeholder.svg?height=100&width=100",
      quote: "As an event coordinator, I've used many ticketing platforms, but witzzart stands out for its user-friendly interface and excellent customer support. It's become an indispensable tool for our art events."
    }
  ]

  return (
    <div className="custom-container py-5">
      <section className="text-center mb-5">
        <h2 className=" font-weight-bold mb-4">Empower Your Creative Events with witzzart</h2>
        <p className="lead text-muted mb-4">
          Join thousands of organizers who trust witzzart to bring their creative visions to life.
        </p>
        <button className="btn btn-red btn-lg rounded-pill px-4 py-2">Get Started</button>
      </section>

      <section className="mb-5">
        <h2 className="text-center h2 text-black font-weight-bold mb-4">Why Choose witzzart?</h2>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
          {features.map((feature, index) => {
            const Icon = feature.icon
            return (
              <div className="col" key={index}>
                <div className="card border text-center p-4">
                  <div className="card-body">
                    <Icon className="w-10 h-10 main-red mb-3" size={40} />
                    <h5 className="card-title h5 font-weight-bold mb-2">{feature.title}</h5>
                    <p className="card-text text-muted">{feature.description}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>

      <section className="mb-5">
        <h2 className="text-center h2 font-weight-bold mb-4">How It Works</h2>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-4 text-center d-flex flex-column align-items-center justify-content-center">
            <div className="rounded-circle  main-red  mx-auto d-flex align-items-center justify-content-center mb-3" style={{backgroundColor:"rgb(254 226 226 / var(--tw-bg-opacity, 1))" }}>
              <span className="h4 font-weight-bold mb-0 px-3 py-2" >1</span>
            </div>
            <h3 className="h5 font-weight-bold mb-2">Create Your Event</h3>
            <p className="text-muted">Set up your event details, ticket types, and pricing in our easy-to-use dashboard.</p>
          </div>
          <div className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-4 text-center d-flex flex-column align-items-center justify-content-center">
            <div className="rounded-circle  main-red  mx-auto d-flex align-items-center justify-content-center mb-3" style={{ backgroundColor:"rgb(254 226 226 / var(--tw-bg-opacity, 1))" }}>
              <span className="h4 font-weight-bold mb-0 px-3 py-2" >2</span>
            </div>
            <h3 className="h5 font-weight-bold mb-2">Promote and Sell</h3>
            <p className="text-muted">Leverage our marketing tools and user base to boost ticket sales for your event.</p>
          </div>
          <div className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-4 text-center d-flex flex-column align-items-center justify-content-center">
            <div className="rounded-circle  main-red  mx-auto d-flex align-items-center justify-content-center mb-3" style={{ backgroundColor:"rgb(254 226 226 / var(--tw-bg-opacity, 1))" }}>
              <span className="h4 font-weight-bold mb-0 px-3 py-2" >3</span>
            </div>
            <h3 className="h5 font-weight-bold mb-2">Manage and Analyze</h3>
            <p className="text-muted">Track sales, manage attendees, and gain insights with our powerful analytics tools.</p>
          </div>
        </div>
      </section>

      {/* <section className="mb-5">
        <h2 className="text-center h2 font-weight-bold mb-4">What Organizers Say</h2>
        <div className="row row-cols-1 row-cols-md-2 g-4">
          {testimonials.map((testimonial, index) => (
            <div className="col" key={index}>
              <div className="card p-4">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    <image
                      src={testimonial.image}
                      alt={testimonial.name}
                      width={60}
                      height={60}
                      className="rounded-circle mr-3"
                    />
                    <div>
                      <h5 className="font-weight-bold">{testimonial.name}</h5>
                      <p className="text-muted">{testimonial.role}</p>
                    </div>
                  </div>
                  <p className="text-muted font-italic">"{testimonial.quote}"</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section> */}

      <section className="text-center mb-5">
        <h2 className="h2 font-weight-bold mb-4">Ready to Get Started?</h2>
        <p className="lead text-muted mb-4">
          Join the witzzart community and take your art events to the next level.
        </p>
        <button className="btn btn-red btn-lg rounded-pill px-5 py-3" onClick={()=> {navigate("/createEvent"); window.scrollTo(0, 0)}}>Create Your First Event</button>
      </section>
    </div>
  )
}
