export default function TermOFService() {
  return (
    <div className="custom-container my-5  px-3">
      <h2 className=" font-weight-bold mb-4">Terms of Service </h2>
      <div className="mb-4">
        <p>
          <strong>Last updated:</strong> [28th January 2025]
        </p>
        <p>
          Welcome to Witzzart! These Terms of Service ("Terms", "Agreement")
          govern your use of the Witzzart website, platform, and services
          (collectively, the "Services"). By accessing or using our Services,
          you agree to be bound by these Terms. If you do not agree with any
          part of these Terms, you may not use our Services.
        </p>

        <h5 className="mt-5 font-weight-semibold ">1. Acceptance of Terms</h5>
        <p className="text-gray font-sm py-3">
          By using Witzzart, you confirm that you have read, understood, and
          agree to these Terms. If you are using our Services on behalf of an
          organization, you represent that you have the authority to bind that
          organization to these Terms.
        </p>

        <h5 className=" font-weight-semibold ">
          2. Description of Services
        </h5>
        <p className="text-gray font-sm py-3">
          Witzzart is an online platform that connects event organizers with
          attendees. We enable organizers to list and sell tickets to their
          events and allow users to discover, book, and attend these events.
        </p>

        <h5 className=" font-weight-semibold ">3. User Accounts</h5>
        <h6 className="py-2 font-weight-medium ">3.1 Account Creation</h6>
        <p className="text-gray font-sm py-1">
          To access certain features, you must create an account. You agree to
          provide accurate, current, and complete information during
          registration and to update it as necessary.
        </p>

        <h6 className="py-2 font-weight-medium ">3.2 Account Security</h6>
        <p className="text-gray font-sm py-1">
          You are responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account. Notify us immediately at support@witzzart.com if you suspect
          unauthorized access.
        </p>

        <h5 className=" font-weight-semibold ">
          4. Event Listings and Ticket Purchases
        </h5>
        <h6 className="py-2 font-weight-medium ">
          4.1 Organizer Responsibilities
        </h6>
        <p className="text-gray font-sm py-1">
          Event organizers are solely responsible for the accuracy of their
          event listings, including event details, ticket prices, and
          availability.
        </p>

        <h6 className="py-2 font-weight-medium ">
          4.2 Attendee Responsibilities
        </h6>
        <p className="text-gray font-sm py-1">
          By purchasing a ticket, you agree to comply with the event organizer’s
          terms, including age restrictions, entry requirements, and refund
          policies.
        </p>

        <h6 className="py-2 font-weight-medium ">
          4.3 Refunds and Cancellations
        </h6>
        <p className="text-gray font-sm py-1">
          All ticket sales are final unless an event is canceled or rescheduled.
          Refunds or exchanges are subject to the organizer’s policies.
        </p>

        <h5 className=" font-weight-semibold ">5. Fees and Payments</h5>
        <h6 className="py-2 font-weight-medium ">5.1 Service Fees</h6>
        <p className="text-gray font-sm py-1">
          We charge service fees for using our platform. These fees will be
          displayed during checkout.
        </p>
        <h6 className="py-2 font-weight-medium ">5.2 Payment Obligations</h6>
        <p className="text-gray font-sm py-1">
          You agree to pay all fees and taxes associated with your use of the
          Services.
        </p>

        <h5 className=" font-weight-semibold ">6. Intellectual Property</h5>
        <p className="text-gray font-sm py-3">
          All content, features, and functionality on Witzzart (including logos,
          text, and graphics) are owned by Witzzart or its licensors and are
          protected by copyright, trademark, and other laws. You may not use our
          intellectual property without prior written consent.
        </p>

        <h5 className=" font-weight-semibold ">7. User Content</h5>
        <h6 className="py-2 font-weight-medium ">7.1 Ownership</h6>
        <p className="text-gray font-sm py-1">
          You retain ownership of any content you submit, post, or display on
          Witzzart.
        </p>
        <h6 className="py-2 font-weight-medium ">7.2 License to Witzzart</h6>
        <p className="text-gray font-sm py-1">
          By submitting content, you grant us a worldwide, non-exclusive,
          royalty-free license to use, reproduce, modify, and distribute it for
          the purpose of providing and promoting our Services.
        </p>

        <h5 className="font-weight-bold">8. Prohibited Activities </h5>
        <span>You agree not to:</span>
        <ul className="py-3">
          <p className="text-gray font-sm">
            • Use the Services for illegal purposes or in violation of any laws.
          </p>
          <p className="text-gray font-sm">• Harass, abuse, or harm others.</p>
          <p className="text-gray font-sm">
            • Impersonate another person or entity.
          </p>
          <p className="text-gray font-sm">
            • Share or use another user’s account.
          </p>
          <p className="text-gray font-sm">
            • Attempt to bypass any security or content filtering measures.
          </p>
        </ul>

        <h5 className="font-weight-bold">9. Limitation of Liability</h5>
        <p className="text-gray font-sm py-3">
          To the fullest extent permitted by law, Witzzart and its directors,
          employees, partners, and affiliates will not be liable for any
          indirect, incidental, special, or consequential damages (including
          loss of profits, data, or goodwill) arising from your use of the
          Services.
        </p>

        <h5 className="font-weight-bold">10. Governing Law</h5>
        <p className="text-gray font-sm py-3">
          These Terms are governed by the laws of India, without regard to its
          conflict of law principles.
        </p>

        <h5 className="font-weight-bold">11. Changes to Terms</h5>
        <p className="text-gray font-sm py-3">
          We may update these Terms from time to time. If we make significant
          changes, we will notify you by posting the updated Terms on our
          platform and updating the "Last updated" date. Your continued use of
          the Services after such changes constitutes acceptance of the new
          Terms.
        </p>

        <h5 className="font-weight-bold">12. Contact Us</h5>
        <p className="text-gray font-sm py-2">
          If you have any questions about these Terms, please contact us at:
        </p>
        <p  className="text-gray font-sm">Email: support@witzzart.com</p>
        <p  className="text-gray font-sm">Phone: +91-9675624255</p>
        <p  className="text-gray font-sm">Office Address: I-Start Incubation, Udaipur, Rajasthan 313001</p>
      </div>
    </div>
  );
}
