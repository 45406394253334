export default function EventDetails({ formData, onChange }) {

  const handleCheckboxChange = (e, option, field) => {
    const { checked } = e.target;
      const updatedOptions = checked
      ? [...formData[field], option]
      : formData[field].filter((item) => item !== option);
      onChange({ target: { name: field, value: updatedOptions } });
  };

  const handleAddTier = () => {
    onChange({ target: { name: "artists", value: [...formData.artists, { artist_name: "", category: "", description: "", social_media:"" }] } });
  };

  const handleRemoveTier = (index) => {
    onChange({ target: { name: "artists", value: formData.artists.filter((_, i) => i !== index) } });
  };

  const handleTierFieldChange = (index, field, value) => {
    const updatedTiers = formData.artists.map((tier, i) =>
      i === index ? { ...tier, [field]: value } : tier
    );
    onChange({ target: { name: "artists", value: updatedTiers } });
  };


  return (
    <div className="container space-y-6">
      {/* Event Highlights */}

      <div>
          <h6>Featured Artists</h6>
          <button
            type="button"
            className="btn btn-red mb-3"
            onClick={handleAddTier}
          >
            + Add Artist
          </button>
          {formData.artists.map((tier, index) => (
            <div key={index} className="d-flex gap-3 mb-3 align-items-center">
              <input
                type="text"
                className="form-control"
                placeholder="Artist Name"
                value={tier.artist_name}
                onChange={(e) => handleTierFieldChange(index, "artist_name", e.target.value)}
              />
              <input

                className="form-control"
                placeholder="Category"
                type="text"
                value={tier.category}
                onChange={(e) => handleTierFieldChange(index, "category", e.target.value)}
              />
              <input

                className="form-control"
                type="text"
                placeholder="Description"
                value={tier.description}
                onChange={(e) => handleTierFieldChange(index, "description", e.target.value)}
              />
               <input
                className="form-control"
                type="text"
                placeholder="Social Media"
                value={tier.social_media}
                onChange={(e) => handleTierFieldChange(index, "social_media", e.target.value)}
              />
              <button
                type="button"
                className="btn btn-red"
                onClick={() => handleRemoveTier(index)}
              >
                -
              </button>
            </div>
          ))}
        </div>

      <div className="mb-4">
        <label className="form-label">Event Highlights</label>
        <textarea
          name="event_highlights"
          className="form-control"
          placeholder="Enter key highlights of your event, separated by new line "
          value={formData.event_highlights.map(item => item).join("\n")}
          onChange={(e) => {
            const value = e.target.value;
            const highlightsArray = value.split("\n").map((line) => line);
            onChange({ target: { name: "event_highlights", value: highlightsArray } });
          }}
          style={{ whiteSpace: "pre-wrap" }}
        />
        <small className="form-text text-muted">
          Each line will be treated as a separate highlight
        </small>
      </div>

      {/* Event Agenda */}
      <div className="mb-4">
        <label className="form-label">Event Agenda</label>
        <textarea
          name="event_agenda"
          className="form-control"
          placeholder="Enter agenda items in the format 'time: activity', one per line"
          value={formData.event_agenda.map(item => item).join("\n")}
          onChange={(e) => {
            const value = e.target.value;
            const highlightsArray = value.split("\n").map((line) => line);
            onChange({ target: { name: "event_agenda", value: highlightsArray } });
          }}
          style={{ whiteSpace: "pre-wrap" }}
        />
        <small className="form-text text-muted">
          Format: "10:00 AM: Opening ceremony", one item per line
        </small>
      </div>

      {/* Event Language */}
      <div className="mb-4">
        <label className="form-label">Event Language</label>
        <select
          name="event_language"
          className="form-select"
          value={formData.event_language}
          onChange={onChange}
        >
          <option value="English">English</option>
          <option value="English">Hindi</option>
          <option value="Spanish">Spanish</option>
          <option value="French">French</option>
          <option value="German">German</option>
          <option value="Chinese">Chinese</option>
          <option value="Japanese">Japanese</option>
        </select>
      </div>

      {/* Event Capacity */}
      <div className="mb-4">
        <label className="form-label">Event Capacity</label>

        <input
          type="number"
          name="event_capacity"
          className="form-control"
          placeholder="Enter maximum number of attendees"
          value={formData.event_capacity}
          onChange={onChange}
        />
      </div>

      {/* Required Equipment */}
      <div className="mb-4">
        <label className="form-label">Required Equipment</label>
        <textarea
          name="required_equipment"
          className="form-control"
          placeholder="Enter required equipment, separated by commas"
          value={formData.required_equipment.join(", ")}
          onChange={(e) => {
            const value = e.target.value.trimStart();
            const highlightsArray = value.split(",").map((line) => line.trimStart());
            onChange({ target: { name: "required_equipment", value: highlightsArray } });
          }}
        />
        <small className="form-text text-muted">
          Comma-separated list of items attendees need to bring
        </small>
      </div>

      {/* Dress Code */}
      <div className="mb-4">
        <label className="form-label">Dress Code</label>
        <select
          name="dress_code"
          className="form-select"
          value={formData.dress_code}
          onChange={onChange}
        >
          <option value="Casual">Casual</option>
          <option value="Business Casual">Business Casual</option>
          <option value="Formal">Formal</option>
          <option value="Cocktail Attire">Cocktail Attire</option>
          <option value="Black Tie">Black Tie</option>
          <option value="Theme-based">Theme-based</option>
        </select>
      </div>

      {/* Accessibility Options */}
      <div className="mb-4">
        <label className="form-label">Accessibility Options</label>
        <div>
          {[
            "Wheelchair access",
            "Sign language interpreter",
            "Assistive listening devices",
            "Braille materials",
          ].map((option) => (
            <div key={option} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={option}
                checked={formData.accessibility_options.includes(option)}
                onChange={(e) =>
                  handleCheckboxChange(e, option, "accessibility_options")
                }
              />
              <label className="form-check-label" htmlFor={option}>
                {option}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Dynamic Artist Fields */}

    </div>
  );
}
