const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <div>
                <div className="section-body">
                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    Copyright © {currentYear}{' '}
                                    <a></a>
                                    .
                                </div>
                                <div className="col-md-6 col-sm-12 text-md-right">
                                    <ul className="list-inline mb-0">
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
}

export default Footer
