import React from 'react'
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const navigate = useNavigate();

  return (
    <div>
     <div className="section-body">
        <div className="container-fluid">
          <div className="tab-content">
            <form >
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong>User Profile</strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="uname"
                            name="uname"
                            type="text"
                            className="form-control"
                            placeholder="User-Name *"
                            
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                          className="form-control show-tick"
                            id="department"
                            name="department"
                            disabled
                          >
                            <option>Select Department</option>
                            <option>Documentation</option>
                            <option>Procurement</option>
                            <option>Production</option>
                        </select>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                          className="form-control show-tick"
                            id="role_id"
                            name="role_id"
                            disabled
                          >
                            <option>Select Role</option>
                            <option>Role 1</option>
                            <option>Role 2</option>
                            <option>Role 3</option>
                        
                        </select>
                        </div>
                      </div>
                      <div className="col-12 text-right">
                        <hr className="mt-4" />

                        <button
                          type="button"
                          id="button_1"
                          className="btn btn-secondary mx-1 "
                          data-dismiss="modal"
                          onClick={() => navigate("/dashboard")}
                        >
                          CLOSE
                        </button>

                        <button
                          type="submit"
                          id="button_2"
                          className="btn btn-primary"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile