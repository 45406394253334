import React, { useState, useEffect } from 'react';
import { CalendarToday, LocationOn, AccessTime, AttachMoney } from '@mui/icons-material';
import eventbanner from "../../assets/images/eventbannerrr.avif";
import artclassbanner from "../../assets/images/artclassbanner.webp";
import Cards from "../Website/Cards";
const EventsPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const events = [
    {
      id: 1,
      title: "Summer Music Festival",
      date: "2024-07-15",
      time: "16:00",
      location: "Central Park",
      description: "An amazing outdoor music festival featuring top artists and bands.",
      price: 49.99,
      image:artclassbanner,
    },
    {
      id: 2,
      title: "Tech Conference 2024",
      date: "2024-08-20",
      time: "09:00",
      location: "Convention Center",
      description: "Join industry leaders for the biggest tech conference of the year.",
      price: 299.99,
      image:eventbanner,
    },
    {
      id: 3,
      title: "Food & Wine Exhibition",
      date: "2024-09-05",
      time: "18:30",
      location: "City Hall",
      description: "Experience culinary excellence with master chefs and sommeliers.",
      price: 79.99,
      image:artclassbanner,
    },
    {
      id: 4,
      title: "Art Gallery Opening",
      date: "2024-08-10",
      time: "19:00",
      location: "Modern Art Museum",
      description: "Be the first to see this stunning new collection of contemporary art.",
      price: 25.00,
      image:eventbanner,
    },
    {
      id: 5,
      title: "Comedy Night Special",
      date: "2024-08-25",
      time: "20:00",
      location: "Laugh Factory",
      description: "A night of non-stop laughter with top comedians.",
      price: 35.00,
      image:artclassbanner,
    },
    {
      id: 6,
      title: "Business Summit 2024",
      date: "2024-09-15",
      time: "10:00",
      location: "Grand Hotel",
      description: "Network with industry leaders and learn about future trends.",
      price: 199.99,
      image:eventbanner,
    }
  ];
 
  const handleSelect = (index) => setActiveIndex(index);

  return (
    <div className="events-page">
      {/* Banner Section */}
      {/* <div id="eventsCarousel" className="carousel slide" data-bs-ride="carousel" style={{maxHeight:'40vh'}}> */}
      {/* Carousel Indicators */}
      {/* <div className="carousel-indicators">
        {events.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#eventsCarousel"
            data-bs-slide-to={index}
            className={index === activeIndex ? "active" : ""}
            aria-current={index === activeIndex}
            aria-label={`Slide ${index + 1}`}
            onClick={() => handleSelect(index)}
          />
        ))}
      </div> */}

      {/* Carousel Items */}
      {/* <div className="carousel-inner"  style={{maxHeight:'40vh'}}>
        {events.map((event, index) => (
          <div
            key={event.id}
            className={`carousel-item ${index === activeIndex ? "active" : ""}`}
          >
            <img src={event.image} className="d-block w-100 h-100" alt={event.title}  />
            <div className="carousel-caption d-none d-md-block">
              <h5>{event.title}</h5>
              <p>{event.description}</p>
            </div>
          </div>
        ))}
      </div> */} 

      {/* Carousel Controls */}
      {/* <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#eventsCarousel"
        data-bs-slide="prev"
        onClick={() => handleSelect((activeIndex - 1 + events.length) % events.length)}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#eventsCarousel"
        data-bs-slide="next"
        onClick={() => handleSelect((activeIndex + 1) % events.length)}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div> */}

      {/* Events Grid Section */}
      <div className="container py-5">
        <div className="row g-4">
          {events.map((event, index) => (
           

           <Cards 
              eventDetails={{
                title: event.title,
                description: event.description,
                date: event.date,
                time: event.time,
                location: event.location,
                price:event.price,
                image:event.image ,
                route:"eventdetails"
              }}
            />

          ))}
        </div>
      </div>
    </div>
  );
};

export default EventsPage;
