import React from 'react'

const Resource = () => {
  return (
    <div>
            Coming Soon

    </div>
  )
}

export default Resource
