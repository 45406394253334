import React, { useState } from 'react';
import axios from '../../../src/axiosInstance';
import logo from '../../assets/images/logo-text.svg';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from "react-toastify";

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async(e) => {
    e.preventDefault();
      let value={
        email:username,
        password:password,
      }
      try {
        const token = await axios.post(`/login`, value);
        localStorage.setItem('Token', token.data.token)
        localStorage.setItem('userId', token.data.user.id)
        console.log('Login submitted', token);
        toast.success("Login successful!");
        navigate('/');
      } catch (error) {
        toast.error("Login failed! Please try again.");
      }

  };

  const handleSocialLogin = async () => {
    try {      
      window.open(
        `${process.env.REACT_APP_API_PATH}auth/google`, "_self"
      );
    } catch (error) {
      console.error("Social login error:", error);
    }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row h-100">
        <div className="col-md-6 d-flex flex-column justify-content-center p-5">
          <div className="fixed-top p-2  d-flex align-items-center ">
          <Link to="/" className=" text-decoration-none">
              <img
                src={logo}
                alt="SVG 2"
                className="img-fluid"
                style={{ width: "100px", height: "auto" }}
              />
            </Link>

          </div>
          <h2 className="mb-2">Welcome to Witzzart</h2>
          <p className="text-muted mb-4">
            Your gateway to personalized art experiences and creative events
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="your-email@gmail.com"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
              </div>
              <a href="#" className="text-muted">Forgot Password</a>
            </div>
            <button
            type="submit"
            className="btn btn-primary w-100 mb-3"
            style={{ backgroundColor: '#ff3131', borderColor: '#ff3131', borderRadius: '20px' }}>
            Log In
            </button>

          </form>
          <div className="text-center my-3">Or</div>
          <button className="btn btn-outline-dark w-100 d-flex align-items-center justify-content-center" onClick={handleSocialLogin}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google me-2" viewBox="0 0 16 16">
              <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
            </svg>
            Continue with Google
          </button>
        </div>
        <div className="col-md-6 d-none d-md-block p-0">
          <img
            src="././assets/images/Loginphoto.jpg"
            alt="Witzzart login screen"
            className=" w-100"
            style={{ height: '100vh' }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;