import { useContext, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import AuthContext from '../../context/Auth/AuthContext';
import axiosInstance from '../../../src/axiosInstance';
import logo from '../../assets/images/logo-text.svg';
import { showErrorToast, showSuccessToast } from '../../components/Toast/Toaster';

const AdminSignIn = () => {
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      email,
      password,
    };

    try {
      const response = await axiosInstance.post(`/adminPortallogin`, payload);          
      if (response.data.token) {
        localStorage.setItem('Token', response.data.token);
        setUser(response.data);
        showSuccessToast("Login successful");
        navigate('/admin/dashboard');
      } else {
        showErrorToast("Login failed: Invalid response format");
        console.log("Login failed: Invalid response format");
      }
    } catch (error) {
      showErrorToast("Login failed: " + (error.response?.data?.message || error.message));
      console.error("Login failed", error);
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center">
      <div className="p-4 w-100 text-center" style={{ maxWidth: "400px" }}>
        <img src={logo} alt="Admin Logo" className="img-fluid mb-3" style={{ width: "120px" }} />
        <h2 className="mb-3">Admin Panel Login</h2>
        <p className="text-muted">Sign in to manage the admin dashboard</p>

        <form onSubmit={handleSubmit} className="w-100">
          <div className="mb-3 text-start">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="admin@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3 text-start">
            <label htmlFor="password" className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
            </div>
            <Link to="/forgot-password" className="text-muted">Forgot Password?</Link>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-100 mb-3"
            style={{ backgroundColor: '#ff3131', borderColor: '#ff3131', borderRadius: '20px' }}>
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminSignIn;
