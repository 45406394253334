import { useState, useEffect } from "react";
import { Search, MapPin, UserRound, Menu } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo-text.svg";
import axios from "../../../axiosInstance";
export function Header() {
  const routeLocation = useLocation();
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("Select Location");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const id = localStorage.getItem("userId");
  const userID = localStorage.getItem("Token");
  const [isOpen, setIsOpen] = useState(false); // State to track dropdown visibility

  let locations = [
    "Mumbai",
    "Delhi",
    "Bangalore",
    "Hyderabad",
    "Ahmedabad",
    "Chennai",
    "Kolkata",
    "Pune",
    "Jaipur",
    "Lucknow",
    "Surat",
    "Kanpur",
    "Nagpur",
    "Indore",
    "Thane",
    "Bhopal",
    "Visakhapatnam",
    "Patna",
    "Vadodara",
    "Ghaziabad",
    // Add more cities as needed
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown state
    setDropdownOpen(false)

  };

  const Logout =()=>{
    localStorage.setItem("userId","");
    localStorage.setItem("Token","");
    setIsOpen(false)

    navigate("/")
  }

  useEffect(() => {
    // Get user's location
    // if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          // Reverse Geocoding using OpenStreetMap API
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );
          const city = response.data.address.city || response.data.address.state;
          console.log("asedrtyuio",response);
          if(!locations.includes(city))
          {
            locations.push(city)
          }
          setSelectedLocation(city);

        } catch (error) {
          console.error("Error fetching location:", error);
        } finally {
          // setLoading(false);
        }
      }, (error) => {
        console.error("Geolocation Error:", error);
        // setLoading(false);
      });
    // } else {
    //   console.error("Geolocation is not supported by this browser.");
    //   // setLoading(false);
    // }
  }, []);

  useEffect(() => {
    setMenuOpen(false);
    return () => {
      console.log("Cleaning up..."); // Ensure it's a function
    };
  }, [routeLocation.pathname]);
  return (
    <header className="bg-white sticky-top border-bottom all-width py-2 px-3 border-gray mb-2">
      <div className="container-fluid d-flex align-items-center px-0 justify-content-between py-1">
        <div className="d-flex align-items-center gap-4">
          <Link to="/" className=" text-decoration-none">
            <img
              src={logo}
              alt="Witzzart"
              className="img-fluid"
              style={{ width: "100px", height: "auto" }}
            />
          </Link>
        </div>
        {/* Desktop Menu */}
        <div className="d-none d-md-flex align-items-center gap-4">
          <div className="position-relative w-100">
            {/* <input
              type="search"
              className="form-control pl-5 py-2 rounded-pill"
              placeholder="Search events or artists..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            /> */}
          </div>

          <div className="dropdown">
            <button
              className="btn btn-light  border-0 dropdown-toggle"
              onClick={() => {setDropdownOpen(!dropdownOpen);setIsOpen(false)}}
            >
              <MapPin size={20} className="pr-1 pb-1" />
              {selectedLocation}
            </button>
            {dropdownOpen && (
              <ul
                className="dropdown-menu pa-0 show"
                style={{ maxHeight: "200px", overflow: "scroll" }}
              >
                {locations.map((location) => (
                  <li
                    key={location}
                    className={` py-1 rounded mx-1  dropdown-item ${
                      location === selectedLocation
                        ? "bg-danger text-white"
                        : ""
                    }`}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        location === selectedLocation ? "lightcoral" : "white",
                      color: location === selectedLocation ? "white" : "black",
                    }}
                    onClick={() => {
                      setSelectedLocation(location);
                      setDropdownOpen(false);
                    }}
                  >
                    {location}
                  </li>
                ))}
              </ul>
            )}
          </div>





          <Link to="/createEvent">
            <button className=" py-1 btn-red rounded-pill fw-small text-truncate">
              Create Event
            </button>
          </Link>

          <div className="dropdown">
            {/* Toggle Dropdown on Click */}
            <button
              className="btn  rounded-pill fw-small"
              type="button"
              onClick={toggleDropdown} // Manages dropdown visibility
              aria-expanded={isOpen}
            >
              <UserRound size={16} />
            </button>

            {/* Dropdown Menu - Show only when isOpen is true */}
            <ul
              className={`dropdown-menu ${
                isOpen ? "show" : ""
              } dropdown-menu-start `}
            >
              {id?.length > 0 ? (
                <ul className="m-0 p-0">
                  <Link
                    to={`/profile/${id}`}
                    onClick={()=>setIsOpen(false)}
                    className="dropdown-item rounded-2 d-flex align-items-center"
                  >
                    My Profile
                  </Link>
                  <li className="dropdown-item rounded-2" onClick={Logout}>Log Out</li>
                </ul>
              ) : (
                <Link to="/signup" className="dropdown-item rounded-2">
                  Sign In
                </Link>
              )}

              <li></li>
            </ul>
          </div>
          <Link to="/contact">
            <button className="btn fw-small">Help</button>
          </Link>

        </div>

        {/* Mobile Menu */}
        <div className="d-md-none">
          <Link to="/createEvent">
            <button className="py-1 btn-red rounded-pill font-xxs fw-small">
              Create Event
            </button>
          </Link>

          <button className="btn" onClick={() => setMenuOpen(!menuOpen)}>
            <Menu size={24} />
          </button>
        </div>
      </div>

      {/* Mobile Menu Dropdown */}
      {menuOpen && (
        <div className="position-absolute top-100 start-0 w-100 bg-white shadow p-3 d-md-none">
          <div className="mb-2">
            {/* <input
              type="search"
              className="form-control py-2 rounded-pill"
              placeholder="Search events or artists..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            /> */}
          </div>
          {/* <Link to="/createEvent" className="d-block main-red font-xs px-2  mb-2">Create Event</Link> */}
          <Link
            to={"/profile/" + id}
            className="d-block main-red font-xs px-2  mb-2"
            onClick={() => setMenuOpen(false)}
          >
            My Profile
          </Link>
          <Link
            to="/contact"
            className="d-block main-red font-xs px-2 mb-2"
            onClick={() => setMenuOpen(false)}
          >
            Help
          </Link>
          <Link
            to="/signup"
            className="d-block main-red font-xs px-2 "
            onClick={() => setMenuOpen(false)}
          >
            Sign In
          </Link>
        </div>
      )}
    </header>
  );
}
