
export default function CookiePolicyPage() {
  return (
      <div className="custom-container mt-4 px-4 py-12">
        <h2 className="font-weight-bold mt-4 mb-3">Cookie Policy</h2>
        <div className="">
          <p>Last updated: [28th January 2025]</p>
          <p className="py-2 text-gray">
            This Cookie Policy explains how witzzart ("we", "us", or "our") uses cookies and similar technologies 
            to recognize you when you visit our website. It explains what these technologies are and why we use them, 
            as well as your rights to control our use of them.
          </p>
          <h5 className="font-weight-bold">What are cookies?</h5>
          <p className="py-2 text-gray">
            Cookies are small data files that are placed on your computer or mobile device when you visit a website. 
            Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, 
            as well as to provide reporting information.
          </p>
          <h5 className="font-weight-bold">Why do we use cookies?</h5>
          <p className="py-2 text-gray">We use cookies for the following purposes:</p>
          <ul>
            <li className="py-2 text-gray">To enable certain functions of the website</li>
            <li className="py-2 text-gray">To provide analytics</li>
            <li className="py-2 text-gray">To store your preferences</li>
            <li className="py-2 text-gray">To enable advertisement delivery, including behavioral advertising</li>
          </ul>
          <h5 className="font-weight-bold">Types of cookies we use</h5>
          <p className="py-2 text-gray">We use both session and persistent cookies on our website. We use the following types of cookies:</p>
          <ul>
            <li className="py-2 text-gray">Essential cookies: These are cookies that are required for the operation of our website.</li>
            <li className="py-2 text-gray">Analytical/performance cookies: These allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it.</li>
            <li className="py-2 text-gray">Functionality cookies: These are used to recognize you when you return to our website.</li>
            <li className="py-2 text-gray">Targeting cookies: These cookies record your visit to our website, the pages you have visited and the links you have followed.</li>
          </ul>
          <h5 className="font-weight-bold">How to control cookies</h5>
          <p className="py-2 text-gray">
            You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. 
            If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.
          </p>
          <h5 className="font-weight-bold">Changes to this Cookie Policy</h5>
          <p className="py-2 text-gray">
            We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for 
            other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed 
            about our use of cookies and related technologies.
          </p>
          <h5 className="font-weight-bold">Contact Us</h5>
          <p className="py-2 text-gray">
            If you have any questions about our use of cookies or other technologies, please email us at privacy@witzzart.com.
          </p>
        </div>
      </div>
  )
}

