export default function TargetAudience({ formData, onChange }) {

  const handleCheckboxChange = (e, option) => {
    const { checked } = e.target;
    const updatedAudienceType = checked
      ? [...formData.audience_type, option]
      : formData.audience_type.filter((item) => item !== option);
    onChange({ target: { name: "audience_type", value: updatedAudienceType } });
  };

  return (
    <div className="container space-y-6">
      {/* Age Restriction */}
      <div className="mb-4">
        <label className="form-label">Age Restriction</label>
        <select
          name="age_restriction"
          className="form-select"
          value={formData.age_restriction}
          onChange={onChange}
        >
          <option value="All Ages">All Ages</option>
          <option value="13+">13+</option>
          <option value="18+">18+</option>
          <option value="21+">21+</option>
        </select>
      </div>

      {/* Audience Type */}
      <div className="mb-4">
        <label className="form-label">Audience Type</label>
        <div className="form-text">Select the types of audience your event is suitable for.</div>
        {["Family", "Adults", "Professionals", "Teens", "Couples"].map((option) => (
          <div key={option} className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={option}
              name="audience_type"
              checked={formData.audience_type.includes(option)}
              onChange={(e) => handleCheckboxChange(e, option)}
            />
            <label className="form-check-label" htmlFor={option}>
              {option}
            </label>
          </div>
        ))}
      </div>

      {/* Skill Level */}
      <div className="mb-4">
        <label className="form-label">Skill Level</label>
        <select
          name="skill_level"
          className="form-select"
          value={formData.skill_level}
          onChange={onChange}
        >
          <option value="Beginner">Beginner</option>
          <option value="Intermediate">Intermediate</option>
          <option value="Advanced">Advanced</option>
        </select>
        <div className="form-text">For workshops/classes, specify the required skill level.</div>
      </div>
    </div>
  );
}
