export default function PrivacyPolicy() {
  return (
    <div className="custom-container my-5  px-3">
      <h2 className=" font-weight-bold mb-4">Privacy Policy</h2>
      <div className="mb-4">
        <p>
          <strong>Last updated:</strong> [28th January 2025]
        </p>
        <p>
          Welcome to Witzzart! This Privacy Policy explains how we collect, use,
          and protect your personal information when you use our platform. By
          using Witzzart, you agree to the practices described in this policy.
        </p>

        <h5 className="mt-5 font-weight-semibold ">
          1. Information we collect
        </h5>
        <p>
          We collect information to provide and improve our services. This
          includes :
        </p>
        <h6 className="font-weight-bold">Information You Provide </h6>
        <ul className="py-3">
          <p className="text-gray font-sm">
            • <strong>Account Information :</strong> Name, email address, phone
            number, and password when you create an account.
          </p>
          <p className="text-gray font-sm">
            • <strong>Event Details : </strong> If you’re an organizer, we
            collect event descriptions, dates, and ticket information.
          </p>
          <p className="text-gray font-sm">
            • <strong>Payment Information:</strong> Billing details for ticket
            purchases or event listings (processed securely by third-party
            payment processors).
          </p>
          <p className="text-gray font-sm">
            • <strong>Communications : </strong> Messages or inquiries sent to
            our customer support team
          </p>
        </ul>
        <h6 className="font-weight-bold">
          Information We Collect Automatically
        </h6>
        <ul className="py-3">
          <p className="text-gray">
            • <strong>Usage Data: :</strong> : IP address, browser type, pages
            visited, and device information.
          </p>
          <p className="text-gray font-sm">
            • <strong>Location Data : </strong> General location (city/region)
            to show relevant events.
          </p>
          <p className="text-gray font-sm">
            • <strong>Cookies :</strong>: Small data files stored on your device
            to enhance your experience (e.g., remembering login details)
          </p>
        </ul>

        <h5 className="mt-5 font-weight-bold">
          2. How We Use Your Information
        </h5>

        <p className="text-gray font-sm">We use your information to:</p>
        <ul className="py-3">
          <p className="text-gray font-sm">
            • Provide, maintain, and improve our platform.
          </p>
          <p className="text-gray font-sm">
            • Process ticket purchases and event listings.
          </p>
          <p className="text-gray font-sm">
            • Communicate with you about your account, events, or updates.
          </p>
          <p className="text-gray font-sm">
            • Personalize your experience by showing relevant events and
            content.
          </p>
          <p className="text-gray font-sm">
            • Monitor platform usage and detect fraudulent or illegal
            activities.
          </p>
        </ul>

        <h5 className=" font-weight-bold">3. Sharing of Information</h5>
        <p className="text-gray font-sm">We may share your information with:</p>
        <ul className="py-3">
          <p className="text-gray font-sm">
            • <strong>With Event Organizers </strong>If you purchase tickets, we
            share your name and contact details with the organizer for event
            management.
          </p>
          <p className="text-gray font-sm">
            • <strong>With Service Providers </strong> : Third-party vendors
            (e.g., payment processors, email services) who help us operate the
            platform.
          </p>
          <p className="text-gray font-sm">
            • <strong>For Legal Reasons : </strong> If required by law or to
            protect our rights, safety, or property.
          </p>
          <p className="text-gray font-sm">
            • <strong>With Your Consent</strong>: If you choose to make your
            profile or event listings public.
          </p>
        </ul>

        <h5 className=" font-weight-bold">4. Data Retention</h5>
        <p className="text-gray font-sm">
          We retain your information only as long as necessary to:
        </p>

        <ul className="py-3">
          <p className="text-gray font-sm">• Provide our services.</p>
          <p className="text-gray font-sm">• Comply with legal obligations.</p>
          <p className="text-gray font-sm">
            • Resolve disputes or enforce our agreements.
          </p>
        </ul>

        <h5 className=" font-weight-bold">5. Your Rights</h5>
        <p className="text-gray font-sm">
          Depending on your location, you may have the right to:
        </p>
        <ul className="py-3">
          <p className="text-gray font-sm">
            • Access, correct, or delete your personal information.
          </p>
          <p className="text-gray font-sm">
            • Access, correct, or delete your personal information.
          </p>
          <p className="text-gray font-sm">
            • Receive a copy of your data in a portable format.
          </p>
        </ul>
        <p className="text-gray font-sm py-3">
          To exercise these rights, contact us at support@witzzart.com.
        </p>

        <h5 className=" font-weight-bold">6. Data Security</h5>
        <p className="text-gray font-sm py-3">
          We take reasonable measures to protect your information from
          unauthorized access, loss, or misuse. However, no online platform is
          100% secure, so we cannot guarantee absolute security.
        </p>

        <h5 className=" font-weight-bold">7. International Data Transfers</h5>
        <p className="text-gray font-sm py-3">
          Your information may be transferred to and processed in countries
          outside your residence. These countries may have different data
          protection laws. By using Witzzart, you consent to such transfers.
        </p>

        <h5 className=" font-weight-bold">8. Children's Privacy</h5>
        <p className="text-gray font-sm py-3">
          Witzzart is not intended for users under 13. We do not knowingly
          collect information from children. If we discover that we have, we
          will delete it promptly.
        </p>

        <h5 className=" font-weight-bold">9. Changes to This Policy</h5>
        <p className="text-gray font-sm py-3">
          We may update this Privacy Policy occasionally. If we make significant
          changes, we will notify you via email or through our platform. The
          “Last updated” date at the top will reflect the latest version.
        </p>

        <h5 className=" font-weight-bold">10. Contact Us</h5>
        <p className="text-gray font-sm py-3">
          If you have questions or concerns about this Privacy Policy, please
          contact us at: Email: support@witzzart.com
        </p>
      </div>
    </div>
  );
}
