import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const PricingCalculator = () => {
  const [ticketPrice, setTicketPrice] = useState(100);
  const [organizerAbsorbsFees, setOrganizerAbsorbsFees] = useState(false);

  // Platform Fee (5%)
  const platformFee = ticketPrice * 0.05;
  const gstOnPlatformFee = platformFee * 0.18;

  // Payment Fee based on slab
  let paymentFeeRate = 0.02;
  if (ticketPrice > 500 && ticketPrice <= 2000) paymentFeeRate = 0.03;
  if (ticketPrice > 2000) paymentFeeRate = 0.05;
  const paymentFee = ticketPrice * paymentFeeRate;
  const gstOnPaymentFee = paymentFee * 0.18;

  // TDS (1%)
  const tds = ticketPrice * 0.01;

  const totalCustomerPrice = organizerAbsorbsFees ?  ticketPrice + platformFee + gstOnPlatformFee + paymentFee + gstOnPaymentFee +tds :
    ticketPrice + platformFee + gstOnPlatformFee  ;

  const organizerPayout = organizerAbsorbsFees
    ? ticketPrice - platformFee - gstOnPlatformFee - paymentFee - gstOnPaymentFee - tds
    : ticketPrice -  paymentFee - gstOnPaymentFee -tds ;

  return (
    <div className="container d-flex align-items-center justify-content-center mt-5">
      <div className="card w-100" style={{ maxWidth: '500px' }}>
        <div className="bg-white d-flex align-items-center justify-content-center py-2 mt-3">
          <h5 className="fw-bold">Witzzart Pricing Calculator</h5>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <label className="form-label font-xs">Ticket Price (₹{ticketPrice})</label>
            <input
              type="range"
              className="form-range font-xs"
              min="0"
              max="10000"
              value={ticketPrice}
              onChange={(e) => setTicketPrice(Number(e.target.value))}
            />
          </div>

          <div className="form-check form-switch mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              checked={organizerAbsorbsFees}
              onChange={() => setOrganizerAbsorbsFees(!organizerAbsorbsFees)}
            />
            <label className="form-check-label font-xs">Organizer Absorbs Fees</label>
          </div>

          <div className="row py-2">
            <div className="col-6 font-xxs">Platform Fee (5%):</div>
            <div className="col-6 font-xxs text-end">₹{platformFee.toFixed(2)}</div>

            <div className="col-6 font-xxs">GST on Platform Fee (18%):</div>
            <div className="col-6 font-xxs text-end">₹{gstOnPlatformFee.toFixed(2)}</div>
          </div>

          <div className="row py-2">
            <div className="col-6 font-xxs">Payment Fee ({(paymentFeeRate * 100).toFixed(0)}%):</div>
            <div className="col-6 font-xxs text-end">₹{paymentFee.toFixed(2)}</div>

            <div className="col-6 font-xxs">GST on Payment Fee (18%):</div>
            <div className="col-6 font-xxs text-end">₹{gstOnPaymentFee.toFixed(2)}</div>
          </div>

          <div className="row py-2">
            <div className="col-6 font-xxs">TDS (1%):</div>
            <div className="col-6 font-xxs text-end">₹{tds.toFixed(2)}</div>
          </div>

          <hr />

          <div className="row fw-bold">
            <div className="col-6 fw-bold font-xs">Total Price for Customer:</div>
            <div className="col-6 fw-bold font-xs text-end">₹{totalCustomerPrice.toFixed(2)}</div>
          </div>
          <div className="row fw-bold">
            <div className="col-6 fw-bold font-xs">Organizer Payout:</div>
            <div className="col-6 font-xs fw-bold text-success text-end">₹{organizerPayout.toFixed(2)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCalculator;