import React, { useState } from "react";
import axios from "../../../src/axiosInstance";
import logo from "../../assets/images/logo-text.svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SignupPage = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [receiveNewsletter, setReceiveNewsletter] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData object
    const formData = new FormData();

    // Append form fields to the FormData object
    formData.append("name", fullName);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("mobile", "23456789");
    formData.append("address", "w4rtyujhgfds, sdfgh");
    formData.append("gender", "Male");
    formData.append("DOB", dateOfBirth);
    formData.append("profileImage", ""); // If you have a file, you should pass it here

    // Send the FormData object using axios

    try {
      // Your login logic
      const token =  await axios.post("/userRegister", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      localStorage.setItem('userId', token.data.data.userId)
      navigate("/");
      toast.success("Sign up successful!");
    } catch (error) {
      toast.error("Sign up failed! Please try again.");
    }



  };

  const handleSocialLogin = async () => {
    try {      
      window.open(
        `${process.env.REACT_APP_API_PATH}auth/google`, "_self"
      );
    } catch (error) {
      console.error("Social login error:", error);
    }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row h-100">
        <div className="col-md-6 d-flex flex-column justify-content-center p-5">
          <div className="fixed-top p-2 d-flex align-items-center">
            <Link to="/" className=" text-decoration-none">
              <img
                src={logo}
                alt="SVG 2"
                className="img-fluid"
                style={{ width: "100px", height: "auto" }}
              />
            </Link>
          </div>
          <h2 className="mb-2">Join Witzzart</h2>
          <p className="text-muted mb-4">
            Create your account to start your artistic journey
          </p>
          <form onSubmit={handleSubmit} className="signup-form">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="fullName" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  placeholder="John Doe"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="your-email@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Choose a username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="dateOfBirth" className="form-label">
                  Date of Birth
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="dateOfBirth"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Create a password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="acceptTerms"
                  checked={acceptTerms}
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="acceptTerms">
                  I accept the terms of service and privacy policy
                </label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="receiveNewsletter"
                  checked={receiveNewsletter}
                  onChange={(e) => setReceiveNewsletter(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="receiveNewsletter">
                  I want to receive newsletters and updates
                </label>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100 mb-3"
              style={{
                backgroundColor: "#ff3131",
                borderColor: "#ff3131",
                borderRadius: "20px",
              }}
            >
              Sign Up
            </button>
          </form>
          <div className="text-center my-3">Or</div>
          <button className="btn btn-outline-dark w-100 d-flex align-items-center justify-content-center" onClick={handleSocialLogin}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-google me-2"
              viewBox="0 0 16 16"
            >
              <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
            </svg>
            Sign Up with Google
          </button>
          <p className="text-center mt-3">
            Already have an account?{" "}
            <a href="/login" className="text-primary">
              Log in
            </a>
          </p>
        </div>
        <div className="col-md-6 d-none d-md-block p-0">
          <img
            src="././assets/images/Loginphoto.jpg"
            alt="Witzzart signup screen"
            className="w-100"
            style={{ height: "100vh", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
