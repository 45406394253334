import { CheckCircle } from 'lucide-react'
import PricingCalculator from './Shared/PricingCalculator'
export default function PricingPage() {
  const additionalServices = [
    {
      name: "Gate Entry Management",
      description: "Streamline your event entry process with our digital gate entry system.",
      features: [
        "QR code ticket scanning",
        "Real-time attendance tracking",
        "Multiple entry point support",
        "Offline mode for poor connectivity areas"
      ]
    },
    {
      name: "Marketing Assistance",
      description: "Boost your event's visibility with our marketing support services.",
      features: [
        "Social media promotion",
        "Email marketing campaigns",
        "Targeted advertising",
        "Performance analytics and reporting"
      ]
    },
    {
      name: "Custom Branding",
      description: "Create a unique branded experience for your attendees.",
      features: [
        "Custom-branded ticket designs",
        "Personalized event microsites",
        "Branded mobile check-in app",
        "Custom email templates"
      ]
    }
  ]

  return (
      <div className="custom-container mt-4">
        <h2 className="fw-bold text-center mb-4">Witzzart Slab-Based Pricing Model</h2>
        <p className="text-center mb-5">
          Understand our flexible pricing structure designed to cater to various event types and ticket prices.
        </p>
        <div className="">
          <PricingCalculator/>
        </div>
        <div className="mt-5">
          <h2 className="text-center text-black fw-semibold mb-4">Fee Structure</h2>
          <table className="table ">
            <thead>
              <tr>
                <th className='text-capitalize  text-gray fw-semibold'>Ticket Type</th>
                <th className='text-capitalize  text-gray fw-semibold'>Organizer Fee (%)</th>
                <th className='text-capitalize  text-gray fw-semibold'>Platform Fee (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Slab 1 <span className='text-gray font-xxs ml-2'>₹(0-500)</span></td>
                <td>2%</td>
                <td>5%</td>
              </tr>
              <tr>
                <td>Slab 2 <span className='text-gray font-xxs ml-2'>₹(501-2000)</span></td>
                <td>3%</td>
                <td>5%</td>
              </tr>
              <tr>
                <td>Slab 3 <span className='text-gray font-xxs ml-2'>₹(2000 and above)</span></td>
                <td>5%</td>
                <td>5%</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-4 ">
          <h4 className="fw-bold text-black mb-3">Additional Fees</h4>
          <div className="row">
            <div className="col-3"></div>
            <ul className="col-9">
            <li>Payment Gateway Fee: 2.6% + ₹3 per transaction</li>
            <li>GST: 18% on all fees</li>
            <li>TDS: 1% on the organizer's payout</li>
          </ul>
          </div>

        </div>


        <div className="mt-4 ">
          <h4 className="fw-bold text-black mb-3">Rationale</h4>
          <div className="row">
            <div className="col-3"></div>
            <ul className="col-9">
            <li>Tiered Fees: The model uses tiered fees based on ticket price, reflecting the varying costs associated with processing different ticket types.</li>
            <li>Differentiated Pricing: Premium tickets have lower fees to incentivize organizers to offer higher-priced options.</li>
            <li>Maximum Caps: Maximum fees per ticket help protect organizers from excessive costs on high-priced tickets.</li>
            <li>Transparency: Clearly displaying fees for each ticket type enhances transparency and allows customers to make informed decisions.</li>
          </ul>
          </div>

        </div>




        <div className="mt-4 ">
          <h4 className="fw-bold text-black mb-3">Benefits</h4>
          <div className="row">
            <div className="col-3"></div>
            <ul className="col-9">
            <li>Profitability: The tiered structure ensures profitability for Witzzart across different ticket price ranges.</li>
            <li>Fairness: Fees are adjusted based on ticket value, making the model fairer for organizers of high-priced events.</li>
            <li>Flexibility: Organizers can choose the ticket type that best suits their event and pricing strategy.</li>
          </ul>
          </div>

        </div>


        <div className="mt-5">
          <h3 className="fw-bold text-black text-center mb-4">Additional Services</h3>
          <div className="row row-cols-1 row-cols-md-3 g-4">
            {additionalServices.map((service, index) => (
              <div className="col" key={index}>
                <div className="card border-gray">
                  <div className="card-header">
                    <h4 className="fw-bold  text-capitalize">{service.name}</h4>
                  </div>
                  <div className="card-body">
                    <p className="card-text text-muted mb-4">{service.description}</p>
                    <ul>
                      {service.features.map((feature, featureIndex) => (
                        <li key={featureIndex} className="d-flex align-items-start mb-2">
                          <CheckCircle className="text-success me-2" size={20} color={"green"} />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-5 text-center">
          <h2 className="mb-3">Contact Us</h2>
          <p className="mb-4">
            Have questions about our pricing or need more information?
          </p>
          <p>
            Reach out to us at{' '}
            <a href="mailto:support@witzzart.com" className="main-red">
              support@witzzart.com
            </a>
          </p>
        </div>
      </div>

  )
}
