import React, { useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
const Card = ({ eventDetails }) => {
  const [isHovered, setIsHovered] = useState(false);

  const cardStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '28px',
    maxWidth: '340px',
    borderRadius: '30px',
    background: isHovered ? '#ff3131' : '#fff',
    boxShadow: '0px 0px 14px 0px rgba(192, 192, 192, 0.2)',
    transition: 'all 1s ease',
    overflow: 'hidden',
  };

  const afterPseudoStyle = {
    content: '""',
    position: 'absolute',
    bottom: '-30%',
    right: '-30%',
    width: isHovered ? '1000px' : '120px',
    height: isHovered ? '1000px' : '120px',
    background: '#ff3131',
    filter: 'blur(70px)',
    borderRadius: '50%',
    transition: 'width 1s, height 1s',
    opacity: isHovered ? 1 : 0.2,
  };

  const beforePseudoStyle = {
    content: '""',
    position: 'absolute',
    bottom: isHovered ? '-230%' : '-160%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isHovered ? '1000px' : '0',
    height: isHovered ? '1000px' : '0',
    background: '#ff3131',
    filter: 'blur(70px)',
    borderRadius: '50%',
    transition: 'all 1s ease',
  };

  const containerStyle = {
    position: 'relative',
    zIndex: 1,
  };

  const iconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '40%',
    borderRadius: '6px',
    transition: '1s all',
  };

  const titleStyle = {
    color: isHovered ? '#ffffff' : '#171d29',
    fontSize: '22px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    transition: '1s all',
    margin: '1rem 0',
  };

  const subtitleStyle = {
    color: isHovered ? '#ffffff' : '#171d29',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    transition: '1s all',
    margin: '1rem 0',
  };

  const linkMoreStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0.8rem',
    color: isHovered ? '#ffffff' : '#072713',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '22px',
    textDecoration: 'none',
    transition: '1s all',
    marginTop: '1rem',
  };

  return (
    <Link 
      to="/eventdetails" 
      className="card border-0 position-relative m-3" 
      style={cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="pseudo-elements">
        <div style={afterPseudoStyle}></div>
        <div style={beforePseudoStyle}></div>
      </div>
      <div className="container" style={containerStyle}>
        <div style={iconStyle}>
          <img src={eventDetails.image} alt={eventDetails.title} className="w-100 rounded-lg object-cover" height={100} />
        </div>
        <div style={titleStyle}>
          {eventDetails.title}
        </div>
        <div style={subtitleStyle} class="text-gray">
          {eventDetails.description}
        </div>
        <div style={subtitleStyle} className="d-flex align-items-center justify-content-between my-2">
            <div className="d-flex align-items-center text-gray ">
                <LocationOnIcon fontSize={"10"}/>
                {eventDetails.location}
            </div>
            <div className="d-flex align-items-center text-gray">
                <AccessTimeIcon fontSize={"10"}/>
                {eventDetails.time}
            </div>
        </div>
        <div style={linkMoreStyle} className="text-gray">
         Rs.{eventDetails.price}
         <button style={{ backgroundColor: "#ff5757", color: "#fff", border: "none", borderRadius: "12px", padding: "0.1rem 0.5rem" }}>
  View More
</button>

          
        </div>
      </div>
   
    </Link>
  );
};

export default Card;