import { Award, Users, Globe, TrendingUp } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import AboutUsmaincarousal from "../../assets/images/AboutUsmaincarousal.svg"
export default function About() {
  const navigate = useNavigate();
  const stats = [
    {
      icon: Users,
      value: "1M+",
      label: "Active Users",
      description: "Join our growing community of art enthusiasts",
    },
    {
      icon: Globe,
      value: "50+",
      label: "Cities",
      description: "Connecting artists and audiences worldwide",
    },
    {
      icon: Award,
      value: "10K+",
      label: "Events",
      description: "Successful events hosted on our platform",
    },
    {
      icon: TrendingUp,
      value: "95%",
      label: "Satisfaction",
      description: "From both organizers and attendees",
    },
  ];

  const team = [
    {
      name: "Sarah Chen",
      role: "CEO & Co-founder",
      image: "/placeholder.svg?height=400&width=400",
      bio: "Former gallery curator with a passion for making art accessible to everyone.",
    },
    {
      name: "Michael Rodriguez",
      role: "CTO & Co-founder",
      image: "/placeholder.svg?height=400&width=400",
      bio: "Tech veteran with experience in building scalable platforms.",
    },
    {
      name: "Emily Thompson",
      role: "Head of Community",
      image: "/placeholder.svg?height=400&width=400",
      bio: "Community builder with a background in event management.",
    },
    {
      name: "David Kim",
      role: "Head of Operations",
      image: "/placeholder.svg?height=400&width=400",
      bio: "Operations expert focused on creating seamless experiences.",
    },
  ];

  return (
    <div className="all-width">
      {/* Hero Section */}
      <section className="position-relative overflow-hidden" style={{ height: "500px" }}>
        <img
          src={AboutUsmaincarousal}

          className="object-fit-cover w-100 h-100"
          style={{ position: "absolute", top: 0, left: 0 }}
        />
        <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center">
          <div className="container text-white text-left">
            <h1 className="display-4 fw-bold mb-3">Connecting Art Communities Worldwide</h1>
            <p className="lead">
              witzzart is revolutionizing how people discover, create, and participate in art events. Our platform
              brings together artists, enthusiasts, and organizers in a vibrant community.
            </p>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-5 bg-light">
        <div className="container  text-center">
          <h2 className="display-6 fw-bold mb-4">Our Mission</h2>
          <p className="mb-5 text-muted">
            We believe that art has the power to transform lives and communities. Our mission is to make art more
            accessible by providing a platform where creative experiences can be easily discovered and shared.
          </p>
          <div className="row g-4">
            <div className="col-md-6">
              <div className="card h-100">
                <div className="card-body">
                  <h3 className="h5 card-title font-weight-bold">For Art Enthusiasts</h3>
                  <p className="card-text text-muted">
                    Discover unique art events, workshops, and exhibitions in your city. Connect with artists and
                    fellow art lovers in a vibrant community.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card h-100">
                <div className="card-body">
                  <h3 className="h5 card-title font-weight-bold">For Event Organizers</h3>
                  <p className="card-text text-muted">
                    Reach a wider audience and manage your events efficiently. Our platform provides the tools you
                    need to create successful art experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      {/* <section className="py-5 d-flex align-items-center justify-content-center">
        <div className="all-width  mx-1 px-1">
          <div className=" px-4 row g-4 d-flex  justify-content-around">
            {stats.map((stat, index) => {
              const Icon = stat.icon;
              return (
                <div className="col-lg-3  col-md-8" key={index}>
                  <div className="card h-100 text-center">
                    <div className="card-body">
                      <Icon className="text-danger mb-3" size={32} />
                      <h3 className="h4 fw-bold mb-2">{stat.value}</h3>
                      <p className="fw-semibold mb-1">{stat.label}</p>
                      <p className="text-muted small">{stat.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section> */}

      {/* Team Section */}
      <section className="py-5 bg-light d-flex   align-items-center justify-content-center">
        <div className=" p-0 m-0 d-flex flex-column  align-items-center justify-content-center all-width">
          {/* <h2 className="display-6 fw-bold mb-5">Meet Our Team</h2> */}
          {/* <div className="row all-width g-4 px-4 d-flex  justify-content-around">
            {team.map((member, index) => (
              <div className="col-lg-3 col-md-8" key={index}>
                <div className="card h-100">
                  <div className="card-body text-center">
                    <div className="rounded-circle overflow-hidden mb-3" style={{ width: "120px", height: "120px" }}>
                      <img
                        src={member.image}
                        alt={member.name}
                        width={120}
                        height={120}
                        className="w-100 h-100 object-fit-cover"
                      />
                    </div>
                    <h3 className="h5 fw-bold mb-1">{member.name}</h3>
                    <p className="text-danger fw-semibold mb-2">{member.role}</p>
                    <p className="text-muted small">{member.bio}</p>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-5 text-center">
        <div className="container">
          <h2 className="display-6 fw-bold mb-4">Join Our Community</h2>
          <p className="text-muted mb-5">
            Whether you're an artist, event organizer, or art enthusiast, there's a place for you in our community.
          </p>
          <div className="d-flex justify-content-center gap-3">
            <button className="btn btn-red btn-lg rounded-pill" onClick={()=>{ navigate('/signup')}}>Create an Account</button>
            <button className="btn btn-outline-secondary btn-lg rounded-pill" onClick={()=>{ navigate('/contact')}}>Learn More</button>
          </div>
        </div>
      </section>
    </div>
  );
}
