import React, { useEffect, useMemo, useState } from "react";
import { Pagination, Search } from "../DataTable";

const DataTable2 = ({ lists, headers, searchItems }) => {
  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const filterListLengths = [10, 25, 50, 100];

  useEffect(() => {
    setComments(lists);
  }, [lists]);

  const listData = useMemo(() => {
    let computedData = comments;

    if (search && searchItems.length > 0) {
      computedData = computedData.filter((filteData) =>
        searchItems.some((field) =>
          filteData[field]?.toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    setTotalItems(computedData.length);

    // Sorting
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // Pagination
    return computedData.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center mb-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="ms-2 mb-0">Show</label>
            <select
              className="form-select w-auto"
              style={{ minWidth: "70px", paddingRight: "2rem" }}  
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              value={itemsPerPage}
            >
              {filterListLengths.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            <span className="ms-2">entries</span>
          </div>

          <div className="col-md-6 d-flex justify-content-end">
            <Search
              onSearch={(value) => {
                setSearch(value);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            {listData.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-bordered table-hover text-nowrap w-100">
                  <thead className="table-light">
                    <tr>
                      <th>Sr No.</th>
                      {headers.map((header) => (
                        <th key={header.field}>{header.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {listData.map((comment, idx) => (
                      <tr key={idx}>
                        <td>{(currentPage - 1) * itemsPerPage + idx + 1}</td>
                        {headers.map((col, colIdx) => (
                          <td key={`${idx}-${colIdx}`}>{comment[col.field]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="alert alert-warning text-center">No data found.</div>
            )}
          </div>
        </div>

        <div className="row mt-3">
          <div className="col d-flex justify-content-center">
            <Pagination
              total={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTable2;
