import React from "react";
import {
  Calendar,
  Clock,
  MapPin,
  ChevronUp,
  User,
  ChevronDown,
} from "lucide-react"; // If you're still using lucide-react icons
import { useState } from "react";

function WorkshopCard({
  title,
  image,
  date,
  time,
  location,
  price,
  href,
  tags = "",
  type,
  tickets,
  showdetails,
}) {
  const [isOpen, setIsOpen] = useState(false);

  function formatDate(isoDate) {
    if (isoDate) {
      const date = new Date(isoDate);
      const options = { year: "numeric", month: "short", day: "numeric" };
      return date?.toLocaleDateString("en-US", options);
    }
  }

  function formatTime(timeString) {
    if (timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
      return `${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;
    }
  }
  return (
    <div className="card group borer-light rounded-3 position-relative main-card cursor-pointer overflow-hidden transition-all duration-100 transform hover:-translate-y-1">
      <div className="position-relative ">
        <div
          className="position-absolute mt-2 ml-2  top-3 left-3 z-10 d-flex flex-wrap gap-2"
          style={{ zIndex: 1 }}
        >
          <span
            className="badge bg-danger rounded-pill  text-white animate__animated animate__fadeIn"
            style={{ zIndex: 1 }}
          >
            {type}
          </span>
          {/* {tags.map((tag) => ( */}
          <span className="badge rounded-pill bg-lightgray  text-dark">
            {tags}
          </span>
          {/* ))} */}
        </div>
        <div className="position-relative border-b overflow-hidden">
          <div
            className="gradient-overlay"
            style={{
              objectFit: "cover",
              height: "25vh",
              transition: "transform 0.5s",
            }}
          >
            <img
              src={image}
              className="rounded-small"
              style={{ objectFit: "cover" }}
            />
          </div>

          <div className="position-absolute  top-0 start-0 w-100 h-100 bg-black-50 group-hover:bg-black-70 transition-all" />
        </div>
      </div>
      <div className="card-body border-top">
        <h5 className="card-title text-red fw-semibold mb-2">{title}</h5>
        <div className="d-flex flex-column mb-2">
          <div className="d-flex align-items-center justify-content-between all-width">
            <div className="d-flex align-items-center text-muted">
              <Calendar className="w-4 h-4 my-1 mr-2" size={15} />
              <span className="font-xs">{formatDate(date)}</span>
            </div>
            <div className="d-flex align-items-center text-muted">
              <Clock className="w-4 h-4 mr-1" size={15} />
              <span className="font-xs">{formatTime(time)}</span>
            </div>
          </div>

          <div className="d-flex align-items-center text-muted">
            <MapPin className="w-4 h-4 my-1 mr-2" size={15} />
            <span className="font-xs">{location}</span>
          </div>
        </div>

{showdetails && (
  <div className="pt-2 border-top">
          {/* Clickable Header */}
          <div
            className="d-flex align-items-center mb-2 justify-content-between all-width"
            onClick={() => setIsOpen(!isOpen)}
            style={{ cursor: "pointer" }} // Makes it clear it's clickable
          >
            <span className="text-black fw-bold font-xs">View Details</span>
            {isOpen ? <ChevronUp size={17} /> : <ChevronDown size={17} />}
          </div>

          {/* Toggle Content */}
          {isOpen && (
            <div className="animate__animated animate__fadeIn">
              <table class="table m-0  ">
                <thead>
                  <tr>
                    <th className="font-xxs px-1 text-capitalize" scope="col">Ticket Name</th>
                    <th className="font-xxs text-center px-1 text-capitalize" scope="col">Booked Seats</th>
                    <th className="font-xxs text-center px-1 text-capitalize" scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                {tickets.map((item, index) => (
                <tr key={index}>
                  <td className="font-xxs px-1 text-capitalize text-left ">{item.ticket_name}</td>
                  <td className="font-xxs px-1 text-capitalize text-center ">{item?.ticketsold?.total_seats || 0}</td>
                  <td className="font-xxs px-1 text-capitalize text-center ">₹{item?.ticketsold?.total_price || 0}</td>
                </tr>
              ))}
                </tbody>
              </table>


            </div>
          )}
        </div>
)}
      </div>
    </div>
  );
}

export default WorkshopCard;
