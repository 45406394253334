import React, { useState, useEffect } from "react";
import CareerpageCarousal from "../../assets/images/CareerpageCarousal.svg";
const Careers = () => {
  const [activeTab, setActiveTab] = useState("engineering");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    console.log(`Active tab changed to: ${activeTab}`);
  }, [activeTab]);

  return (
    <div className="all-width">
      {/* { Top Div} */}

      <section
        className="position-relative overflow-hidden"
        style={{ height: "70vh" }}
      >
        <img
          src={CareerpageCarousal}
          className="object-fit-cover w-100 h-100"
          style={{ position: "absolute", top: 0, left: 0 }}
        />
        <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center">
          <div className="container text-white text-left">
            <h1 className="display-4 fw-bold mb-3">Join Our Team</h1>
            <p className="lead">
            Help us revolutionize how people discover and experience art
              events. We're looking for passionate individuals to join our
              growing team.
            </p>
          </div>
        </div>
      </section>

      {/* Culture section */}
      <section className="py-16 m-5">
        <div className=" my-10 px-4">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h2 className="text-3xl font-bold mb-10 font-weight-bold">
              Our Culture
            </h2>
            <p className="text-lg text-gray-600 mb-5">
              We're building a diverse and inclusive team of passionate
              individuals who believe in the power of art to bring people
              together. Our culture is built on transparency, collaboration, and
              continuous learning.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 p-2">
              <div
                className="rounded-lg border bg-card text-card-foreground shadow-sm h-100 p-2  bg-white"
                data-v0-t="card"
              >
                <div className="p-6 text-center">
                  <h5 className="text-xl font-bold mb-4 font-weight-bold">
                    Innovation
                  </h5>
                  <p className="text-gray-600 font-xs">
                    We encourage creative thinking and aren't afraid to try new
                    approaches to solve problems.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div
                className="rounded-lg border bg-card text-card-foreground shadow-sm h-100 p-2  bg-white"
                data-v0-t="card"
              >
                <div className="p-6 text-center">
                  <h5 className="text-xl font-bold mb-4 font-weight-bold">
                    Impact
                  </h5>
                  <p className="text-gray-600 font-xs">
                    Your work will directly affect how millions of people
                    experience art and culture.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div
                className="rounded-lg border bg-card text-card-foreground shadow-sm h-100 p-2  bg-white"
                data-v0-t="card"
              >
                <div className="p-6 text-center">
                  <h5 className="text-xl font-bold mb-4 font-weight-bold">
                    Growth
                  </h5>
                  <p className="text-gray-600 font-xs">
                    We invest in your professional development and support your
                    career growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits section */}
      <section className="p-5  bg-gray">
        <div className=" mt-4  mx-auto px-4">
          <h3 className="text-black font-bold text-center mb-12 font-weight-bold">
            Benefits & Perks
          </h3>
          <div className="row mt-2 ">
            <div className="col-md-4 p-2">
              <div
                className="rounded-3  border bg-white text-card-foreground shadow-sm h-100 p-4"
                data-v0-t="card"
              >
                <div className="p-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    color="red"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-earth w-8 h-8 text-red-500 mb-2"
                  >
                    <path d="M21.54 15H17a2 2 0 0 0-2 2v4.54"></path>
                    <path d="M7 3.34V5a3 3 0 0 0 3 3v0a2 2 0 0 1 2 2v0c0 1.1.9 2 2 2v0a2 2 0 0 0 2-2v0c0-1.1.9-2 2-2h3.17"></path>
                    <path d="M11 21.95V18a2 2 0 0 0-2-2v0a2 2 0 0 1-2-2v-1a2 2 0 0 0-2-2H2.05"></path>
                    <circle cx="12" cy="12" r="10"></circle>
                  </svg>
                  <h5 className="text-xl font-bold mb-2 font-weight-bold">
                    Remote-First
                  </h5>
                  <p className="text-gray-400">
                    Work from anywhere in the world
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div
                className="rounded-3  border bg-card text-card-foreground shadow-sm h-100 p-4  bg-white"
                data-v0-t="card"
              >
                <div className="p-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    color="red"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-heart w-8 h-8 text-red-500 mb-2"
                  >
                    <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z"></path>
                  </svg>
                  <h5 className="text-xl font-bold mb-2 font-weight-bold">
                    Health Benefits
                  </h5>
                  <p className="text-gray-400">
                    Comprehensive health, dental, and vision coverage
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div
                className="rounded-3  border bg-card text-card-foreground shadow-sm h-100 p-4  bg-white"
                data-v0-t="card"
              >
                <div className="p-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    color="red"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-heart w-8 h-8 text-red-500 mb-2"
                  >
                    <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z"></path>
                  </svg>
                  <h5 className="text-xl font-bold mb-2 font-weight-bold">
                    Learning Budget
                  </h5>
                  <p className="text-gray-400">
                    Access to professional development resources and training
                    program
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div
                className="rounded-3  border bg-card text-card-foreground shadow-sm h-100 p-4  bg-white"
                data-v0-t="card"
              >
                <div className="p-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    color="red"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-laptop w-8 h-8 text-red-500 mb-2"
                  >
                    <path d="M20 16V7a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v9m16 0H4m16 0 1.28 2.55a1 1 0 0 1-.9 1.45H3.62a1 1 0 0 1-.9-1.45L4 16"></path>
                  </svg>
                  <h5 className="text-xl font-bold mb-2 font-weight-bold">
                    Team Events
                  </h5>
                  <p className="text-gray-400">
                    Regular team meetups and social events
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div
                className="rounded-3  border bg-card text-card-foreground shadow-sm h-100 p-4  bg-white"
                data-v0-t="card"
              >
                <div className="p-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    color="red"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-leaf w-8 h-8 text-red-500 mb-2"
                  >
                    <path d="M11 20A7 7 0 0 1 9.8 6.1C15.5 5 17 4.48 19 2c1 2 2 4.18 2 8 0 5.5-4.78 10-10 10Z"></path>
                    <path d="M2 21c0-3 1.85-5.36 5.08-6C9.5 14.52 12 13 13 12"></path>
                  </svg>
                  <h5 className="text-xl font-bold mb-2 font-weight-bold">
                    Paid Time Off
                  </h5>
                  <p className="text-gray-400">
                    Unlimited vacation policy and paid holidays
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div
                className="rounded-3  border bg-card text-card-foreground shadow-sm h-100 p-4  bg-white"
                data-v0-t="card"
              >
                <div className="p-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    color="red"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-building2 w-8 h-8 text-red-500 mb-2"
                  >
                    <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z"></path>
                    <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"></path>
                    <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2"></path>
                    <path d="M10 6h4"></path>
                    <path d="M10 10h4"></path>
                    <path d="M10 14h4"></path>
                    <path d="M10 18h4"></path>
                  </svg>
                  <h5 className="text-xl font-bold mb-2 font-weight-bold">
                    Co-working Budget
                  </h5>
                  <p className="text-gray-400">
                    Support for creating a productive home office setup
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* position section */}
      <section className="py-16">
        <div className=" custom-container mx-auto px-4">
          <h3 className=" font-bold font-weight-bold text-center  my-5">
            Open Positions
          </h3>
          <div dir="ltr" data-orientation="horizontal" className="w-full">
            <div
              role="tablist"
              aria-orientation="horizontal"
              className="inline-flex h-10 items-center text-muted-foreground w-full justify-start mb-8 bg-white rounded-full p-1 space-x-2"
              tabIndex="0"
              data-orientation="horizontal"
            >
              {["engineering", "product", "marketing"].map((tab) => (
                <button
                  key={tab}
                  type="button"
                  role="tab"
                  aria-selected={activeTab === tab}
                  aria-controls={`radix-content-${tab}`}
                  data-state={activeTab === tab ? "active" : "inactive"}
                  id={`radix-trigger-${tab}`}
                  className={`inline-flex items-center justify-center px-3 py-1.5 text-sm font-medium ${
                    activeTab === tab
                      ? "bg-white text-black shadow-sm"
                      : "bg-white text-gray shadow-sm"
                  } rounded-full`}
                  tabIndex="-1"
                  data-orientation="horizontal"
                  onClick={() => handleTabClick(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            {/* Tab Content */}
            <div className="mt-2">
              {activeTab === "engineering" && (
                <div>
                  <div className="grid gap-6">
                    <div
                      className="rounded-lg border bg-card text-card-foreground shadow-sm p-10 mb-4"
                      data-v0-t="card"
                    >
                      <div>
                        <div className="p-2">
                          <div className="upperRow">
                            <h5 className="font-semibold tracking-tight text-xl mb-2">
                              Senior Frontend Engineer
                            </h5>
                            <button className="btn btn-red rounded-pill fw-small text-white">
                              Apply Now
                            </button>
                          </div>
                          <div className="remoteRow font-xxs">
                            <div className="innerRow font-xxs">Remote</div>
                            <div className="innerRow  font-xxs">Full-time</div>
                            <div className="innerRow font-xxs">Engineering</div>
                          </div>
                        </div>
                      </div>
                      <div className="p-6 pt-0">
                        <p className="text-muted font-xxs mt-2 px-2">
                          Join our frontend team to build beautiful and
                          performant user interfaces.
                        </p>
                      </div>
                    </div>
                    <div
                      className="rounded-lg border bg-card text-card-foreground shadow-sm p-10 mb-4"
                      data-v0-t="card"
                    >
                      <div>
                        <div className="p-2">
                          <div className="upperRow">
                            <h5 className="font-semibold tracking-tight text-xl mb-2">
                              Backend Engineer
                            </h5>
                            <button className="btn btn-red rounded-pill fw-small text-white">
                              Apply Now
                            </button>
                          </div>
                          <div className="remoteRow font-xxs">
                            <div className="innerRow font-xxs">Remote</div>
                            <div className="innerRow  font-xxs">Full-time</div>
                            <div className="innerRow font-xxs">Engineering</div>
                          </div>
                        </div>
                      </div>
                      <div className="p-6 pt-0">
                        <p className="text-muted font-xxs mt-2 px-2">
                          Help scale our infrastructure and build robust backend
                          systems.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "product" && (
                <div className="grid gap-6">
                  <div
                    className="rounded-lg border bg-card text-card-foreground shadow-sm p-10 mb-4"
                    data-v0-t="card"
                  >
                    <div>
                      <div className="p-2">
                        <div className="upperRow">
                          <h5 className="font-semibold tracking-tight text-xl mb-2">
                            Product Manager
                          </h5>
                          <button className="btn btn-red rounded-pill fw-small text-white">
                            Apply Now
                          </button>
                        </div>
                        <div className="remoteRow font-xxs">
                          <div className="innerRow font-xxs">Remote</div>
                          <div className="innerRow  font-xxs">Full-time</div>
                          <div className="innerRow font-xxs">Product</div>
                        </div>
                      </div>
                    </div>
                    <div className="p-6 pt-0">
                      <p className="text-muted font-xxs mt-2 px-2">
                        Lead product strategy and work closely with engineering
                        and design teams.
                      </p>
                    </div>
                  </div>
                  <div
                    className="rounded-lg border bg-card text-card-foreground shadow-sm p-10 mb-4"
                    data-v0-t="card"
                  >
                    <div>
                      <div className="p-2">
                        <div className="upperRow">
                          <h5 className="font-semibold tracking-tight text-xl mb-2">
                            Product Designer
                          </h5>
                          <button className="btn btn-red rounded-pill fw-small text-white">
                            Apply Now
                          </button>
                        </div>
                        <div className="remoteRow font-xxs">
                          <div className="innerRow font-xxs">Remote</div>
                          <div className="innerRow  font-xxs">Full-time</div>
                          <div className="innerRow font-xxs">Product</div>
                        </div>
                      </div>
                    </div>
                    <div className="p-6 pt-0">
                      <p className="text-muted font-xxs mt-2 px-2">
                        Create beautiful and intuitive user experiences for our
                        platform.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "marketing" && (
                <div className="grid gap-6">
                  <div
                    className="rounded-lg border bg-card text-card-foreground shadow-sm p-10 mb-4"
                    data-v0-t="card"
                  >
                    <div>
                      <div className="p-2">
                        <div className="upperRow">
                          <h5 className="font-semibold tracking-tight text-xl mb-2">
                            Content Marketing Manager
                          </h5>
                          <button className="btn btn-red rounded-pill fw-small text-white">
                            Apply Now
                          </button>
                        </div>
                        <div className="remoteRow font-xxs">
                          <div className="innerRow font-xxs">Remote</div>
                          <div className="innerRow  font-xxs">Full-time</div>
                          <div className="innerRow font-xxs">Marketing</div>
                        </div>
                      </div>
                    </div>
                    <div className="p-6 pt-0">
                      <p className="text-muted font-xxs mt-2 px-2">
                        Create compelling content to engage our community and
                        drive growth.
                      </p>
                    </div>
                  </div>
                  <div
                    className="rounded-lg border bg-card text-card-foreground shadow-sm p-10 mb-4"
                    data-v0-t="card"
                  >
                    <div>
                      <div className="p-2">
                        <div className="upperRow">
                          <h5 className="font-semibold tracking-tight text-xl mb-2">
                            Social Media Manager
                          </h5>
                          <button className="btn btn-red rounded-pill fw-small text-white">
                            Apply Now
                          </button>
                        </div>
                        <div className="remoteRow font-xxs">
                          <div className="innerRow font-xxs">Remote</div>
                          <div className="innerRow  font-xxs">Full-time</div>
                          <div className="innerRow font-xxs">Marketing</div>
                        </div>
                      </div>
                    </div>
                    <div className="p-6 pt-0">
                      <p className="text-muted font-xxs mt-2 px-2">
                        Manage our social media presence and community
                        engagement.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* resume section */}
      <section class="p-12">
        <div class=" mx-auto p-4 text-center">
          <h2 class="text-3xl font-bold mb-8 font-weight-bold p-3">
            Don't See the Right Role?
          </h2>
          <p class="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
            We're always looking for talented individuals to join our team. Send
            us your resume and we'll keep you in mind for future opportunities.
          </p>
          <button
            className="btn btn-red rounded-pill fw-small text-white"
            onClick={() =>
              (window.location.href =
                "mailto:support@witzzart.com?subject=Resume Submission&body=Hello, I would like to submit my resume.")
            }
          >
            Send Your Resume
          </button>
        </div>
      </section>
    </div>
  );
};

export default Careers;
