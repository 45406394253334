import React, { useEffect, useState, useMemo } from "react";

const PaginationComponent = ({
    total = 0,
    itemsPerPage = 10,
    currentPage = 1,
    onPageChange
}) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);

    const paginationItems = useMemo(() => {
        const pages = [];

        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <div
                    key={i}
                    active={i === currentPage}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </div>
            );
        }

        return pages;
    }, [totalPages, currentPage]);

    if (totalPages === 0) return null;

    return (
        <div className="float-right d-flex mt-3">
            <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}>
                        Previous
                    </button>
                </li>
                {paginationItems.map((key,value)=>{
                    return <li key={key} className={currentPage-1 == value ? "page-item active" : "page-item"}><button  className="page-link  btn btn-red border-0" onClick={() => onPageChange(value)}>{++value}</button></li>
                })}
                <li className="page-item">
                    <button className="page-link text-dark" onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}>
                        Next
                    </button>
                </li>
            </ul>
            </nav>
        </div>
    );
};

export default PaginationComponent;
