import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import TopNavBar from "../../Layouts/TopNavBar";
import axios from '../../../src/axiosInstance';
import { showErrorToast, showSuccessToast } from "../../components/Toast/Toaster";
import BasicInfo from '../Website/Create_event_feilds/Basic_Info';
import DateAndTime from '../Website/Create_event_feilds/date-and-time';
import TargetAudience from '../Website/Create_event_feilds/target-audience';
import EventDetails from '../Website/Create_event_feilds/event-details';
import LegalAndSafety from '../Website/Create_event_feilds/legal-and-safety';
import MarketingAssistance from '../Website/Create_event_feilds/marketing-assistance';
import { Preview } from '@mui/icons-material';
import { toast } from 'react-toastify';
import Location from '../Website/Create_event_feilds/location';

const AddEditEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/event');
      setEvent(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      showErrorToast('Failed to fetch categories');
    }
  };

  const formSteps = [
    { title: "Basic Info", component: BasicInfo },
    { title: "Date & Time", component: DateAndTime },
    { title: "Location", component: Location },
    { title: "Target Audience", component: TargetAudience },
    { title: "Event Details", component: EventDetails },
    { title: "Legal & Safety", component: LegalAndSafety },
    { title: "Marketing Assistance", component: MarketingAssistance },
    { title: "Preview", component: Preview },
  ];

  const [step, setStep] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userName, setUserName] = useState("");
  const [formData, setFormData] = useState({
    event_title: "",
    event_type: "",
    description: "",
    event_organizer_name: "",
    cancellation_policy: "Full refund",
    waiver_disclaimer: "",
    health_safety_guidelines: "",
    photography_consent: false,
    start_date: "",
    end_date: "",
    event_duration: "",
    start_time: "",
    end_time: "",
    timezone: "",
    event_highlights: [],
    event_agenda: [],
    event_language: "English",
    event_capacity: 0,
    required_equipment: [],
    dress_code: "Casual",
    accessibility_options: [],
    artists: [],
    venue_name: "",
    location_type: "In-Person",
    address: "",
    online_link: "",
    map: "",
    marketing: {
      featured_event: false,
      social_media_promotion: false,
      email_campaigns: false,
      influencer_collaboration: false,
      digital_advertisements: false,
    },
    ticket_type: "Free",
    tickets: [],
    image: [],
    age_restriction: "All Ages",
    audience_type: [],
    skill_level: "Beginner",
    fee_absorption: 0,
  });
  const [ticketPrices, setTicketPrices] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem("Token");
    if (!token) {
      navigate('/login');
      return;
    }
  }, [navigate]);

  // const getUserDetails= async ()=> {
  //   let id = localStorage.getItem("userId")

  //   try {
  //     const response = await axios.get(`/getUserProfile/${id}`);
  //     if(response)
  //     {
  //       setUserName(response?.data?.data?.user?.name)
  //       setFormData((prevState) => ({
  //         ...prevState,
  //         event_organizer_name: response?.data?.data?.user?.name,
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {    
    let requiredFields = [
      "event_title",
      "event_type",
      "description",
      "event_organizer_name",
      "start_date",
      "end_date",
      "event_duration",
      "start_time",
      "end_time",
      "timezone",
      "venue_name",
    ];

    // Dynamically add fields based on Location_type
    if (formData.location_type === "In-Person") {
      requiredFields.push("address", "map");
    } else if (formData.location_type === "Online") {
      requiredFields.push("online_link");
    } else if (formData.location_type === "Hybrid") {
      requiredFields.push("address", "map", "online_link");
    }

    let errors = [];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors.push(field);
      }
    });

    if (formData.tickets.length === 0) errors.push("tickets");
    if (formData.image.length === 0) errors.push("image");

    if (errors.length > 0) {
      console.log("asderftyu7i",errors.length);

      toast.error(`Please fill all required fields: ${errors.join(", ")}`);
      return;
    }

    const formDataObj = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        formDataObj.append(key, JSON.stringify(value));
      } else {
        formDataObj.append(key, value);
      }
    });

    selectedFiles.forEach((file) => {
      formDataObj.append("event_image", file);
    });


    try {
      const response = await axios.post('/event', formDataObj, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });


      if (response.status === 200) {
        showSuccessToast("Event added successfully");
        navigate(`/admin/events`);
      } else {
        showErrorToast(response.data.message || "Failed to submit event");
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleImageSelect = (files) => {
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const getAmount = async () => {
    const payload = {
      tickets: formData.tickets,
      fee_absorption: formData.fee_absorption
    };
    try {
      const response = await axios.post('/event-slab8', { 'data': payload });
      console.log('Response:', response);
      setTicketPrices(response.data.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const CurrentStepComponent = formSteps[step].component;

  

  const validationSchema = Yup.object({
    category_id: Yup.string().required('Category is required'),
    event_name: Yup.string().required('Event name is required'),
    event_date: Yup.date().required('Event date is required'),
    start_time: Yup.string().required('Start time is required'),
    end_time: Yup.string().required('End time is required'),
    venue: Yup.string().required('Venue is required'),
    total_ticket: Yup.number().positive('Must be a positive number').required('Total tickets is required'),
    status: Yup.string().required('Status is required'),
    price: Yup.number().positive('Must be a positive number').required('Price is required'),
    Images: Yup.array().min(1, 'At least one image is required'),
  });

  const formik = useFormik({
    initialValues: {
      category_id: "",
      event_name: "",
      event_date: "",
      start_time: "",
      end_time: "",
      venue: "",
      total_ticket: "",
      status: "",
      price: "",
      Images: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
          if (key === 'Images') {
            values[key].forEach(image => formData.append('Images', image.file));
          } else {
            formData.append(key, values[key]);
          }
        });

        let result;
        if (id) {
          result = await axios.put(`/event/${id}`, formData);
        } else {
          result = await axios.post('/event', formData);
        }
        
        if (result.status === 200) {
          showSuccessToast("Event added/updated successfully");
          navigate("/admin/events");
        } else {
          showErrorToast(result.data.message || "Failed to submit event");
        }
      } catch (error) {
        console.error("Error submitting form", error);
        showErrorToast(error.response?.data?.message || "Failed to submit event");
      }
    },
  });

  useEffect(() => {
    if (id) {
      fetchEventData();
    }
  }, [id]);

  const fetchEventData = async () => {
    try {
      const result = await axios.get(`/event/${id}`);
      if (result.status === 200) {
        const eventData = result.data.data;
  
        // Map API response to form fields
        setFormData({
          event_title: eventData.event_title || "",
          event_type: eventData.event_type || "",
          description: eventData.description || "",
          event_organizer_name: eventData.event_organizer_name || "",
          start_date: eventData.start_date || "",
          end_date: eventData.end_date || "",
          event_duration: eventData.event_duration || "",
          start_time: eventData.start_time || "",
          end_time: eventData.end_time || "",
          timezone: eventData.timezone || "",
          venue_name: eventData.venue_name || "",
          location_type: eventData.location_type || "",
          address: eventData.address || "",
          map: eventData.map || "",
          age_restriction: eventData.age_restriction || "All Ages",
          audience_type: JSON.parse(eventData.audience_type || "[]"),
          skill_level: eventData.skill_level || "Beginner",
          event_highlights: JSON.parse(eventData.event_highlights || "[]"),
          event_agenda: JSON.parse(eventData.event_agenda || "[]"),
          event_language: eventData.event_language || "English",
          event_capacity: eventData.event_capacity || 0,
          required_equipment: JSON.parse(eventData.required_equipment || "[]"),
          dress_code: eventData.dress_code || "Casual",
          accessibility_options: JSON.parse(eventData.accessibility_options || "[]"),
          cancellation_policy: eventData.cancellation_policy || "Full refund",
          waiver_disclaimer: eventData.waiver_disclaimer || "",
          health_safety_guidelines: eventData.health_safety_guidelines || "",
          photography_consent: eventData.photography_consent === "true",
          fee_absorption: eventData.fee_absorption || 0,
          ticket_type: eventData.ticket_type || "Free",
          tickets: eventData.tickets || [],
          image: eventData.images || [],
          status: eventData.status || "",
        });
  
        showSuccessToast("Event data fetched successfully");
      } else {
        showErrorToast(result.data.message || "Failed to fetch event data");
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
      showErrorToast("Failed to fetch event data");
    }
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.currentTarget.files);
    const imageFiles = files.map((file, index) => ({
      file,
      preview: URL.createObjectURL(file),
      name: file.name,
      index,
    }));
    formik.setFieldValue("Images", imageFiles);
  };

  return (
    <div>
      <TopNavBar links={{ list: "/admin/events", add: "/admin/events/add" }} />
      <div className="container px-3 pb-4 py-1 bg-white rounded shadow">
      {/* Progress Bar for Mobile */}
      <div className="d-block d-lg-none mb-3">

        <h5 className="text-center mt-4 main-red">{formSteps[step].title}</h5>

        <div className="progress" style={{ height: "7px" }}>
          <div
            className="progress-bar btn-red"
            style={{ width: `${((step + 1) / formSteps.length) * 100}%` }}
          ></div>
        </div>
      </div>

      {/* Step Indicators - Visible on Desktop */}
      <div className="d-none d-lg-block my-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          {formSteps.map((formStep, index) => (
            <div key={formStep.title} className="text-center flex-fill">
              <div
                className={`rounded-circle text-small mx-auto mb-2 ${
                  index <= step ? "btn-red text-white" : "bg-secondary text-white"
                }`}
                style={{ width: "25px", height: "25px", lineHeight: "25px" }}
              >
                <p>{index + 1}</p>
              </div>
              <small className={index <= step ? "main-red" : "text-secondary"}>
                {formStep.title}
              </small>
            </div>
          ))}
        </div>
        <div className="progress" style={{ height: "7px" }}>
          <div
            className="progress-bar btn-red"
            style={{ width: `${((step + 1) / formSteps.length) * 100}%` }}
          ></div>
        </div>
      </div>

      <form>
        <div className={`fade ${step === step ? "show" : ""}`}>
          <CurrentStepComponent
            formData={formData}
            onChange={handleInputChange}
            onImageSelect={handleImageSelect}
            ticketPrices={ticketPrices}
          />
        </div>

        <div className="d-flex justify-content-between mt-4">
          {/* Previous Button */}
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setStep((prev) => Math.max(0, prev - 1))}
            disabled={step === 0}
          >
            <i className="bi bi-chevron-left"></i> Previous
          </button>

          {step < formSteps.length - 1 ? (
            <button
              type="button"
              className="btn btn-red"
              onClick={() => {
                if (step === formSteps.length - 2) {
                  getAmount();
                }
                setStep((prev) => Math.min(formSteps.length - 1, prev + 1));
              }}
            >
              Next <i className="bi bi-chevron-right"></i>
            </button>
          ) : (
            <button type="button" onClick={handleSubmit} className="btn btn-red">
              Create Event
            </button>
          )}
        </div>
      </form>
    </div>

    </div>
  );
};

export default AddEditEvent;