import React from "react";
import { Calendar, Clock, MapPin, User } from "lucide-react"; // If you're still using lucide-react icons
import { Link, useNavigate } from "react-router-dom";

function WorkshopCard({
  title,
  image,
  date,
  time,
  location,
  price,
  href,
  tags = "",
  type,
}) {
  const navigate = useNavigate();
  const handleNavigation = () => {
    // If href is relative, ensure it starts with a '/'
    const absolutePath = href.startsWith("/") ? href : `/${href}`;
    navigate(absolutePath, { replace: true }); // Replaces the current history entry
  };

  function formatDate(isoDate) {
    if (isoDate) {
      const date = new Date(isoDate);
      const options = { year: "numeric", month: "short", day: "numeric" };
      return date?.toLocaleDateString("en-US", options);
    }
  }

  function formatTime(timeString) {
    if (timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
      return `${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;
    }
  }

  return (
    <div
      className="card group borer-light rounded-3 position-relative main-card cursor-pointer overflow-hidden transition-all duration-100 transform hover:-translate-y-1"
      onClick={handleNavigation}
    >
      <div className="position-relative ">
        <div
          className="position-absolute mt-2 ml-2  top-3 left-3 z-10 d-flex flex-wrap gap-2"
          style={{ zIndex: 1 }}
        >
          <span
            className="badge bg-danger rounded-pill  text-white animate__animated animate__fadeIn"
            style={{ zIndex: 1 }}
          >
            {type}
          </span>
          {/* {tags.map((tag) => ( */}
          <span className="badge rounded-pill bg-lightgray  text-dark">
            {tags}
          </span>
          {/* ))} */}
        </div>
        <div className="position-relative border-b overflow-hidden">
          <div
            className="gradient-overlay"
            style={{
              objectFit: "cover",
              height: "25vh",
              transition: "transform 0.5s",
            }}
          >
            <img
              src={image}
              className="rounded-small"
              style={{ objectFit: "cover" }}
            />
          </div>

          <div className="position-absolute  top-0 start-0 w-100 h-100 bg-black-50 group-hover:bg-black-70 transition-all" />
        </div>
      </div>
      <div className="card-body border-top">
        <h5 className="card-title text-red fw-semibold mb-2">{title}</h5>
        <div className="d-flex flex-column mb-2">
          <div className="d-flex align-items-center text-muted">
            <Calendar className="w-4 h-4 my-2 mr-2" size={15} />
            <span className="font-xs">{formatDate(date)}</span>
          </div>
          <div className="d-flex align-items-center text-muted">
            <Clock className="w-4 h-4 mr-2" size={15} />
            <span className="font-xs">{formatTime(time)}</span>
          </div>
          <div className="d-flex align-items-center text-muted">
            <MapPin className="w-4 h-4 my-2 mr-2" size={15} />
            <span className="font-xs">{location}</span>
          </div>
        </div>
        <div className="d-flex justify-content-between pt-2 border-top">
          <span className="fs-5 fw-bold text-red">₹{price}</span>
          <a
            // href={href}
            className="btn btn-red text-white btn-sm rounded-pill hover:bg-red-600 transform hover:scale-105 transition-all duration-300"
          >
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default WorkshopCard;
