import React from 'react';
import { Outlet } from 'react-router-dom';
import {Header} from "./Shared/Header"
import {Footer} from "./Shared/Footer"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WebsiteLayout = () => {

  return (
    <div className="d-flex ">

      {/* Main Content Area */}
      <div className="all-width overflow-x-hidden" >
        {/* Top Navigation Bar */}
          <Header />
        {/* Main Content */}
        <div className="mt-2  d-flex justify-content-center">
          <Outlet />
        </div>
          <Footer/>
      </div>
      <ToastContainer />
    </div>
  );
};

export default WebsiteLayout;