import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showErrorToast = (message) => {

  // const words = message.toLowerCase().split(' ');

  // // Capitalize the first letter of each word
  // const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // let formattedMessage = titleCaseWords.join(' ');

  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
};


export const showSuccessToast = (message) => {
  toast(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};


