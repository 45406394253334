"use client";

import { useState, useRef, useEffect } from "react";
import {
  Camera,
  Facebook,
  Globe,
  Instagram,
  Linkedin,
  Phone,
  Star,
  Twitter,
} from "lucide-react";
import { Mail } from "@mui/icons-material";
import axios from "../../../src/axiosInstance";
import ProfileEventCards from "./Shared/profileEventCards";

import { useParams, useNavigate } from "react-router-dom";

export default function ProfilePage({ username }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [userWork, setUserWork] = useState([]);
  const fileInputRef = useRef(userDetails.profileImage);
  const filecoverInputRef = useRef(null);
  const [avatar, setAvatar] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [activeTab, setActiveTab] = useState("about");
  const [showModal, setShowModal] = useState(false);
  let myprofile = localStorage.getItem("userId");

  const [otherinfo, setotherinfo] = useState({
    upcomingEvents: [
      {
        title: "Urban Photography Workshop",
        date: "May 11, 2024",
        location: "Mumbai",
      },
      {
        title: "Street Photography Masterclass",
        date: "May 12, 2024",
        location: "Delhi",
      },
      {
        title: "Portrait Photography Workshop",
        date: "May 13, 2024",
        location: "Bangalore",
      },
    ],
    rating: 4.9,
    reviews: 120,
  });

  const works = [
    {
      title: "Urban Life Series",
      image: "/placeholder.svg?height=400&width=600&text=Urban+Life",
      category: "Photography",
      date: "March 2024",
    },
    {
      title: "Digital Dreams",
      image: "/placeholder.svg?height=400&width=600&text=Digital+Dreams",
      category: "Digital Art",
      date: "February 2024",
    },
    {
      title: "City Lights",
      image: "/placeholder.svg?height=400&width=600&text=City+Lights",
      category: "Photography",
      date: "January 2024",
    },
  ];

  const reviews = [
    {
      name: "Sarah Chen",
      avatar: "/placeholder.svg",
      rating: 5,
      date: "March 2024",
      comment:
        "Amazing workshop! John is an excellent instructor who really knows how to explain complex concepts in an easy-to-understand way.",
    },
    {
      name: "Mike Johnson",
      avatar: "/placeholder.svg",
      rating: 4,
      date: "February 2024",
      comment:
        "Great experience overall. The workshop was well-structured and informative.",
    },
  ];

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const media = URL.createObjectURL(file);
      setAvatar(media);
      const formData = { profileImageFile: file, id: 8 };
      try {
        const response = await axios.post(`/uploadProfile/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Response:", response);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleCoverClick = () => {
    filecoverInputRef.current.click(); // Trigger the file input click
  };

  const handleCoverFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const media = URL.createObjectURL(file);
      setCoverImage(media);

      const formData = { thumbnailFile: file, id: 8 };

      try {
        // Send FormData in a POST request to the backend
        const response = await axios.post(`/uploadThumbnail/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Response:", response);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (field, value) => {
    setUserDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const convertformdata = () => {
    const formData = new FormData();

    // Loop through the userDetails object and append to FormData
    Object.keys(userDetails).forEach((key) => {
      if (Array.isArray(userDetails[key])) {
        // For arrays like tags or achievements, convert to a string (e.g., JSON.stringify)
        formData.append(key, JSON.stringify(userDetails[key]));
      } else if (
        typeof userDetails[key] === "object" &&
        userDetails[key] !== null
      ) {
        // Handle nested objects like social links
        Object.keys(userDetails[key]).forEach((subKey) => {
          formData.append(`${key}[${subKey}]`, userDetails[key][subKey]);
        });
      } else {
        // For all other values, just append directly
        formData.append(key, userDetails[key]);
      }
    });

    // If there are files (profile and cover images), append them to FormData
    if (avatar) {
      formData.append("profileImageFile", avatar);
    }

    if (coverImage) {
      formData.append("thumbnailFile", coverImage);
    }

    return formData;
  };

  const SaveDetails = async () => {
    const formData = convertformdata(userDetails);

    try {
      // Send FormData in a POST request to the backend
      const response = await axios.post(`/userProfile/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response:", response);
      setShowModal(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getUserDetails = async () => {
    try {
      if (!id) {
        navigate("/signup");
        console.error("User ID not found!");
        return;
      }

      const response = await axios.get(`/getUserProfile/${id}`);

      if (response.data.data.user) {
        const keysToParse = ["achievement", "tags"];
        keysToParse.forEach((key) => {
          if (response.data.data.user[key]) {
            response.data.data.user[key] = JSON.parse(
              response.data.data.user[key]
            );
          }
        });
      }

      setUserDetails(response.data.data.user);
      setAvatar(response.data.data.user.profileImage);
      setCoverImage(response.data.data.user.thumbnail);
      setUserWork(response.data.data.eventdetail);

      console.log("Response:", response.data.data.eventdetail);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div className="px-2 all-width">
      {/* Cover Photo Section */}
      <div className="row px-3">
        <div className="col-12 px-0 position-relative">
          <div
            className="bg-light border"
            style={{ height: "300px", cursor: "pointer" }}
              onClick={handleCoverClick}
            >
              {coverImage ? (
                <img
                  src={coverImage}
                  alt="Cover"
                  className="w-100 h-100 object-cover"
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <div
                  className="d-flex all-width justify-content-center align-items-center bg-gradient-to-b"
                  style={{
                    background: "linear-gradient(to bottom, #f0f0f0, #e0e0e0)",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Camera style={{ fontSize: "48px", color: "#aaa" }} />
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                ref={filecoverInputRef}
                style={{ display: "none" }}
                onChange={handleCoverFileChange}
              />
            </div>
          </div>
        </div>

        <div className="row px-3">
          <div className="col-12 col-md-3 col-lg-2 position-relative">
            <div
              className="rounded-circle bg-white border d-flex justify-content-center align-items-center"
              style={{
                height: "150px",
                width: "150px",
                marginTop: "-50px",
                cursor: "pointer",
              }}
              onClick={handleAvatarClick}
            >
              {avatar ? (
                <img
                  src={avatar}
                  className="rounded-circle"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <Camera style={{ fontSize: "48px", color: "#aaa" }} />
              )}
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
          </div>
            </div>

            <div className="col-12 col-md-9 col-lg-10 d-flex flex-column flex-md-row align-items-start justify-content-between mt-3 mt-md-0">
              <div className="">
                <h3 className="fw-bold">{userDetails?.name}</h3>
                <p className="text-muted">{userDetails?.subtitle}</p>
                <div className="mb-4">
                  {userDetails?.tags?.map((tag) => (
                    <span key={tag} className="badge bg-secondary me-2">
                      {tag}
                    </span>
                  ))}
                </div>

                {/* Location and Reviews */}
                <div className="d-flex align-items-center gap-3 text-muted">
                  <div className="d-flex align-items-center gap-1">
                    <i className="bi bi-pin-map"></i>
                    <span>{userDetails?.location !== "null" && userDetails?.location !== null && userDetails?.location}</span>
                  </div>
                </div>
              </div>

              <div className="mt-4 d-flex flex-row gap-2 align-items-end justify-content-end all-width">
              <div className="mb-2">
                  <a className="text-muted redTextOnhover me-3" href={userDetails?.facebook_link} target="_blank" rel="noopener noreferrer">
                  <Facebook style={{ fontSize: "10px" }} />
                  </a>
                  <a className="text-muted redTextOnhover me-3" href={userDetails?.twitter} target="_blank" rel="noopener noreferrer">
                  <Twitter style={{ fontSize: "10px" }} />
                  </a>
                  <a className="text-muted redTextOnhover me-3" href={userDetails?.ig_link} target="_blank" rel="noopener noreferrer">
                  <Instagram style={{ fontSize: "10px" }} />
                  </a>
                  <a className="text-muted redTextOnhover me-3" href={userDetails?.linkedIn} target="_blank" rel="noopener noreferrer">
                  <Linkedin style={{ fontSize: "10px" }} />
                  </a>
              </div>
                {myprofile === id && (
                  <button onClick={handleOpenModal} className="btn btn-red  mt-3">
                    <small>Edit Profile</small>
                  </button>
                )}
</div>
        </div>
      </div>

      {/* Tabs for About, Works, Reviews */}
      <div className="row mt-5 ">
        <div className="col-12">
        <ul
          className="nav nav-pills bg-lightgray rounded-pill p-1"
          style={{ fontSize: "14px" }}
        >
          <li className="nav-item px-0">
            <p
              className={`rounded-pill m-0 ${
                activeTab === "about"
                  ? "active text-black bg-white"
                  : "text-secondary"
              }`}
              onClick={() => setActiveTab("about")}
              style={{ padding: "5px 10px", cursor: "pointer" }}
            >
              About
            </p>
          </li>
          <li className="nav-item px-0">
            <p
              className={`rounded-pill m-0 ${
                activeTab === "works"
                  ? "active text-black bg-white"
                  : "text-secondary"
              }`}
              onClick={() => setActiveTab("works")}
              style={{ padding: "5px 10px", cursor: "pointer" }}
            >
              Works
            </p>
          </li>
          <li className="nav-item px-0">
            <p
              className={`rounded-pill m-0 ${
                activeTab === "reviews"
                  ? "active text-black bg-white"
                  : "text-secondary"
              }`}
              onClick={() => setActiveTab("reviews")}
              style={{ padding: "5px 10px", cursor: "pointer" }}
            >
              Reviews
            </p>
          </li>
        </ul>
        </div>
        <div className="col-12 mt-4">
              {activeTab === "about" && (
                <div className="row tab-pane fade show active">
                    <div className="col-12 col-md-8">
                      <div className="card mb-4">
                        <div className="card-body">
                          <h5 className="fw-bold mb-4">
                            About {userDetails?.name?.split(" ")[0]}
                          </h5>
                          <p className="text-muted">{userDetails?.about !== null && userDetails?.about !== "null" && userDetails?.about}</p>
                          <p className="text-muted">{userDetails?.passion}</p>
                        </div>
                      </div>

                      <div className="card mb-4">
                        <div className="card-body">
                          <h5 className="fw-bold mb-4">Achievements</h5>
                          {userDetails?.achievement?.map((achievement, index) => (
                              <div key={index} className="d-flex gap-4 mb-1">
                                <div
                                  className="badge bg-light text-danger d-flex align-items-center justify-content-center"
                                  style={{ width: "50px", height: "50px" }}
                                >
                                  {index + 1}
                                </div>
                                <div>
                                  <p className="mb-0 fw-semibold">
                                    {achievement.title}
                                  </p>
                                  <p className="text-muted">
                                    {achievement.description}
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="card mb-4">
                        <div className="card-body">
                          <h5 className="fw-bold">Contact</h5>
                          <div className="mb-3 text-muted">
                            <Mail
                              className="mr-2"
                              style={{ fontSize: "20px" }}
                            />
                            { userDetails?.email !== "null" && userDetails?.email !== null && userDetails?.email}
                          </div>
                          <div className="mb-3 text-muted">
                            <Phone
                              className="mr-2"
                              style={{ fontSize: "20px" }}
                            />
                            {userDetails?.mobile_no !== "null" && userDetails?.mobile_no !== null && userDetails?.mobile_no}
                          </div>
                          <div className="text-muted">
                            <Globe
                              className="mr-2"
                              style={{ fontSize: "20px" }}
                            />{" "}
                            {userDetails?.website !== "null" && userDetails?.website !== null && userDetails?.website}
                          </div>
                        </div>
                      </div>

                      <div className="card mb-4">
                        <div className="card-body">
                          <h5 className="fw-bold">Upcoming Events</h5>
                          {otherinfo.upcomingEvents.map((event, index) => (
                            <div
                              key={index}
                              className="mb-3 d-flex align-items-center gap-3"
                            >
                              <div className="badge bg-light text-danger d-flex flex-column">
                                <span className="mb-1">
                                  {event.date.split(",")[0]}
                                </span>
                                <span className="">
                                  {event.date.split(",")[1]}
                                </span>
                              </div>
                              <div>
                                <p className="fw-semibold mb-0">
                                  {event.title}
                                </p>
                                <small>{event.location}</small>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "works" && (
                <div className="row tab-pane fade show active">
                    {userWork?.map((work, index) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-4"
                        key={index}
                      >
                        <ProfileEventCards
                          title={work?.event_title}
                          image={work?.images?.[0]?.image}
                          date={work?.start_date}
                          time={work?.start_time}
                          location={work?.address}
                          price={work?.tickets?.[0]?.price}
                          href={"eventdetails/" + work?.id}
                          tags={work?.event_type}
                          type={work?.type}
                          tickets={work?.tickets}
                          showdetails={myprofile === id? true :false}
                        />
                      </div>
                    ))}
                </div>
              )}

              {activeTab === "reviews" && (
                <div className="tab-pane fade show active">
                  <div className="d-flex justify-content-end mb-4">
                    {/* <button
                      className="btn btn-outline-secondary"
                      onClick={() => setIsReviewModalOpen(true)}
                    >
                      Write a Review
                    </button> */}
                  </div>

                  <div className="space-y-4">
                    {reviews.map((review, index) => (
                      <div key={index} className="card mb-4">
                        <div className="card-body">
                          <div className="d-flex gap-3">
                            <img
                              src={review.avatar}
                              alt={review.name}
                              className="rounded-circle"
                              style={{ width: "50px", height: "50px" }}
                            />
                            <div className="flex-grow-1">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <h5 className="fw-semibold">{review.name}</h5>
                                  <p className="text-muted">{review.date}</p>
                                </div>
                                <div>
                                  {[...Array(review.rating)].map((_, i) => (
                                    <i
                                      key={i}
                                      className="bi bi-star-fill text-warning"
                                    ></i>
                                  ))}
                                </div>
                              </div>
                              <p>{review.comment}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        </div>

        {showModal && (
          <div
            className="modal fade show d-block"
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Profile</h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={handleCloseModal}
                  ></button>
                </div>
                <div
                  className="modal-body"
                  style={{ maxHeight: "70vh", overflow: "scroll" }}
                >
                  {/* Edit Form */}
                  {/* Basic Fields */}
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={userDetails?.name}
                      onChange={(e) =>
                        handleInputChange("name", e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      value={userDetails?.subtitle}
                      onChange={(e) =>
                        handleInputChange("subtitle", e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="location" className="form-label">
                      Location
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      value={userDetails?.location}
                      onChange={(e) =>
                        handleInputChange("location", e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="about" className="form-label">
                      Bio
                    </label>
                    <textarea
                      className="form-control"
                      id="about"
                      rows="4"
                      value={userDetails?.about}
                      onChange={(e) =>
                        handleInputChange("about", e.target.value)
                      }
                    ></textarea>
                  </div>

                  {/* Contact */}
                  <div className="mb-3">
                    <label htmlFor="contact-email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="contact-email"
                      disabled
                      value={userDetails?.email}
                      onChange={(e) =>
                        setUserDetails((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="contact-phone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="contact-phone"
                      value={userDetails?.mobile_no}
                      onChange={(e) =>
                        setUserDetails((prev) => ({
                          ...prev,
                          mobile_no: e.target.value,
                        }))
                      }
                    />
                  </div>

                  {/* Achievements */}
                  <div className="mb-3">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <label className="form-label">Achievements</label>
                      <button
                        type="button"
                        className="btn btn-danger mt-2"
                        onClick={() =>
                          setUserDetails((prev) => ({
                            ...prev,
                            achievement: [
                              ...(prev.achievement || []),
                              { title: "", description: "" },
                            ],
                          }))
                        }
                      >
                        + Add Achievement
                      </button>
                    </div>
                    {userDetails?.achievement?.map((achievement, index) => (
                      <div key={index} className="mb-3">
                        {/* Title Input */}
                        <input
                          type="text"
                          className="form-control mb-1"
                          placeholder="Achievement Title"
                          value={achievement.title}
                          onChange={(e) =>
                            setUserDetails((prev) => {
                              const updatedAchievements = [...prev.achievement];
                              updatedAchievements[index].title = e.target.value;
                              return {
                                ...prev,
                                achievement: updatedAchievements,
                              };
                            })
                          }
                        />
                        {/* Description Input */}
                        <textarea
                          className="form-control"
                          placeholder="Achievement Description"
                          rows="3"
                          value={achievement.description}
                          onChange={(e) =>
                            setUserDetails((prev) => {
                              const updatedAchievements = [...prev.achievement];
                              updatedAchievements[index].description =
                                e.target.value;
                              return {
                                ...prev,
                                achievement: updatedAchievements,
                              };
                            })
                          }
                        ></textarea>
                        {/* Remove Achievement Button */}
                        <button
                          type="button"
                          className="btn btn-danger mt-2"
                          onClick={() =>
                            setUserDetails((prev) => ({
                              ...prev,
                              achievement: prev.achievement.filter(
                                (_, i) => i !== index
                              ),
                            }))
                          }
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>

                  {/* Tags */}
                  <div className="mb-3">
                    <label className="form-label">Tags</label>
                    <div className="d-flex flex-wrap gap-2">
                      {/* Display existing tags as chips */}
                      {userDetails?.tags?.map((tag, index) => (
                        <span
                          key={index}
                          className="badge bg-danger d-flex align-items-center"
                          style={{ padding: "0.5rem 1rem", cursor: "pointer" }}
                          onClick={() =>
                            setUserDetails((prev) => ({
                              ...prev,
                              tags: prev.tags.filter((_, i) => i !== index),
                            }))
                          }
                        >
                          {tag} &times;
                        </span>
                      ))}
                    </div>

                    {/* Input field for adding new tags */}
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Add a tag and press Enter"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && e.target.value.trim()) {
                          const newTag = e.target.value.trim();
                          if (!userDetails?.tags.includes(newTag)) {
                            // Prevent duplicates
                            setUserDetails((prev) => ({
                              ...prev,
                              tags: [...prev.tags, newTag],
                            }));
                          }
                          e.target.value = ""; // Clear the input field after adding
                          e.preventDefault(); // Prevent form submission
                        }
                      }}
                    />
                  </div>

                  {/* Social Links */}
                  <div className="mb-3">
                    <label className="form-label">Social Links</label>
                    <div className="mb-2">
                      <label className="form-label">Facebook</label>
                      <input
                        type="url"
                        className="form-control"
                        value={userDetails?.facebook_link}
                        onChange={(e) =>
                          setUserDetails((prev) => ({
                            ...prev,
                            facebook_link: e.target.value,
                          }))
                        }
                      />
                    </div>

                    <div className="mb-2">
                      <label className="form-label">Instagram</label>
                      <input
                        type="url"
                        className="form-control"
                        value={userDetails?.ig_link}
                        onChange={(e) =>
                          setUserDetails((prev) => ({
                            ...prev,
                            ig_link: e.target.value,
                          }))
                        }
                      />
                    </div>

                    <div className="mb-2">
                      <label className="form-label">Twitter</label>
                      <input
                        type="url"
                        className="form-control"
                        value={userDetails?.twitter}
                        onChange={(e) =>
                          setUserDetails((prev) => ({
                            ...prev,
                            twitter: e.target.value,
                          }))
                        }
                      />
                    </div>

                    <div className="mb-2">
                      <label className="form-label">Linkedin</label>
                      <input
                        type="url"
                        className="form-control"
                        value={userDetails?.linkedIn}
                        onChange={(e) =>
                          setUserDetails((prev) => ({
                            ...prev,
                            linkedIn: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => SaveDetails()}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
