import { Fragment } from "react";
import SideBar from './SideBar';
import Header from './Header';
import Footer from './Footer'
import { useState,useEffect,useContext } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate,useLocation } from "react-router-dom";
import AuthContext from '../context/Auth/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = (props) => {
  return (
    <Fragment>
    <div className="font-montserrat">     
      <div id="main_content">
        <div className="">
            <div>
                <SideBar />
            </div>
            <div className="page">
                <Header />
                <Outlet />
                <Footer />
            </div>
        </div>
      </div>
    </div>
    < ToastContainer/>
    </Fragment>
  );
};

export default Main;
