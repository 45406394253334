// src/axiosInstance.js

import axios from 'axios';

// Create an Axios instance with the base URL from the .env file
const API_URL = process.env.REACT_APP_API_PATH;;
const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Optionally, set up interceptors for requests and responses
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const token = localStorage.getItem('Token');

    // If token exists, attach it to the request headers as a Bearer token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful response
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
