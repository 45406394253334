export default function LegalAndSafety({ formData, onChange }) {
  return (
    <div className="container space-y-6">
      {/* Cancellation Policy */}
      <div className="mb-4">
        <label className="form-label">Cancellation Policy*</label>
        <select
          name="cancellation_policy"
          className="form-select"
          value={formData.cancellation_policy}
          onChange={onChange}
          required
        >
          <option value="Full refund">Full refund</option>
          <option value="Partial refund">Partial refund</option>
          <option value="Non-refundable">Non-refundable</option>
        </select>
      </div>

      {/* Waiver/Disclaimer */}
      <div className="mb-4">
        <label className="form-label">Waiver/Disclaimer</label>
        <textarea
          name="waiver_disclaimer"
          className="form-control"
          placeholder="Enter any legal waivers or disclaimers"
          value={formData.waiver_disclaimer}
          onChange={onChange}
        />
        <small className="form-text text-muted">
          Legal waivers if required (e.g., for adventure activities)
        </small>
      </div>

      {/* Health and Safety Guidelines */}
      <div className="mb-4">
        <label className="form-label">Health and Safety Guidelines</label>
        <textarea
          name="health_safety_guidelines"
          className="form-control"
          placeholder="Enter health and safety guidelines"
          value={formData.health_safety_guidelines}
          onChange={onChange}
        />
        <small className="form-text text-muted">
          COVID-19 or other health requirements
        </small>
      </div>

      {/* Photography Consent */}
      <div className="mb-4 d-flex justify-content-between align-items-center border p-2 rounded">
        <div>
          <label className="form-label">Photography Consent</label>
          <small className="form-text text-muted">
            Inform attendees about photo/video capture during the event.
          </small>
        </div>
        <div>
          <input
            type="checkbox"
            name="photography_consent"
            checked={formData.photography_consent}
            onChange={onChange}
            className="form-check-input"
          />
        </div>
      </div>
    </div>
  );
}
