import React, { useState, useEffect } from "react";
import BasicInfo from "./Create_event_feilds/Basic_Info";
import DateAndTime from "./Create_event_feilds/date-and-time";
import Location from "./Create_event_feilds/location";
import TargetAudience from "./Create_event_feilds/target-audience";
import EventDetails from "./Create_event_feilds/event-details";
import LegalAndSafety from "./Create_event_feilds/legal-and-safety";
import MarketingAssistance from "./Create_event_feilds/marketing-assistance";
import Preview from "./Create_event_feilds/preview";
import axios from '../../../src/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from '../../components/Toast/Toaster';


const formSteps = [
  { title: "Basic Info", component: BasicInfo },
  { title: "Date & Time", component: DateAndTime },
  { title: "Location", component: Location },
  { title: "Target Audience", component: TargetAudience },
  { title: "Event Details", component: EventDetails },
  { title: "Legal & Safety", component: LegalAndSafety },
  { title: "Marketing Assistance", component: MarketingAssistance },
  { title: "Preview", component: Preview }
];

export default function CreateEventForm() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userName, setUserName] = useState("");
  const [formData, setFormData] = useState({
    event_title: "",
    event_type: "",
    description: "",
    event_organizer_name: "",
    cancellation_policy: "Full refund",
    waiver_disclaimer: "",
    health_safety_guidelines: "",
    photography_consent: false,
    start_date: "",
    end_date: "",
    event_duration: "",
    start_time: "",
    end_time: "",
    timezone: "",
    event_highlights: [],
    event_agenda: [],
    event_language: "English",
    event_capacity: 0,
    required_equipment: [],
    dress_code: "Casual",
    accessibility_options: [],
    artists: [],
    venue_name: "",
    location_type: "In-Person",
    address: "",
    online_link: "",
    map: "",
    marketing: {
      featured_event: false,
      social_media_promotion: false,
      email_campaigns: false,
      influencer_collaboration: false,
      digital_advertisements: false,
    },
    ticket_type: "Free",
    tickets: [],
    image: [],
    age_restriction: "All Ages",
    audience_type: [],
    skill_level: "Beginner",
    fee_absorption: 0,
  });
  const [ticketPrices, setTicketPrices] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem("Token");
    if (!token) {
      navigate('/login');
      return;
    }
    getUserDetails()
  }, [navigate]);

  const getUserDetails= async ()=> {
    let id = localStorage.getItem("userId")

    try {
      const response = await axios.get(`/getUserProfile/${id}`);
      if(response)
      {
        setUserName(response?.data?.data?.user?.name)
        setFormData((prevState) => ({
          ...prevState,
          event_organizer_name: response?.data?.data?.user?.name,
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {    
    let requiredFields = [
      "event_title",
      "event_type",
      "description",
      "event_organizer_name",
      "start_date",
      "end_date",
      "event_duration",
      "start_time",
      "end_time",
      "timezone",
      "venue_name",
    ];

    // Dynamically add fields based on Location_type
    if (formData.location_type === "In-Person") {
      requiredFields.push("address", "map");
    } else if (formData.location_type === "Online") {
      requiredFields.push("online_link");
    } else if (formData.location_type === "Hybrid") {
      requiredFields.push("address", "map", "online_link");
    }

    let errors = [];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors.push(field);
      }
    });

    if (formData.tickets.length === 0) errors.push("tickets");
    if (formData.image.length === 0) errors.push("image");

    if (errors.length > 0) {
      console.log("asderftyu7i",errors.length);

      toast.error(`Please fill all required fields: ${errors.join(", ")}`);
      return;
    }

    const formDataObj = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        formDataObj.append(key, JSON.stringify(value));
      } else {
        formDataObj.append(key, value);
      }
    });

    selectedFiles.forEach((file) => {
      formDataObj.append("event_image", file);
    });


    try {
      const response = await axios.post('/event', formDataObj, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log('Response:::::::::', response);

      if (response.status === 200) {
        showSuccessToast("Event added successfully");
        navigate(`/eventdetails/${response?.data?.data?.id}`);
      } else {
        showErrorToast(response.data.message || "Failed to submit event");
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleImageSelect = (files) => {
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const getAmount = async () => {
    const payload = {
      tickets: formData.tickets,
      fee_absorption: formData.fee_absorption
    };
    try {
      const response = await axios.post('/event-slab8', { 'data': payload });
      console.log('Response:', response);
      setTicketPrices(response.data.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const CurrentStepComponent = formSteps[step].component;

  return (
    <div className="container px-3 pb-4 py-1 bg-white rounded shadow">
      {/* Progress Bar for Mobile */}
      <div className="d-block d-lg-none mb-3">

        <h5 className="text-center mt-4 main-red">{formSteps[step].title}</h5>

        <div className="progress" style={{ height: "7px" }}>
          <div
            className="progress-bar btn-red"
            style={{ width: `${((step + 1) / formSteps.length) * 100}%` }}
          ></div>
        </div>
      </div>

      {/* Step Indicators - Visible on Desktop */}
      <div className="d-none d-lg-block my-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          {formSteps.map((formStep, index) => (
            <div key={formStep.title} className="text-center flex-fill">
              <div
                className={`rounded-circle text-small mx-auto mb-2 ${
                  index <= step ? "btn-red text-white" : "bg-secondary text-white"
                }`}
                style={{ width: "25px", height: "25px", lineHeight: "25px" }}
              >
                <p>{index + 1}</p>
              </div>
              <small className={index <= step ? "main-red" : "text-secondary"}>
                {formStep.title}
              </small>
            </div>
          ))}
        </div>
        <div className="progress" style={{ height: "7px" }}>
          <div
            className="progress-bar btn-red"
            style={{ width: `${((step + 1) / formSteps.length) * 100}%` }}
          ></div>
        </div>
      </div>

      <form>
        <div className={`fade ${step === step ? "show" : ""}`}>
          <CurrentStepComponent
            formData={formData}
            onChange={handleInputChange}
            onImageSelect={handleImageSelect}
            ticketPrices={ticketPrices}
          />
        </div>

        <div className="d-flex justify-content-between mt-4">
          {/* Previous Button */}
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setStep((prev) => Math.max(0, prev - 1))}
            disabled={step === 0}
          >
            <i className="bi bi-chevron-left"></i> Previous
          </button>

          {step < formSteps.length - 1 ? (
            <button
              type="button"
              className="btn btn-red"
              onClick={() => {
                if (step === formSteps.length - 2) {
                  getAmount();
                }
                setStep((prev) => Math.min(formSteps.length - 1, prev + 1));
              }}
            >
              Next <i className="bi bi-chevron-right"></i>
            </button>
          ) : (
            <button type="button" onClick={handleSubmit} className="btn btn-red">
              Create Event
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
