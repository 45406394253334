import React from 'react'
import { Search, Calendar, Ticket, Star } from 'lucide-react';
import fororganizer from "../../assets/images/ForOrganizer.svg"
import forattendees from "../../assets/images/ForAttendees.svg"
import { useNavigate } from "react-router-dom";

const HowItWorksPage = () => {
  const navigate = useNavigate();
  const steps = [
    {
      icon: Search,
      title: "Discover Events",
      description: "Browse through a wide range of art events, from exhibitions to workshops.",
    },
    {
      icon: Calendar,
      title: "Choose Your Date",
      description: "Select the date and time that works best for you.",
    },
    {
      icon: Ticket,
      title: "Book Your Tickets",
      description: "Secure your spot with our easy booking process.",
    },
    {
      icon: Star,
      title: "Enjoy and Review",
      description: "Attend the event and share your experience with the community.",
    },
  ]

  return (
    <div className="custom-container py-5 mt-2">
      <h2 className="text-center fw-bold mb-4 text-black">How witzzart Works</h2>
      <p className="text-center lead text-muted mb-5">
        Discover, book, and enjoy art events with ease. Here's how you can make the most of witzzart.
      </p>

      {/* Steps Section */}
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-5">
        {steps.map((step, index) => {
          const Icon = step.icon
          return (
            <div className="col" key={index}>
              <div className="card border text-center">
                <div className='card-body'>
                  <div className=" border rounded-circle mx-auto mb-4 d-flex justify-content-center align-items-center p-2" style={{backgroundColor:"rgb(254 226 226 / var(--tw-bg-opacity, 1))" , width:"fit-content"}} >
                    <Icon className="w-8 h-8 main-red " />
                  </div>
                  <h5 className=" fw-bold">{step.title}</h5>
                  <p className="card-text text-truncate-2">{step.description}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      {/* For Attendees Section */}
      <section className="mb-5">
        <h3 className="text-center fw-bold mb-4">For Attendees</h3>
        <div className="row align-items-center mt-3">
          <div className="col-md-6">
            <h4 className="h4 mb-4">Discover Amazing Creative Events</h4>
            <ul className="list-unstyled">
              <li className="d-flex align-items-start mb-2">
                <span className="mr-2">•</span>
                <span>Browse a curated selection of art events in your city</span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <span className="mr-2">•</span>
                <span>Filter by date, type of event, and more</span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <span className="mr-2">•</span>
                <span>Read reviews from other attendees</span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <span className="mr-2">•</span>
                <span>Save events to your wishlist</span>
              </li>
            </ul>
            <button className="btn-red font-xs rounded-pill btn-lg"   onClick={() => {navigate("/"); window.scrollTo(0, 0)}}>Find Events</button>
          </div>
          <div className="col-md-6 all-width all-height">
            <img
              src={forattendees}
              alt="Attendee Experience"
              className=" rounded-lg "
              style={{width:"100%", maxHeight:"70vh"}}
            />
          </div>
        </div>
      </section>

      {/* For Organizers Section */}
      <section>
        <h3 className="text-center fw-bold mb-4 text-black">For Organizers</h3>
        <div className="row align-items-center">
          <div className="col-md-6 ">
            <img
              src={fororganizer}
              alt="Organizer Dashboard"
              className=" rounded-lg "
              style={{width:"100%", maxHeight:"70vh"}}
            />
          </div>
          <div className="col-md-6 ">
            <h3 className="h4 mb-4">Effortless Event Management</h3>
            <ul className="list-unstyled">
              <li className="d-flex align-items-start mb-2">
                <span className="mr-2">•</span>
                <span>Create and publish events in minutes</span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <span className="mr-2">•</span>
                <span>Manage ticket sales and attendee lists</span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <span className="mr-2">•</span>
                <span>Promote your events to our community</span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <span className="mr-2">•</span>
                <span>Access detailed analytics and insights</span>
              </li>
            </ul>
            <button className="btn-red font-xs rounded-pill btn-lg"  onClick={() => {navigate("/createEvent"); window.scrollTo(0, 0)}}>Start Organizing</button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HowItWorksPage
