import React, { useEffect, useState } from 'react'
import TopNavBar from "../../Layouts/TopNavBar"
import { useNavigate } from "react-router-dom";
import DataTable2 from '../../components/DataTable2';
import { Link } from "react-router-dom";
import { FaEdit, FaTrash , FaUserAlt} from 'react-icons/fa';
import axios from '../../../src/axiosInstance'; 
import { toast } from 'react-toastify';
const EventApprovalList = () => {
  const [eventApproval, setEventApproval]=useState();


  const  getEventApprovalList = async()=>{
    const result = await axios.get('/approval');
    if (result.status === 200) {
        setEventApproval(result.data.data ? result.data.data:[]) 
    }
       
  }
  useEffect(()=>{
    getEventApprovalList()
  },[])

  const handleApprovalChange = async (id, isChecked) => {
    try {
      const response = await axios.post(`/approvalCheck/${id}`, {
        approved: isChecked,
      });
      if (response.status === 200) {
        toast.success("Approval status updated successfully!");
        getEventApprovalList(); 
      }
    } catch (error) {
      console.error("Error updating approval status:", error);
    }
  };

  const headers = [
    {name: 'Event Name',	field: 'event_title',sortable: true,classKey: '',},
		{name: 'Type', field: 'event_type', sortable: true, classKey: '' },
		{name: 'event_organizer_name', field: 'event_organizer_name', sortable: true, classKey: '' },
		{name: 'Status', field: 'status', sortable: true, classKey: '' },
    {name: 'Event Duration', field: 'event_duration', sortable: true, classKey: '' },
    { name: "Approved", field: "action", classKey: "" },
    ];

    const searchItems = ['event_title', 'event_organizer_name', 'event_type'];


  const EventApprovalListData = eventApproval?.map((value) => {

    let buttons = [];
    buttons.push(
        <input
          key={`approvalCheckbox-${value.id}`}
          type="checkbox"
          className="custom-checkbox" 
          checked={value.is_approval === 1} 
          onChange={(e) => handleApprovalChange(value.id, e.target.checked)}
        />
      );
   
    value['action'] = buttons?.length > 0 ? buttons : '-';
    
    return value;
  });

  console.log("checking",EventApprovalListData);
  


  return (
<>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div
                            className="tab-pane fade show active"
                            id="user-list"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {
                                            <DataTable2
                                                lists={EventApprovalListData ? EventApprovalListData : []}
                                                headers={headers}
                                                searchItems={searchItems}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}
export default EventApprovalList