import React, { useEffect, useState } from 'react'
import TopNavBar from "../../Layouts/TopNavBar"
import { useNavigate } from "react-router-dom";
import DataTable2 from '../../components/DataTable2';
import { Link } from "react-router-dom";
import { FaEdit, FaTrash , FaUserAlt} from 'react-icons/fa';
import axios from '../../../src/axiosInstance'; 

const PaymentList = () => {
  const [payment, setPayment]=useState();


  const  getPaymentList = async()=>{
    const result = await axios.post('/adminPayment');
    if (result.status === 200) {
        setPayment(result.data.data ? result.data.data:[]) 
    }
       
  }
  useEffect(()=>{
    getPaymentList()
  },[])

  const headers = [
    {name: 'Event Title',	field: 'event_title',sortable: true,classKey: '',},
    {name: 'Organizer Name', field: 'event_organizer_name', sortable: true, classKey: '' },
    {name: 'Buyers Name', field: 'name', sortable: true, classKey: '' },
    {name: 'Payment Status', field: 'payment_status', sortable: true, classKey: '' },
        {name: 'Price', field: 'price', sortable: true, classKey: '' },
        {name: 'Seats', field: 'seats', sortable: true, classKey: '' },
    {name: 'Mobile', field: 'mobile', sortable: true, classKey: '' },
    ];

    const searchItems = ['event_title', 'event_organizer_name', 'name'];


  const PaymentListData = payment?.map((value) => {

    let buttons = [];
    //  if (authPermissions?.includes('User-Update'))
    // {
    // buttons.push(
    //   <Link
    //     key='editButton##1'
    //     type='button'
        
    //     to={`/admin/events/edit/${value.id}`} 
    //     style={{ border: 'none', background: 'none', marginRight: '10px' }}
    //     title='Edit'
    //   >
    //     <FaEdit color='green' size={13} />
    //   </Link>
    // );
    //  }

    // // if(authPermissions?.includes("User-Delete"))
    // {
    // buttons.push(
    //   <button
    //     key='deleteButton##1'
    //     type='button'
    //     // data-id={value.id}
    //     // onClick={() => userDeleteHandler(value.id)}
    //     title='Delete'
    //     style={{ border: 'none', background: 'none' }}   
    //   >                                                                  
    //     <FaTrash color='red' size={13} />
    //   </button>
    // );
    // }
   
    value['action'] = buttons?.length > 0 ? buttons : '-';
    
    return value;
  });

  console.log("checking",PaymentListData);
  


  return (
<>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div
                            className="tab-pane fade show active"
                            id="user-list"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {
                                            <DataTable2
                                                lists={PaymentListData ? PaymentListData : []}
                                                headers={headers}
                                                searchItems={searchItems}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}
export default PaymentList