import { Fragment,useContext,useEffect } from "react";
import {Link, useNavigate } from "react-router-dom";
import AuthContext from '../context/Auth/AuthContext';

const Header = (props) => {
   const { setUser } = useContext(AuthContext);
   const navigate = useNavigate();
 
   const logoutHandler = (e) => {
     localStorage.removeItem('userData');
     localStorage.removeItem('userRefreshToken');
     localStorage.removeItem('userDetails');
     navigate('/signin');
   };
   const BacktoWeb = (e) => {
      navigate('/');
    };


   return (
      <div>
         <div id="page_top" className="section-body  ">
            <div className="container-fluid">
               <div className="page-header">
                  <div className="left">
                     
                     <div className="input-group xs-hide"></div>
                  </div>

                  <div className="right">
                     <ul className="nav nav-pills">
                     </ul>
                     <div className="notification d-flex">

                        <div className="dropdown d-flex">
                           <Link className="nav-link icon d-none d-md-flex btn btn-default btn-icon mr-3" to={'/admin/profile'}>
                              <i className="fa fa-user"></i>
                           </Link>
                           <div className="dropdown-menu dropdown-menu-right ">
									<div className="dropdown-divider"></div>
								</div>
                        </div>
                        <div className="dropdown d-flex">
                        <a className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1" onClick={e => logoutHandler()}><i className="dropdown-icon fe fe-log-out"></i></a>
                        </div>
                        <div className=" d-flex">
                        <a className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1" onClick={e => BacktoWeb()}><i className=" fe fe-navigation"></i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Header;