import { Facebook, Twitter, Instagram } from "lucide-react"; // Import Lucid icons

function ArtistCard({ artist }) {
  return (
    <div className="card overflow-hidden border custom-hover cursor-pointer ">
      <div className="card-body">
        <h5 className="card-title fw-bold mb-1">{artist.artist_name}</h5>
        <p className="card-text text-muted mb-2 font-xs">
          Category: {artist.category}
        </p>
        <p className="card-text text-muted mb-4 text-truncate-2 font-xs">
          {artist.description}
        </p>
        <div className="d-flex gap-2">
          <a
            href={artist.social_media}
            className="redTextOnhover"
            aria-label="Instagram"
          >
            <Instagram className="fs-5" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ArtistCard;
