import React, { useState } from "react";
import { MessageSquare, Phone, Mail, MapPin } from "lucide-react";
import axios from "../../../src/axiosInstance";
import { useNavigate } from "react-router-dom";
export default function Contact() {
  const [subject, setSubject] = useState("");
  const navigate= useNavigate()
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const contactInfo = [
    {
      icon: "bi-telephone-fill",
      title: "Phone",
      details: ["+1 (555) 123-4567", "Mon-Fri 9am-6pm IST"],
    },
    {
      icon: "bi-envelope-fill",
      title: "Email",
      details: ["support@witzzart.com", "press@witzzart.com"],
    },
    {
      icon: "bi-geo-alt-fill",
      title: "Office",
      details: ["123 Art Street", "Mumbai, Maharashtra 400001"],
    },
    {
      icon: "bi-chat-dots-fill",
      title: "Social Media",
      details: ["@witzzart on all platforms", "Response within 24 hours"],
    },
  ];

  const handleSubmit = async (e) => {
    console.log("aswedrftyui", formData);
    e.preventDefault();
    // const token = await axios.post(`/contact`, formData);
  };

  return (
    <div className=" py-5">
      {/* Header Section */}
      <div className="text-center mb-5">
        <h1 className="display-4 fw-bold mb-3">Contact Us</h1>
        <p className="lead text-muted">
          Have a question or need help? We're here for you. Choose a topic below
          or fill out the form, and we'll get back to you as soon as possible.
        </p>
      </div>

      {/* Contact Information Grid */}
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-5 px-5">
        <div className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-3">
          <div className="card h-100 text-start border">
            <div className="card-body">
              <span className="main-red mb-2">
                <Phone />
              </span>
              <h5 className="card-title mb-2 mt-3 fw-bold">Phone</h5>
              <p className="text-muted mb-1">9675624255</p>
              <p className="text-muted mb-1"> Mon-Fri 9am-6pm IST</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-3">
          <div className="card h-100 text-start border">
            <div className="card-body">
              <span className="main-red mb-2">
                <Mail />
              </span>
              <h5 className="card-title mb-2 mt-3 fw-bold">Email</h5>
              <p className="text-muted mb-1"> support@witzzart.com</p>
              {/* <p className="text-muted mb-1">press@witzzart.com</p> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-3">
          <div className="card h-100 text-start border">
            <div className="card-body">
              <span className="main-red mb-2">
                <MapPin />
              </span>
              <h5 className="card-title mb-2 mt-3 fw-bold">Office</h5>
              <p className="text-muted mb-1">I-Start incubation </p>
              <p className="text-muted mb-1">Udaipur, Rajasthan </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-3">
          <div className="card h-100 text-start border">
            <div className="card-body">
              <span className="main-red mb-2">
                <MessageSquare />
              </span>
              <h5 className="card-title mb-2 mt-3 fw-bold">Social Media</h5>
              <p className="text-muted mb-1">Follow us on @witzzart_ </p>
              {/* <p className="text-muted mb-1">Response within 24 hours</p> */}
            </div>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="d-flex align-items-center justify-content-center">
        <div className="card border-gray mb-5 width-50">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row g-3 mb-3">
                <div className="col-md-6">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="form-control rounded"
                    placeholder="Enter your first name"
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="form-control rounded"
                    placeholder="Enter your last name"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control rounded"
                  placeholder="Enter your email"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Subject</label>
                <select
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="form-select rounded"
                >
                  <option value="">Select a subject</option>
                  <option value="general">General Inquiry</option>
                  <option value="support">Technical Support</option>
                  <option value="billing">Billing Question</option>
                  <option value="partnership">Partnership Opportunity</option>
                  <option value="press">Press Inquiry</option>
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="form-control rounded"
                  rows="4"
                  placeholder="How can we help you?"
                ></textarea>
              </div>

              <button type="submit" className="btn btn-red w-100 rounded-pill">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* FAQ Section */}
      <div className="text-center">
        <h5 className="fw-bold mb-3">Frequently Asked Questions</h5>
        <p className="text-muted mb-4">
          Find quick answers to common questions in our FAQ section.
        </p>
        <button className="btn btn-outline-secondary rounded-pill" onClick={()=> {navigate("/faq"); window.scrollTo(0, 0)}}  >
          Visit FAQ Page
        </button>
      </div>
    </div>
  );
}
