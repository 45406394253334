import * as Icons from "lucide-react";
import { useState } from "react";
import axios from "../../../../src/axiosInstance";
import { useEffect } from "react";

export default function BasicInfo({ formData, onChange, onImageSelect }) {
  const EVENT_CATEGORIES = [
    { id: "Tourism and Hospitality", name: "Tourism and Hospitality" },
    { id: "Education and Training", name: "Education and Training" },
    { id: "Entertainment & Arts", name: "Entertainment & Arts" },
    { id: "Wellness and Fitness", name: "Wellness and Fitness" },
    { id: "Food and Beverage", name: "Food and Beverage" },
    { id: "Art and Craft", name: "Art and Craft" },
    { id: "Corporate & Team Building", name: "Corporate & Team Building" },
    { id: "Fashion and Lifestyle", name: "Fashion and Lifestyle" },
    { id: "Gaming and Esports", name: "Gaming and Esports" },
    { id: "Social/ Environmental Causes", name: "Social/ Environmental Causes" },
    { id: "Other", name: "Other" },

  ];

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userName, setUserName] = useState("");

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files || []);
    const media = files.map((file) => URL.createObjectURL(file));
    formData.image= media
    onImageSelect(e.target.files); // Pass the selected files to the parent component
  };

  const getUserDetails= async ()=> {
    let id = localStorage.getItem("userId")

    try {
      const response = await axios.get(`/getUserProfile/${id}`);
      if(response)
      {
        setUserName(response?.data?.data?.user?.name)

      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(()=>{
    getUserDetails()
  },[])
  return (
    <div className="mb-4">
      {/* Event Title */}
      <div className="mb-3">
        <label className="form-label">Event Title*</label>
        <input
          type="text"
          className="form-control"
          name="event_title"
          value={formData.event_title}
          onChange={onChange}
          required
          placeholder="Enter event title"
        />
        <small className="form-text text-muted">
          Choose a catchy title for your event.
        </small>
      </div>

      {/* Event Category */}
      <div className="mb-3">
        <label className="form-label">Event Type*</label>
        <select
          className="form-select"
          name="event_type"
          required
          value={formData.event_type}
          onChange={onChange}
        >
          <option value="">Select event type</option>
          {EVENT_CATEGORIES.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
        <small className="form-text text-muted">
          Choose the category that best describes your event.
        </small>
      </div>

      {/* Description */}
      <div className="mb-3">
        <label className="form-label">Description*</label>
        <textarea
          className="form-control"
          name="description"
          required
          value={formData.description}
          onChange={onChange}
          placeholder="Describe your event"
          rows={4}
        />
        <small className="form-text text-muted">
          Provide details about your event to attract attendees.
        </small>
      </div>

      {/* Event Organizer Name */}
      <div className="mb-3">
        <label className="form-label">Event Organizer Name*</label>
        <input
          type="text"
          className="form-control"
          name="event_organizer_name"
          required
          value={formData.event_organizer_name}
          onChange={onChange}
          placeholder="Enter organizer name"
        />
      </div>

      {/* Event Image/Video */}
      <div className="mb-3">
        <label className="form-label">Event Image/Video*</label>
        <input
      type="file"
      className="form-control"
      accept="image/*,video/*"
      onChange={handleFileChange}
      required
    />
        <small className="form-text text-muted">
          Upload a photo showcasing the event.
        </small>
      </div>
    </div>
  );
}
