import React, { useState, useEffect } from "react";
import MainCards from "./Shared/MainCards";
import axios from "../../../src/axiosInstance";
import concert from "../../assets/images/concert.png";
import Carousel from "./Shared/Carousel";
import {
  Calendar,
  Star,
  ChevronRight,
  Mic,
  Music2,
  VenetianMaskIcon as TheatreMask,
  MessagesSquare,
  Gamepad2,
  Briefcase,
  ShoppingBag,
} from "lucide-react";

const Home = () => {
  const [activeTheme, setActiveTheme] = useState("all");
  const [category, setCategory] = useState([]);
  const [eventslist, setEventsList] = useState([]);

  // const themes = [
  //   "All",
  //   "Painting",
  //   "Photography",
  //   "Sculpture",
  //   "Digital Art",
  //   "Performance",
  // ];
  // const newEvents = [
  //   {
  //     title: "Abstract Visions",
  //     artist: "Elena Kraft",
  //     likes: 234,
  //     image: "",
  //     date: "12-12-12",
  //     time: "04:00",
  //     location: "udaipur",
  //     price: "1234",
  //     href: "eventdetails",
  //     tags: ["photo", "music"],
  //     type: "Event",
  //   },
  //   {
  //     title: "Urban Sketchers Meetup",
  //     artist: "Chris Lee",
  //     likes: 189,
  //     image: "",
  //     date: "12-12-12",
  //     time: "04:00",
  //     location: "udaipur",
  //     price: "1234",
  //     href: "eventdetails",
  //     tags: ["photo", "music"],
  //     type: "Event",
  //   },
  //   {
  //     title: "Digital Art Symposium",
  //     artist: "Alex Chen",
  //     likes: 412,
  //     image: "",
  //     date: "12-12-12",
  //     time: "04:00",
  //     location: "udaipur",
  //     price: "1234",
  //     href: "eventdetails",
  //     tags: ["photo", "music"],
  //     type: "Event",
  //   },
  //   {
  //     title: "Digital Art Symposium",
  //     artist: "Alex Chen",
  //     likes: 412,
  //     image: "",
  //     date: "12-12-12",
  //     time: "04:00",
  //     location: "udaipur",
  //     price: "1234",
  //     href: "eventdetails",
  //     tags: ["photo", "music"],
  //     type: "Event",
  //   },
  // ];
  // const upcomingEvents = [
  //   {
  //     title: "Annual Photography Exhibition",
  //     date: "May 15-20",
  //     location: "New York City",
  //     image: "",
  //     date: "12-12-12",
  //     time: "04:00",
  //     location: "udaipur",
  //     price: "1234",
  //     href: "eventdetails",
  //     tags: ["photo", "music"],
  //     type: "Event",
  //   },
  //   {
  //     title: "Sculpture in the Park",
  //     date: "June 5-7",
  //     location: "Chicago",
  //     image: "",
  //     date: "12-12-12",
  //     time: "04:00",
  //     location: "udaipur",
  //     price: "1234",
  //     href: "eventdetails",
  //     tags: ["photo", "music"],
  //     type: "Event",
  //   },
  //   {
  //     title: "Modern Art Fair",
  //     date: "July 1-3",
  //     location: "Los Angeles",
  //     image: "",
  //     date: "12-12-12",
  //     time: "04:00",
  //     location: "udaipur",
  //     price: "1234",
  //     href: "eventdetails",
  //     tags: ["photo", "music"],
  //     type: "Event",
  //   },
  //   {
  //     title: "Modern Art Fair",
  //     date: "July 1-3",
  //     location: "Los Angeles",
  //     image: "",
  //     date: "12-12-12",
  //     time: "04:00",
  //     location: "udaipur",
  //     price: "1234",
  //     href: "eventdetails",
  //     tags: ["photo", "music"],
  //     type: "Event",
  //   },
  // ];

  // const cities = [
  //   {
  //     name: "Mumbai",
  //     events: 1234,
  //     image: "/placeholder.svg?height=300&width=400&text=Mumbai",
  //   },
  //   {
  //     name: "Delhi",
  //     events: 987,
  //     image: "/placeholder.svg?height=300&width=400&text=Delhi",
  //   },
  //   {
  //     name: "Bangalore",
  //     events: 756,
  //     image: "/placeholder.svg?height=300&width=400&text=Bangalore",
  //   },
  //   {
  //     name: "Kolkata",
  //     events: 543,
  //     image: "/placeholder.svg?height=300&width=400&text=Kolkata",
  //   },
  // ];

  // const trendingSearches = [
  //   { name: "Digital Art Exhibition 2024", type: "Events" },
  //   { name: "Photography Masterclass", type: "Workshop" },
  //   { name: "Street Art Festival", type: "Events" },
  //   { name: "Modern Art Gallery", type: "Venue" },
  //   { name: "Abstract Expressions", type: "Exhibition" },
  //   { name: "Art Tech Summit", type: "Conference" },
  // ];

  // const categories = [
  //   { icon: Mic, name: "Music", count: "850+ Events", href: "/events/music" },
  //   {
  //     icon: Music2,
  //     name: "Nightlife",
  //     count: "420+ Events",
  //     href: "/events/nightlife",
  //   },
  //   {
  //     icon: TheatreMask,
  //     name: "Performing & Visual Arts",
  //     count: "195+ Events",
  //     href: "/events/arts",
  //   },
  //   {
  //     icon: Calendar,
  //     name: "Holidays",
  //     count: "85+ Events",
  //     href: "/events/holidays",
  //   },
  //   {
  //     icon: MessagesSquare,
  //     name: "Dating",
  //     count: "150+ Events",
  //     href: "/events/dating",
  //   },
  //   {
  //     icon: Gamepad2,
  //     name: "Hobbies",
  //     count: "320+ Events",
  //     href: "/events/hobbies",
  //   },
  //   {
  //     icon: Briefcase,
  //     name: "Business",
  //     count: "250+ Events",
  //     href: "/events/business",
  //   },
  //   {
  //     icon: ShoppingBag,
  //     name: "Food & Drink",
  //     count: "480+ Events",
  //     href: "/events/food-drink",
  //   },
  // ];

  const getCategory = async () => {
    try {
      // Send FormData in a POST request to the backend
      const response = await axios.get("/users");
      setCategory(response);
      console.log("Response:", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getEvents = async () => {
    try {
      // Send FormData in a POST request to the backend
      const response = await axios.get("/event");
      setEventsList(response.data.data);
      console.log("Response:", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getEvents();
    getCategory();
  }, []);

  return (
    <div className=" d-flex custom-container pt-4 flex-column align-start justify-start">
      <div
        className="position-relative rounded-5 mb-4 mt-3 overflow-hidden"
        style={{ maxHeight: "80vh", minHeight: "70vh" }}
      >
        {/* {eventslist.map((event, index) => (
            <div
              className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-3"
              key={index}
            >
              <Carousel slides={event} />

            </div>
          ))} */}

        {eventslist && <Carousel event={eventslist} />}
      </div>

      {/*
       <h5 className="text-lg font-semibold text-black mb-3 mt-2">
        Trending Searches Right Now
      </h5>
      <div className="row  gap-1 mb-4">
        {trendingSearches.map((search, index) => (
          <div
            className="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-2"
            key={index}
          >
            <div className="group my-1  d-flex align-items-center gap-2 px-2 py-1 bg-white rounded-pill border cursor-pointer">
              <span className="text-truncate font-xs fw-medium group-hover:text-red-600">
                {search.name}
              </span>
              <span className="badge bg-light font-xxs rounded-pill  text-secondary ">
                {search.type}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-between mb-4 mt-5">
        <h4 className=" fw-semibold text-black">Browse Categories</h4>
      </div>  */}

      {/* Categories Grid */}
      {/* <div className="row g-4 py-3 mb-5">
        {categories.map((category, index) => (
          <div className="col-4 col-sm-3 col-md-2 col-lg-1" key={index}>
            <div className="d-flex flex-column align-items-center text-center">
              <div className="position-relative mb-3">
                <div className=" rounded-circle bg-white  shadow-lg d-flex  align-items-center justify-content-center transform-hover s">
                  <category.icon
                    className=" text-muted transform-hover text-danger p-3 transition-all"
                    size={70}
                  />
                </div>
              </div>
              <small
                className=" fw-medium text-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                {category.name}
              </small>
              <small
                className="text-xs text-muted"
                style={{ fontSize: "10px" }}
              >
                {category.count}
              </small>
            </div>
          </div>
        ))}
      </div>

      <h4 class="fw-bold mt-5 mb-4 ">Popular Cities</h4>
      <div className="py-2 mb-5">
        <div className="row g-4">
          {cities.map((city) => (
            <div
              key={city.name}
              className=" col-12 col-sm-6 col-md- col-lg-4 col-xl-3   cursor-pointer"
            >
              <div class="position-relative rounded-5 overflow-hidden group cursor-pointer aspect-ratio">
                <img
                  alt={city.name}
                  loading="lazy"
                  decoding="async"
                  class="img-fluid object-cover group-hover-scale"
                  src={city.image}
                />
                <div class="position-absolute top-0 start-0 w-100 h-100 gradient-overlay"></div>
                <div class="position-absolute bottom-0 start-0 px-4 text-white">
                  <h3 class="h5 fw-bold mb-1">{city.name}</h3>
                  <p class="small text-white-80">{city.events} events</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      <div className="my-3 mb-5">
        <h4 class="fw-bold mt-5 mb-4 ">Trending Event</h4>
        <div className="py-2 all-width">
          <div className="row g-5">
            {eventslist.map((event, index) => (
              <div
                className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-3"
                key={index}
              >
                <MainCards
                  title={event.event_title}
                  image={event.images?.[0]?.image}
                  date={event.start_date}
                  time={event.start_time}
                  location={event.address}
                  price={event.tickets[0].price}
                  href={"eventdetails/" + event.id}
                  tags={event.event_type}
                  type={event.type}
                />
              </div>
            ))}
          </div>
        </div>
      </div>


      {/* Upcoming Events Section */}
      <div className="my-3 mb-5">

        <h4 class="fw-bold mb-3 mt-4">Featured Workshop</h4>
        <div className="py-2 all-width">
          <div className="row g-5">
            {eventslist.map(
              (event, index) =>
                event?.event_type?.toLowerCase() === "workshop" && (
                  <div
                    className="col-12 col-sm-6 col-md-4 col-lg-4 px-3 col-xl-3"
                    key={index}
                  >
                    <MainCards
                      title={event.event_title}
                      image={event.images?.[0]?.image}
                      date={event.start_date}
                      time={event.start_time}
                      location={event.address}
                      price={event.tickets?.[0]?.price}
                      href={`eventdetails/${event.id}`}
                      tags={event.event_type}
                      type={event.type}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
      <div className="my-3 mb-5">

        <h4 class="fw-bold mb-3 mt-4">Upcoming Exhibitions</h4>
        <div className="py-2 all-width">
          <div className="row g-5">
            {eventslist.map(
              (event, index) =>
                event?.event_type?.toLowerCase() === "exhibition" && (
                  <div
                    className="col-12 col-sm-6 col-md- col-lg-4 px-3 col-xl-3"
                    key={index}
                  >
                    <MainCards
                      title={event.event_title}
                      image={event.images?.[0]?.image}
                      date={event.start_date}
                      time={event.start_time}
                      location={event.address}
                      price={event.tickets[0].price}
                      href={"eventdetails/" + event.id}
                      tags={event.event_type}
                      type={event.type}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
